import { useContext, useEffect, useState } from "react"
import { HeaderPlaceholder } from "../component/placceholder"
import HalogenLogo from "../asset/halogen-logo"
import { PersonaContext } from "../module/login/context"
import { useNavigate } from "react-router-dom"

export default function Header() {
    const persona = useContext(PersonaContext)

    const navigate = useNavigate()
    const handleAccounts = () => {
        navigate("/persona/account/list")
    }
    
    if (!persona.ready) return <HeaderPlaceholder />

    return <div className="flex justify-between mr-3">
        <div className="flex">
            <HalogenLogo color="blue" className="w-9" />
            <h1 className="text-lg font-bold ml-3 mt-auto mb-auto">Halo, <span>{persona.title}</span>!</h1>
        </div>
        {persona.roles?.length > 1 && <div className="my-auto">
            <a className="text-sm font-medium text-blue-800 cursor-pointer" onClick={handleAccounts}>Accounts</a>
        </div>}
        {/* {!persona.isDelegate && <span className="text-xs font-medium bg-white text-emerald-500 border-emerald-500 border p-1 rounded-md">Master</span>}
        {persona.isDelegate && <span className="text-xs font-medium bg-white text-blue-500 border-blue-500 border p-1 rounded-md">Delegate</span>} */}
    </div>
}