import React from 'react';
import ReactDOM from 'react-dom/client';
import { createHashRouter, RouterProvider} from "react-router-dom";

import Portfolio from './module/portfolio';
import Fund from './module/fund';
import Settings from './module/settings';
import Login from './module/login';
import Invest from './module/invest';
import Redeem from './module/redeem';
import Onboarding from './module/onboarding';
import Switch from './module/switch';
import Rewards from './module/rewards';
import DuitNow from './module/duitnow';
import Policy from './module/policy';
import Deposit from './module/deposit';
import V2 from './module/v2';

import './index.css';

if (window.location.pathname === "/RPP/MY/Redirect/RTP") {
  window.location.href = window.location.href + "#/duitnow"
}

const router = createHashRouter([ ...Onboarding.Route, ...Portfolio.Route, ...Invest.Route, ...Switch.Route, ...Redeem.Route, ...Fund.Route, ...Rewards.Route, ...Settings.Route, ...Login.Route, ...DuitNow.Route, ...Policy.Route, ...Deposit.Route, ...V2.Route ]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

document.addEventListener("wheel", (ev: any) => {
  if (ev.target.type === "number") {
    ev.preventDefault()
  }
}, {passive: false})

root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);
