interface BitcoinProps {
    className?: string
}


export default function HSBTCFLogo({ className }: BitcoinProps) {
    return <svg className={className} viewBox="0 0 86 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M43.0398 51.1216C42.8514 51.1216 42.6734 51.0692 42.5059 50.975L0.630165 26.1637C0.305628 25.9648 0.117188 25.6193 0.117188 25.2424C0.117188 24.8656 0.326566 24.5201 0.651103 24.3421L42.82 0.588151C43.155 0.39971 43.5528 0.410179 43.8878 0.609089L85.4704 26.1951C85.7844 26.394 85.9729 26.7395 85.9729 27.1164C85.9729 27.4933 85.753 27.8283 85.4285 28.0062L43.5528 50.996C43.3958 51.0797 43.2178 51.1216 43.0503 51.1216H43.0398ZM3.25787 25.2843L43.0607 48.8708L82.8322 27.0326L43.312 2.71334L3.25787 25.2843Z" fill="#F7B84A"/>
        <path d="M43.0398 51.1216C42.8514 51.1216 42.6734 51.0692 42.5059 50.975L0.630165 26.1637C0.305628 25.9648 0.117188 25.6193 0.117188 25.2424C0.117188 24.8656 0.326566 24.5201 0.651103 24.3421L42.82 0.588151C43.155 0.39971 43.5528 0.410179 43.8878 0.609089L85.4704 26.1951C85.7844 26.394 85.9729 26.7395 85.9729 27.1164C85.9729 27.4933 85.753 27.8283 85.4285 28.0062L43.5528 50.996C43.3958 51.0797 43.2178 51.1216 43.0503 51.1216H43.0398ZM3.25787 25.2843L43.0607 48.8708L82.8322 27.0326L43.312 2.71334L3.25787 25.2843Z" fill="#F7B84A"/>
        <path d="M43.0398 51.1216C42.8514 51.1216 42.6734 51.0692 42.5059 50.975L0.630165 26.1637C0.305628 25.9648 0.117188 25.6193 0.117188 25.2424C0.117188 24.8656 0.326566 24.5201 0.651103 24.3421L42.82 0.588151C43.155 0.39971 43.5528 0.410179 43.8878 0.609089L85.4704 26.1951C85.7844 26.394 85.9729 26.7395 85.9729 27.1164C85.9729 27.4933 85.753 27.8283 85.4285 28.0062L43.5528 50.996C43.3958 51.0797 43.2178 51.1216 43.0503 51.1216H43.0398ZM3.25787 25.2843L43.0607 48.8708L82.8322 27.0326L43.312 2.71334L3.25787 25.2843Z" fill="#F7B84A"/>
        <path d="M43.0398 51.1216C42.8514 51.1216 42.6734 51.0692 42.5059 50.975L0.630165 26.1637C0.305628 25.9648 0.117188 25.6193 0.117188 25.2424C0.117188 24.8656 0.326566 24.5201 0.651103 24.3421L42.82 0.588151C43.155 0.39971 43.5528 0.410179 43.8878 0.609089L85.4704 26.1951C85.7844 26.394 85.9729 26.7395 85.9729 27.1164C85.9729 27.4933 85.753 27.8283 85.4285 28.0062L43.5528 50.996C43.3958 51.0797 43.2178 51.1216 43.0503 51.1216H43.0398ZM3.25787 25.2843L43.0607 48.8708L82.8322 27.0326L43.312 2.71334L3.25787 25.2843Z" fill="#F7B84A"/>
        <path d="M43.043 100.455C42.865 100.455 42.687 100.413 42.53 100.318C42.195 100.13 41.9961 99.7845 41.9961 99.4076V50.0781C41.9961 49.5024 42.4672 49.0312 43.043 49.0312C43.6188 49.0312 44.0899 49.5024 44.0899 50.0781V97.5442L84.3743 73.0888C84.8664 72.7852 85.5154 72.9422 85.8086 73.4447C86.1122 73.9368 85.9551 74.5859 85.4526 74.879L43.5769 100.298C43.4094 100.402 43.221 100.455 43.0325 100.455H43.043Z" fill="#F7B84A"/>
        <path d="M43.043 100.455C42.865 100.455 42.687 100.413 42.53 100.318C42.195 100.13 41.9961 99.7845 41.9961 99.4076V50.0781C41.9961 49.5024 42.4672 49.0312 43.043 49.0312C43.6188 49.0312 44.0899 49.5024 44.0899 50.0781V97.5442L84.3743 73.0888C84.8664 72.7852 85.5154 72.9422 85.8086 73.4447C86.1122 73.9368 85.9551 74.5859 85.4526 74.879L43.5769 100.298C43.4094 100.402 43.221 100.455 43.0325 100.455H43.043Z" fill="#F7B84A"/>
        <path d="M43.043 100.455C42.865 100.455 42.687 100.413 42.53 100.318C42.195 100.13 41.9961 99.7845 41.9961 99.4076V50.0781C41.9961 49.5024 42.4672 49.0312 43.043 49.0312C43.6188 49.0312 44.0899 49.5024 44.0899 50.0781V97.5442L84.3743 73.0888C84.8664 72.7852 85.5154 72.9422 85.8086 73.4447C86.1122 73.9368 85.9551 74.5859 85.4526 74.879L43.5769 100.298C43.4094 100.402 43.221 100.455 43.0325 100.455H43.043Z" fill="#F7B84A"/>
        <path d="M43.043 100.455C42.865 100.455 42.687 100.413 42.53 100.318C42.195 100.13 41.9961 99.7845 41.9961 99.4076V50.0781C41.9961 49.5024 42.4672 49.0312 43.043 49.0312C43.6188 49.0312 44.0899 49.5024 44.0899 50.0781V97.5442L84.3743 73.0888C84.8664 72.7852 85.5154 72.9422 85.8086 73.4447C86.1122 73.9368 85.9551 74.5859 85.4526 74.879L43.5769 100.298C43.4094 100.402 43.221 100.455 43.0325 100.455H43.043Z" fill="#F7B84A"/>
        <path d="M43.0398 100.448C42.8514 100.448 42.6734 100.396 42.5059 100.302L0.619696 75.103C0.305628 74.9145 0.117188 74.5795 0.117188 74.2131V25.25C0.117188 24.6742 0.588289 24.2031 1.16408 24.2031C1.73987 24.2031 2.21097 24.6742 2.21097 25.25V73.6164L43.5842 98.501C44.0762 98.7941 44.2437 99.4432 43.9401 99.9352C43.7412 100.26 43.3957 100.438 43.0398 100.438V100.448Z" fill="#F7B84A"/>
        <path d="M43.0398 100.448C42.8514 100.448 42.6734 100.396 42.5059 100.302L0.619696 75.103C0.305628 74.9145 0.117188 74.5795 0.117188 74.2131V25.25C0.117188 24.6742 0.588289 24.2031 1.16408 24.2031C1.73987 24.2031 2.21097 24.6742 2.21097 25.25V73.6164L43.5842 98.501C44.0762 98.7941 44.2437 99.4432 43.9401 99.9352C43.7412 100.26 43.3957 100.438 43.0398 100.438V100.448Z" fill="#F7B84A"/>
        <path d="M43.0398 100.448C42.8514 100.448 42.6734 100.396 42.5059 100.302L0.619696 75.103C0.305628 74.9145 0.117188 74.5795 0.117188 74.2131V25.25C0.117188 24.6742 0.588289 24.2031 1.16408 24.2031C1.73987 24.2031 2.21097 24.6742 2.21097 25.25V73.6164L43.5842 98.501C44.0762 98.7941 44.2437 99.4432 43.9401 99.9352C43.7412 100.26 43.3957 100.438 43.0398 100.438V100.448Z" fill="#F7B84A"/>
        <path d="M43.0398 100.448C42.8514 100.448 42.6734 100.396 42.5059 100.302L0.619696 75.103C0.305628 74.9145 0.117188 74.5795 0.117188 74.2131V25.25C0.117188 24.6742 0.588289 24.2031 1.16408 24.2031C1.73987 24.2031 2.21097 24.6742 2.21097 25.25V73.6164L43.5842 98.501C44.0762 98.7941 44.2437 99.4432 43.9401 99.9352C43.7412 100.26 43.3957 100.438 43.0398 100.438V100.448Z" fill="#F7B84A"/>
        <path d="M84.9141 75.0231C84.3383 75.0231 83.8672 74.552 83.8672 73.9762V27.086C83.8672 26.5102 84.3383 26.0391 84.9141 26.0391C85.4899 26.0391 85.961 26.5102 85.961 27.086V73.9762C85.961 74.552 85.4899 75.0231 84.9141 75.0231Z" fill="#F7B84A"/>
        <path d="M84.9141 75.0231C84.3383 75.0231 83.8672 74.552 83.8672 73.9762V27.086C83.8672 26.5102 84.3383 26.0391 84.9141 26.0391C85.4899 26.0391 85.961 26.5102 85.961 27.086V73.9762C85.961 74.552 85.4899 75.0231 84.9141 75.0231Z" fill="#F7B84A"/>
        <path d="M84.9141 75.0231C84.3383 75.0231 83.8672 74.552 83.8672 73.9762V27.086C83.8672 26.5102 84.3383 26.0391 84.9141 26.0391C85.4899 26.0391 85.961 26.5102 85.961 27.086V73.9762C85.961 74.552 85.4899 75.0231 84.9141 75.0231Z" fill="#F7B84A"/>
        <path d="M84.9141 75.0231C84.3383 75.0231 83.8672 74.552 83.8672 73.9762V27.086C83.8672 26.5102 84.3383 26.0391 84.9141 26.0391C85.4899 26.0391 85.961 26.5102 85.961 27.086V73.9762C85.961 74.552 85.4899 75.0231 84.9141 75.0231Z" fill="#F7B84A"/>
        <path d="M10.8594 33.1247V67.3894C10.8594 67.8501 10.985 68.3107 11.2153 68.7085C11.4456 69.1063 11.7806 69.4413 12.1785 69.6716L41.8579 86.7988C42.2557 87.0291 42.7163 87.1547 43.177 87.1547C43.6376 87.1547 44.0982 87.0291 44.496 86.7988L74.1755 69.6716C74.5733 69.4413 74.9083 69.1063 75.1386 68.7085C75.3689 68.3107 75.4946 67.8501 75.4946 67.3894V33.1247C75.4946 32.664 75.3689 32.2034 75.1386 31.8056C74.9083 31.4078 74.5733 31.0728 74.1755 30.8425L44.496 13.7153C44.0982 13.485 43.6376 13.3594 43.177 13.3594C42.7163 13.3594 42.2557 13.485 41.8579 13.7153L12.1889 30.832C11.7911 31.0623 11.4561 31.3973 11.2258 31.7951C10.9955 32.1929 10.8698 32.6536 10.8698 33.1142L10.8594 33.1247Z" fill="url(#paint0_linear_7507_2183)"/>
        <circle opacity="0.15" cx="43.045" cy="50.459" r="22.834" fill="url(#paint1_radial_7507_2183)"/>
        <path d="M52.3952 50.2161C52.1963 50.1114 51.9869 50.0172 51.7776 49.9334C54.1017 48.5201 55.0648 46.9079 55.1381 43.83C55.1381 43.6939 55.1381 43.5578 55.1381 43.4217C55.1381 42.1027 54.9496 40.972 54.5937 40.0193C54.2378 39.0667 53.6934 38.2815 52.992 37.6534C51.7671 36.5646 50.0397 35.9574 47.8308 35.7271V31.9688H43.6851V35.6224H41.7797V31.9688H37.634V35.6224H30.3477V40.6789H33.6454V60.0568H30.3477V65.1133H37.634V68.767H41.7797V65.1238H43.6851V68.767H47.8308V65.0191C52.657 64.4957 55.6197 61.9726 55.7243 56.9371C55.7243 56.8429 55.7243 56.7591 55.7243 56.6544C55.7243 53.1474 54.5937 51.3258 52.3952 50.2161ZM39.8325 40.7417H45.3078C47.6423 40.7417 48.8567 41.7886 48.8567 44.2069C48.8567 44.3221 48.8567 44.4372 48.8463 44.5419C48.752 46.6671 47.6633 47.7663 45.3601 47.7663H39.843V40.7312L39.8325 40.7417ZM49.4325 56.7277C49.3174 59.324 47.9355 60.0045 45.4962 60.0045H39.843V52.8019H45.4962C47.9669 52.8019 49.443 53.9325 49.443 56.3613C49.443 56.4869 49.443 56.6126 49.4325 56.7277Z" fill="white"/>
        <defs>
        <linearGradient id="paint0_linear_7507_2183" x1="43.177" y1="13.3594" x2="43.177" y2="87.1547" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F7A01E"/>
        <stop offset="1" stopColor="#F2CE79"/>
        </linearGradient>
        <radialGradient id="paint1_radial_7507_2183" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(43.045 50.459) rotate(90) scale(22.834)">
        <stop stopColor="#48320A"/>
        <stop offset="1" stopColor="#F5B84D" stopOpacity="0"/>
        </radialGradient>
        </defs>
    </svg>
    
}