
import { ArrowLeftIcon, ArrowUpIcon, ArrowUturnDownIcon, ArrowsRightLeftIcon, ChartPieIcon, Cog6ToothIcon, DocumentIcon, GiftIcon, ScaleIcon } from "@heroicons/react/24/outline"
import { useContext, useEffect, useState } from "react"
import { Doughnut } from "react-chartjs-2"
import { IPortfolioContext, PortfolioContext } from "../portfolio/context"
import { ProfileContext } from "../settings/context"
import { FundListContext } from "../fund/context"
import { PersonaContext } from "../login/context"
import { AccountRequestsPlaceholder } from "../../component/placceholder"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { Switch } from "@headlessui/react"

export default function Settings() {

    const navigate = useNavigate()

    return <div className="h-full">
        <div className="flex flex-col gap-6 h-full bg-black overflow-auto">
            {/* Header */}
            <div className="flex items-center justify-between text-white mt-5 mx-3">
                <ArrowLeftIcon className="w-5 h-5" onClick={() => navigate("/v2/portfolio")}/>
                <div className="flex flex-col items-center gap-1">
                    <h3 className="">Portfolio Settings</h3>
                </div>
                <div></div>
            </div>

            <div className="flex flex-col gap-6 mx-3 text-white">
                <div>
                    <div className="flex gap-1 text-gray-500">
                        <DocumentIcon className="w-4 h-4"/>
                        <h3 className="text-sm font-medium">General</h3>
                    </div>
                    <ul className="divider">
                        <li className="flex justify-between border-b border-gray-800 py-4">
                            <label className="text-sm font-medium">Name</label>
                            <label className="text-sm text-gray-300">Balanced</label>
                        </li>
                        <li className="flex justify-between border-b border-gray-800 py-4">
                            <label className="text-sm font-medium">Color</label>
                            <label className="bg-blue-700 w-5 h-5 rounded-full"></label>
                        </li>
                        <li></li>
                    </ul>
                </div>
                
                <div>
                    <div className="flex gap-1 text-gray-500">
                        <ScaleIcon className="w-4 h-4"/>
                        <h3 className="text-sm font-medium">Allocations</h3>
                    </div>
                    <ul>
                        <li className="flex justify-between border-b border-gray-800 py-4">
                            <label className="text-sm font-medium">Enabled</label>
                            <Switch checked={true} onChange={() => {}} className={`${true ? "bg-blue-700" : "bg-gray-200"} relative inline-flex h-4 w-8 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out`}>
                                <span className={`${true ? "translate-x-4" : "translate-x-0"} pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}/>
                            </Switch>
                        </li>
                        <li className="flex justify-between border-b border-gray-800 py-4">
                            <label className="text-sm font-medium">Assets</label>
                            <label className=""><ChartPieIcon className="w-5 h-5"/></label>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
}
