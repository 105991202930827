import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getSession, verifyAuthEmail } from "../../api";

export default function Verify() {
    const navigate = useNavigate()
    const { id } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        if (!id) {
            navigate("/auth/email")
            return
        }
        if (searchParams.get("medium") === "mobile") {
            window.location.href = "halogen://wallet/auth/verify/" + id
            return
        }
        getSession().then(resp => {
            verifyAuthEmail(id).then(resp => {
                if (resp.data.title && resp.data.title !== "") {
                    navigate(`/portfolio`)
                    return
                }
                navigate("/onboarding")
                return
            }).catch(() => {
                navigate("/auth/email")
                return
            })
        }).catch(() => {
            navigate("/auth/email")
            return
        })
    }, [])

    return <></>
}
