import PersonaContext from '../login/context';
import { Navigate } from "react-router-dom";
import Home from './home';
import CreateAccount from './create_account';
import CreateAccountWizard from './create_account_wizard';
import CreateAccountVerify from './create_account_verify';

export default [
    {
      path: "/",
      element: <Home />,
      errorElement: <Navigate to="/" />
    },
    {
      path: "/create-account",
      element: <CreateAccount />,
      errorElement: <Navigate to="/" />
    },
    {
      path: "/create-account/verify/:id",
      element: <CreateAccountVerify />,
      errorElement: <Navigate to="/" />
    },
    {
      path: "/create-account/wizard",
      element: <CreateAccountWizard />,
      errorElement: <Navigate to="/" />
    }
]
 