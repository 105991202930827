import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import { MouseEvent, useContext, useEffect, useState } from "react";
import { Button, Loader } from "../../component";
import { PortfolioContext } from "./context";
import { FundListContext } from "../fund/context";
import { PersonaContext } from "../login/context";
import { DuitNowBank, InitiateDuitNowPaymentOutput, ListDuitNowBanksOutput, initiateDuitNowPayment, listDuitNowBanks } from "../../api";
import { ProfileContext } from "../settings/context";

export default function DuitNow() {
    
    const [loading, setLoading] = useState(true)
    
    const [banks, setBanks] = useState([] as DuitNowBank[])
    const [selectedBankCode, setSelectedBankCode] = useState<string>("")
    const [proceedLoading, setProceedLoading] = useState(false)
    const navigate = useNavigate()
    const persona = useContext(PersonaContext)
    const portfolio = useContext(PortfolioContext)
    const fundList = useContext(FundListContext)
    const profile = useContext(ProfileContext)
    
    const {requestId} = useParams()
    useEffect(() => {
        if (!persona.clientId || !persona.accountId) {
            navigate("/auth/email")
            return
        }
        const request = portfolio.accountRequests?.filter(r => r.id === requestId)[0]
        if (!request) {
            portfolio.reloadAccountRequests()
            return
        }
        if (request.status !== "pending") {
            navigate(`/portfolio`)
            return
        }
        const duitnowSupported =  (profile.client?.type === "individual" && request?.amount <= 30000) || (profile.client?.type === "corporate" && request?.amount <= 1000000)
        if (!duitnowSupported) {
            navigate(`/portfolio/request/${requestId}`)
            return
        }

        try {
            listDuitNowBanks({
                clientId: persona.clientId, 
                accountId: persona.accountId,
            }).then((resp) => {
                const output = resp.data as ListDuitNowBanksOutput
                setBanks(output.banks.filter(b => b.url?.includes("mepsfpx")))
                setLoading(false)
            })
        } catch(err) {
            navigate(`/portfolio/request/${requestId}`)
            return
        }
    },[ portfolio.accountRequestsLoading, fundList.funds ])

    const handleSelectBank = (ev: MouseEvent<HTMLLIElement>) => {
        const {code} = ev.currentTarget.dataset
        setSelectedBankCode(code || "")
    }
    
    const handleProceedClick = (ev: MouseEvent<HTMLButtonElement>) => {
        if(!requestId) return
        setProceedLoading(true)
        initiateDuitNowPayment({
            clientId: persona.clientId, 
            accountId: persona.accountId,
            bankCode: selectedBankCode,
            requestId,
        }).then((resp) => {
            const output = resp.data as InitiateDuitNowPaymentOutput
            window.location.href = output.payment.url
            window.setTimeout(() => {
                setProceedLoading(false)
            }, 1000)
        })
    }

    if (loading) {
        return <div className="h-full flex justify-center items-center">
            <Loader classNames="w-16 h-16 text-blue-100" />
        </div>
    }

    return <div className="pb-24">
        <div className="relative h-32 text-center hg-bg-blue mb-10" style={{ borderBottomLeftRadius: "40%", borderBottomRightRadius: "40%"}}>
            <ArrowLeftCircleIcon className="fixed mt-2 ml-2 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={() => navigate(`/portfolio/request/${requestId}`)}/>
            <h1 className="pt-16 text-xl text-white pl-3 pr-6">Select a bank</h1>
        </div>

        <div className="w-full justify-center gap-3">
            <ul className="mx-5 pb-5">
                {banks.map((b) => {
                    return <li className={`p-3 my-3 border-b font-medium border-gray-200 text-md text-gray-700 cursor-pointer ${selectedBankCode === b.code ? "bg-blue-200 rounded-xl" : ""}`} key={b.code} data-code={b.code} onClick={handleSelectBank}>
                        {b?.name}
                    </li>
                })}
            </ul>
            <div className="2xs:fixed 2xs:bottom-0 2xs:left-0 md:fixed 2xs:w-full md:w-full lg:w-1/2 lg:left-1/4 border-gray-200 border bg-white p-5 mx-0">
                <Button label={proceedLoading ? `Redirecting...` : selectedBankCode !== "" ? `Proceed with ${banks.filter(b => b.code === selectedBankCode)[0]?.name}` : "Proceed"} onClick={handleProceedClick} loading={false} disabled={proceedLoading || selectedBankCode === ""} className="2xs:w-full block p-3 hg-bg-blue text-white border shadow-2xl rounded-full disabled:bg-blue-300"/>
            </div>
        </div>
    </div>
}
