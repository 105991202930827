import 'chart.js/auto';
import { useNavigate, useParams } from "react-router-dom";
import { ScriptableContext } from 'chart.js';
import { Line } from 'react-chartjs-2';
import React, { useEffect, useState, MouseEvent, useContext, Fragment } from "react";

import moment from "moment";
import { Loader } from "../../component";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { Fund, FundClass, FundListContext } from "./context";

interface FundState {
    fund: Fund
    loading: boolean

    selectedClass: string
}

export default function View() {
    const {fundCode} = useParams()
    const fundList = useContext(FundListContext)

    const [fund, setFund] = useState<FundState>({
        fund: {} as Fund,
        loading: fundList.loading,
        selectedClass: "A"
    })
    const navigate = useNavigate()

    useEffect(() => {
        const fund = fundList.funds.filter(f => f.code === fundCode)[0]
        if (!fund) return
        setFund(prev => ({
            ...prev,
            fund,
            selectedClass: fund?.classes[0]?.label,
            loading: false
        }))
        if (!fundCode) {
            navigate("/")
            return
        }
    }, [ fundList.funds ])

    const handleTabChange = (ev: MouseEvent<HTMLAnchorElement>) => {
        const {tab} = ev.currentTarget.dataset
        setFund(prev => ({
            ...prev,
            selectedClass: tab || prev.selectedClass,
        }))
    }

    const chartBackgroundColor = (context: ScriptableContext<"line">) => { 
        const ctx = context.chart.ctx
        const gradient = ctx.createLinearGradient(0, 0, 0, 200)
        gradient.addColorStop(0, "rgba(59, 130, 255, 0.2)")
        gradient.addColorStop(1, "rgba(59, 130, 255, 0.03)")
        return gradient
    }

    const verticalLinePlugin = {
        id: 'verticalLinePlugin',
        afterDraw: (chart: any) => {
            if (chart.tooltip._active && chart.tooltip._active.length) {
                const ctx = chart.ctx;
                ctx.save();
    
                const activePoint = chart.tooltip._active[0];
                const x = activePoint.element.x;
                const topY = chart.scales.y.top;
                const bottomY = chart.scales.y.bottom;
    
                ctx.beginPath();
                ctx.setLineDash([5, 5]);
                ctx.moveTo(x, topY);
                ctx.lineTo(x, bottomY);
                ctx.lineWidth = 1;
                ctx.strokeStyle = 'rgba(169, 169, 169, 0.8)';
                ctx.stroke();
                ctx.restore();
            }
        }
    };
    
    return <div className="bg-whtie pb-24">
        {fund.loading &&  <>
            <ArrowLeftCircleIcon className="fixed mt-2 ml-2 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={() => navigate("/portfolio")}/>
            <div className="text-center hg-bg-blue h-32 pt-8 text-white" style={{ borderBottomLeftRadius: "40%", borderBottomRightRadius: "40%"}}>
                <h1 className="text-lg text-center mt-5 font-medium">......</h1>
                <h1 className="text-lg text-center font-medium"><span className="uppercase">...</span></h1>
            </div>
            <div className="text-center"><Loader classNames="w-12 h-12 mt-20 text-blue-100"/></div>
        </>
        }
        

        {!fund.loading && 
        <>
        <ArrowLeftCircleIcon className="fixed mt-2 ml-2 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={() => navigate("/portfolio")}/>
        <div className="text-center hg-bg-blue h-32 pt-6 text-white" style={{ borderBottomLeftRadius: "40%", borderBottomRightRadius: "40%"}}>
            <h1 className="text-lg text-center mt-5 font-medium">{fund.fund.name}</h1>
            <h1 className="text-lg text-center font-medium"><span className="uppercase">{fund.fund.code}</span></h1>
        </div>
        <nav className="flex justify-around pt-3 border-b">
            {fund.fund.classes?.map((tab) => (
                <a key={tab.label} onClick={handleTabChange} data-tab={tab.label} className={`${tab.label === fund.selectedClass ? "border-b hg-border-b-blue hg-text-blue" : "border-transparent text-gray-500 "} w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium cursor-pointer`}>
                    Class {tab.label}
                </a>
            ))}
        </nav>
        <div className="mt-6">
        <Line
            className="w-full"
            height={210}
            data={{
                labels: new Array<string>(fund.fund.classes?.find(c => c.label === fund.selectedClass)?.historicalNetAssetValuePerUnit.length || 0).fill(""),
                datasets: [
                    {
                        label: "",
                        data: fund.fund.classes?.find(c => c.label === fund.selectedClass)?.historicalNetAssetValuePerUnit,
                        backgroundColor: chartBackgroundColor,
                        borderColor: "rgb(59 130 255 / 1)",
                        fill: true,
                        pointBorderWidth: 0.5,
                        tension: 0.3,
                    },
                ],
            }}
            options={{
                responsive: true,
                events: ['mousemove', 'click', 'touchstart', 'touchmove'],
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                scales: {
                    x: {
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        grid: {
                            display: false
                        },
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (item: any) => {
                                return (item.raw as number)?.toLocaleString("en-my", { minimumFractionDigits: 4, maximumFractionDigits: 4 })
                            }
                        }
                    },
                    legend: {
                        display: false,
                    },
                    verticalLinePlugin: {},
                } as any
            }}
            plugins={window.innerWidth < 1024 ? [verticalLinePlugin] : []}
        />
 
        </div>

        <hr className="mt-3"/>
        <div className="p-3">
            <h1 className="text-blue-900 text-md font-medium">Objective:</h1>
            <p className="pr-2 text-sm mt-2">{fund.fund.investmentObjective}</p>
        </div>

        <div className="mt-3">
            <h1 className="pl-3 text-blue-900 text-md font-medium">Net asset value:</h1>
            <div className="grid grid-cols-3 text-center">
                <div className="text-blue-900 border-b"></div>
                <div className="text-blue-900 border-b text-sm">Total</div>
                <div className="text-blue-900 border-b text-sm">NAV per unit</div>
                
                {fund.fund.classes?.map((c: FundClass) => {
                    const netAssetValue = c.netAssetValue ? c.netAssetValue : 0
                    const netAssetValuePerUnit = c.netAssetValuePerUnit ? c.netAssetValuePerUnit : c.launchPrice
                    return <Fragment key={c.label}>
                        <div className="text-blue-900 w-24 text-sm mt-3">Class {c.label}</div>
                        <div className="text-gray-800 text-xs  mt-3">{netAssetValue.toLocaleString("en-my", { style: "currency", currency: "myr", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                        <div className="text-gray-800 text-xs  mt-3">{netAssetValuePerUnit.toLocaleString("en-my", { style: "currency", currency: "myr", minimumFractionDigits: 4, maximumFractionDigits: 4 })}</div>
                    </Fragment>
                })}
            </div>
            <span className="pt-2 pr-2 text-gray-800 text-xs text-right block">Last updated {moment(fund.fund.classes?.at(0)?.lastUpdatedAt).format("Do MMM YYYY")}</span>
        </div>

        {/* {fund.fund.classes?.map((c: FundClass) => {
            return <>
                <hr className="mt-3"/>
                <div className="p-3">
                    <h1 className="text-blue-900 text-md font-medium">Class {c.label} - Performance:</h1>
                    <div className="grid grid-cols-4 gap-1 mt-2">
                        <div className="col-span-1 text-sm text-blue-950 ">1M</div>
                        <div className="col-span-1 text-sm text-black">{c.performance.oneMonth?.toLocaleString("en-my", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</div>
                        
                        <div className="col-span-1 text-sm text-blue-950 ">6M</div>
                        <div className="col-span-1 text-sm text-black">{c.performance.sixMonth?.toLocaleString("en-my", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</div>
                        
                        <div className="col-span-1 text-sm text-blue-950 ">YTD</div>
                        <div className="col-span-1 text-sm text-black">{c.performance.yearToDate?.toLocaleString("en-my", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</div>
                        
                        <div className="col-span-1 text-sm text-blue-950 ">1Y</div>
                        <div className="col-span-1 text-sm text-black">{c.performance.oneYear?.toLocaleString("en-my", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</div>
                        
                        <div className="col-span-1 text-sm text-blue-950 ">3Y</div>
                        <div className="col-span-1 text-sm text-black">{c.performance.threeYears?.toLocaleString("en-my", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</div>
                        
                        <div className="col-span-1 text-sm text-blue-950 ">MAX</div>
                        <div className="col-span-1 text-sm text-black">{c.performance.max?.toLocaleString("en-my", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</div>
                    </div>
                </div>
                </>
        })} */}

        {fund.fund.classes?.map((c: FundClass) => {
            return <Fragment key={c.label}>
                <hr className="mt-3"/>
                <div className="p-3">
                    <h1 className="text-blue-900 text-md font-medium">Class {c.label} - Limits:</h1>
                    <div className="flex flex-col gap-2 mt-2">
                        <div className="flex justify-between">
                            <div className="text-sm text-blue-950 font-light">Minimum initial investment</div>
                            <div className="text-sm text-black font-light">{c.limits.minimumInitialInvestment?.toLocaleString("en-my", { style: "currency", currency: "myr", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                        </div>
                        
                        <div className="flex justify-between">
                            <div className="text-sm text-blue-950 font-light">Minimum additional investment</div>
                            <div className="text-sm text-black font-light">{c.limits.minimumAdditionalInvestment?.toLocaleString("en-my", { style: "currency", currency: "myr", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                        </div>
                        
                        {c.limits.minimumUnitsHeld > 0 && <div className="flex justify-between">
                            <div className="text-sm text-blue-950 font-light">Minimum units held</div>
                            <div className="text-sm text-black font-light">{c.limits.minimumUnitsHeld?.toLocaleString("en-my", { minimumFractionDigits: 4, maximumFractionDigits: 4 })}</div>
                        </div>}
                        <div className="flex justify-between">
                            <div className="text-sm text-blue-950 font-light">Minimum redemption amount</div>
                            <div className="text-sm text-black font-light">{c.limits.minimumRedemptionAmount?.toLocaleString("en-my", { style: "currency", currency: "myr", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                        </div>
                    </div>
                </div>
        </Fragment>})}

        {!fund.fund?.code?.startsWith("pm-") && <React.Fragment>
            <hr className="mt-3"/>
            <div className="p-3">
                <h1 className="text-blue-900 text-md font-medium">Documents:</h1>
                <div className="grid grid-cols-2 gap-3 mt-2">
                    {Array.from(fund.fund?.documents || []).map((v) => {
                        return <Fragment key={v.label}>
                            <span className="text-blue-950  text-sm font-light">{v.label}</span>
                            <a className="text-blue-800 text-sm text-right cursor-pointer" target="_blank" href={v.url}>View</a>
                        </Fragment>
                    })}
                </div>
            </div>
        </React.Fragment>}

        </>}
    </div>
}