import {createContext, useState} from 'react'

export interface INotificationContext {
    type: "error" | "success" | ""
    visible: boolean
    title: string
    text: string
    onSuccess: Function
    onError: Function
    onClear: Function
}

export const NotificationContext = createContext<INotificationContext>({
    type: "",
    title: "",
    text: "",
    visible: false,
    onSuccess: (title: string, text: string) => {},
    onError: (title: string, text: string) => {},
    onClear: () => {},
})

const NotificationProvider = (props: any) => {
    const [state, setState] = useState({
        visible: false
    } as {
        type: "error" | "success" | ""
        text: string
        title: string
        visible: boolean
    })

    const onSuccess = (title: string, text: string) => {
        setState({
            type: "success",
            text,
            title,
            visible: true
        })
    }
    const onError = (title: string, text: string) => {
        setState({
            type: "error",
            text,
            title,
            visible: true
        })
    }
    const onClear = () => {
        setState({
            type: "",
            text: "",
            title: "",
            visible: false
        })
    }
    return <NotificationContext.Provider value={{visible: state.visible, onSuccess, onError, onClear, title: state.title, text: state.text, type: state.type}}>
        {props.children}
    </NotificationContext.Provider>
}

export default {
    NotificationContext,
    NotificationProvider,
}
