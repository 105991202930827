import Layout from '../../layout';
import Help from './help';
import List from './list';
import Profile from './profile';
import { Navigate } from "react-router-dom";
import ProfileWizard from './profile_wizard';
import CreateBankAccount from './bank_account_create';
import ListBankAccount from './bank_account_list';
import Statement from './statement';


export default [
    {
      path: "/settings",
      element: <Layout><List /></Layout>,
      errorElement: <Navigate to="/" />
    },
    {
      path: "/settings/profile",
      element: <Layout><Profile /></Layout>,
      errorElement: <Navigate to="/" />
    },
    {
      path: "/settings/profile/wizard",
      element: <Layout><ProfileWizard /></Layout>,
      errorElement: <Navigate to="/" />
    },
    {
      path: "/settings/bank-account/list",
      element: <Layout><ListBankAccount /></Layout>,
      errorElement: <Navigate to="/" />
    },
    {
      path: "/settings/bank-account/create",
      element: <Layout><CreateBankAccount /></Layout>,
      errorElement: <Navigate to="/" />
    },
    // {
    //   path: "/settings/profile/bank",
    //   element: <Layout><UpdateProfileBank /></Layout>,
    // },
    // {
    //   path: "/settings/profile/permanent-address",
    //   element: <Layout><UpdateProfileAddress /></Layout>,
    // },
    // {
    //   path: "/settings/profile/correspondence-address",
    //   element: <Layout><UpdateProfileAddress /></Layout>,
    // },
    {
      path: "/settings/statement",
      element: <Layout><Statement /></Layout>,
    },
    // {
    // path: "/settings/delegate",
    //   element: <Layout><Delegate /></Layout>,
    // },
    // {
    //   path: "/settings/delegate/create",
    //   element: <Layout><CreateDelegate /></Layout>,
    // },
    {
      path: "/settings/help",
      element: <Layout><Help /></Layout>,
      errorElement: <Navigate to="/" />
    },
]
