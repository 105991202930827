import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Loader } from "../../component";
import { IProfileContext, ProfileContext } from "./context";

export default function Profile() {
    const navigate = useNavigate()
    const profile = useContext(ProfileContext)
    const [state, setState] = useState<IProfileContext>(profile)
    
    useEffect(() => {
        setState(profile)
    }, [ profile.loading ])

    const {client, loading} = state

    const header = <>
        <div className="relative h-32 text-center hg-bg-blue mb-10" style={{ borderBottomLeftRadius: "40%", borderBottomRightRadius: "40%"}}>
            <ArrowLeftCircleIcon className="fixed mt-2 ml-2 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={() => navigate("/settings")}/>
            <h1 className="pt-12 text-xl text-white pl-3 pr-6">Profile</h1>
        </div>
    </>

    if (loading) {
        return <div className="pb-24">
            {header}
            <div className="text-center">
                <Loader classNames="w-12 h-12 text-blue-100" />
            </div>
        </div>
    }

    return <div className="pb-24">
        {header}

        <label className="block ml-5">Full name</label>
        <div className="flex justify-between ml-5 mr-5 mt-2">
            <label className="text-gray-700 uppercase">{client.name}</label>
        </div>
        <hr className="mt-5 mb-5" />

        
        {client.type === "individual" && <>
                <label className="block ml-5">Nationality</label>
                <div className="flex justify-between ml-5 mr-5 mt-2">
                    <label className="text-gray-700 capitalize">{client.individual.nationality}</label>
                </div>
                <hr className="mt-5 mb-5" />
                
                
                {client.individual.nationality === "malaysia" && <>
                        <label className="block ml-5">NRIC no.</label>
                        <div className="flex justify-between ml-5 mr-5 mt-2">
                            <label className="text-gray-700">{client.individual.nricNo}</label>
                        </div>
                        <hr className="mt-5 mb-5" />
                    </>
                }
                {client.individual.nationality !== "malaysia" && <>
                        <label className="block ml-5">Passport no.</label>
                        <div className="flex justify-between ml-5 mr-5 mt-2">
                            <label className="text-gray-700 uppercase">{client.individual.passportNo}</label>
                        </div>
                        <hr className="mt-5 mb-5" />
                    </>
                }
                <label className="block ml-5">Mobile no.</label>
                <div className="flex justify-between ml-5 mr-5 mt-2">
                    <label className="text-gray-700">{client.individual.msisdn}</label>
                </div>
                <hr className="mt-5 mb-5" />
                
                <label className="block ml-5">Email</label>
                <div className="flex justify-between ml-5 mr-5 mt-2">
                    <label className="text-gray-700 lowercase">{client.individual.email}</label>
                </div>
                <hr className="mt-5 mb-5" />

                <label className="block ml-5">Permanent address</label>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">Line 1</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.individual.permanentAddressLine1}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">Line 2</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.individual.permanentAddressLine2}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">City</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.individual.permanentAddressCity}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">Postcode</label>
                    <label className="text-gray-700 w-5/6 ml-5">{client.individual.permanentAddressPostcode}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">State</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.individual.permanentAddressState}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">Country</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.individual.permanentAddressCountry}</label>
                </div>
                {/* <div className="flex justify-end ml-5 mr-5 mt-4">
                    <a onClick={() => navigate("/settings/profile/permanent-address")} className="text-blue-700 font-medium">Update</a>
                </div> */}
                <hr className="mt-5 mb-5" />
                
                <label className="block ml-5">Correspondence address</label>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">Line 1</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.individual.correspondenceAddressLine1}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">Line 2</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.individual.correspondenceAddressLine2}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">City</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.individual.correspondenceAddressCity}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">Postcode</label>
                    <label className="text-gray-700 w-5/6 ml-5">{client.individual.correspondenceAddressPostcode}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">State</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.individual.correspondenceAddressState}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">Country</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.individual.correspondenceAddressCountry}</label>
                </div>
                {/* <div className="flex justify-end ml-5 mr-5 mt-4">
                    <a onClick={() => navigate("/settings/profile/correspondence-address")} className="text-blue-700 font-medium">Update</a>
                </div> */}
                <hr className="mt-5 mb-5" />
            </>
        }
        
        {client.type === "corporate" && <>
                <label className="block ml-5">Company registration no.</label>
                <div className="flex justify-between ml-5 mr-5 mt-2">
                    <label className="text-gray-700 capitalize">{client.corporate.companyRegistrationNo}</label>
                </div>
                <hr className="mt-5 mb-5" />
                
                {client.corporate.oldCompanyRegistrationNo && client.corporate.oldCompanyRegistrationNo !== "" && <>
                    <label className="block ml-5">Old company registration no.</label>
                    <div className="flex justify-between ml-5 mr-5 mt-2">
                        <label className="text-gray-700 capitalize">{client.corporate.oldCompanyRegistrationNo}</label>
                    </div>
                    <hr className="mt-5 mb-5" />
                    </>
                }

                <label className="block ml-5">Date of incorporation</label>
                <div className="flex justify-between ml-5 mr-5 mt-2">
                    <label className="text-gray-700 capitalize">{client.corporate.dateOfIncorporation}</label>
                </div>
                <hr className="mt-5 mb-5" />
                
                <label className="block ml-5">Country of incorporation</label>
                <div className="flex justify-between ml-5 mr-5 mt-2">
                    <label className="text-gray-700 capitalize">{client.corporate.countryOfIncorporation}</label>
                </div>
                <hr className="mt-5 mb-5" />
                
                <label className="block ml-5">Authorised person</label>
                <div className="flex ml-5 mr-5 mt-3">
                    <label className="text-gray-700 w-2/6">Name</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.corporate.authorisedPersonName}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-3">
                    <label className="text-gray-700 w-2/6">Email</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.corporate.authorisedPersonEmail}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-3">
                    <label className="text-gray-700 w-2/6">Mobile no.</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.corporate.authorisedPersonMsisdn}</label>
                </div>
                <hr className="mt-5 mb-5" />
                
                <label className="block ml-5">Registered address</label>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">Line 1</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.corporate.registeredAddressLine1}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">Line 2</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.corporate.registeredAddressLine2}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">City</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.corporate.registeredAddressCity}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">Postcode</label>
                    <label className="text-gray-700 w-5/6 ml-5">{client.corporate.registeredAddressPostcode}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">State</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.corporate.registeredAddressState}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">Country</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.corporate.registeredAddressCountry}</label>
                </div>
                {/* <div className="flex justify-end ml-5 mr-5 mt-4">
                    <a onClick={() => navigate("/settings/profile/registered-address")} className="text-blue-700 font-medium">Update</a>
                </div> */}
                <hr className="mt-5 mb-5" />
                
                <label className="block ml-5">Correspondence address</label>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">Line 1</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.corporate.correspondenceAddressLine1}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">Line 2</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.corporate.correspondenceAddressLine2}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">City</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.corporate.correspondenceAddressCity}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">Postcode</label>
                    <label className="text-gray-700 w-5/6 ml-5">{client.corporate.correspondenceAddressPostcode}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">State</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.corporate.correspondenceAddressState}</label>
                </div>
                <div className="flex ml-5 mr-5 mt-4">
                    <label className="text-gray-700 w-1/6">Country</label>
                    <label className="text-gray-700 w-5/6 ml-5 uppercase">{client.corporate.correspondenceAddressCountry}</label>
                </div>
                {/* <div className="flex justify-end ml-5 mr-5 mt-4">
                    <a onClick={() => navigate("/settings/profile/correspondence-address")} className="text-blue-700 font-medium">Update</a>
                </div> */}
                <hr className="mt-5 mb-5" />
            </>
        }

    </div>
}
