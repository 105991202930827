import { ArrowLeftCircleIcon, ClipboardIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useContext} from "react";
import { PersonaContext } from "../login/context";
import { DepositContext } from "./context";
import HSBTCFLogo from "../../asset/hsbtcf-logo";
import { getClientAccountDepositAsset, GetClientAccountDepositAssetOutput } from "../../api";
import { AxiosError } from "axios";
import { Loader } from "../../component";

interface DepositCollectionState {
    output: GetClientAccountDepositAssetOutput
    loading: boolean
}

interface ClipboardState {
    accountBank: boolean
    accountName: boolean
    accountNumber: boolean
    referenceNumber: boolean
}

export default function DepositCollection() {
    const [copied, setCopied] = useState(false)
    const [state, setState] = useState<DepositCollectionState>({
        output: {} as GetClientAccountDepositAssetOutput,
        loading: true,
    })
    const [clipboard, setClipboard] = useState<ClipboardState>({
        accountBank: false,
        accountName: false,
        accountNumber: false,
        referenceNumber: false,
    })
    const persona = useContext(PersonaContext)
    const deposit = useContext(DepositContext)
    
    const { chain, asset } = useParams()
    
    const navigate = useNavigate()

    useEffect(() => {
        if (!persona.clientId || !persona.accountId) {
            navigate("/auth/email")
            return
        }
        if (!persona.canInvest || !chain || !asset) {
            navigate("/portfolio")
            return
        }
        getClientAccountDepositAsset({
            clientId: persona.clientId,
            accountId: persona.accountId,
            chain,
            asset,
        }).then((resp) => {
            setState({
                loading: false,
                output: resp.data,
            })
        }).catch((err: AxiosError) => {
            if (err.response?.status === 401) navigate("/auth/email")
        })
    },[])

    const handleBack = () => {
        navigate("/deposit")
    }
    
    const renderCollection = () => {
        // fiat
        if (state.output.assetType === "fiat") {
            const copyToClipboard = (field: string, text: string) => {
                setClipboard(prev => ({ ...prev, [field]: true }))
                setTimeout(() => {
                    setClipboard(prev => ({ ...prev, [field]: false }))
                }, 1000)
                window.navigator.clipboard.writeText(text);
            }
            return <div className="pb-24">
                <div className="flex flex-col text-sm font-medium 2xs:mx-5">
                    <div className="text-sm font-medium bg-slate-200 text-gray-800 border-gray-300 rounded-lg p-5 mb-5 mt-5">
                        We will match your deposit using the bank <b>account name</b> and <b>reference number</b>. Please make sure you deposit from a bank account in your name.
                    </div>
                    <div className="flex flex-col gap-3 mb-5">
                        <div className="flex flex-col gap-1 cursor-pointer" onClick={() => copyToClipboard("accountBank", state.output.collection?.bankAccount.accountBank)}>
                            <span className="text-md font-medium text-gray-600">Bank name</span>
                            <div className="flex justify-between gap-1">
                                <span>{clipboard.accountBank ? "Copied!" : state.output.collection?.bankAccount.accountBank}</span>
                                {!clipboard.accountBank ? <ClipboardIcon className="w-5 h-5"/> : <></> }
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 cursor-pointer" onClick={() => copyToClipboard("accountName", state.output.collection?.bankAccount?.accountName)}>
                            <span className="text-md font-medium text-gray-600">Account name</span>
                            <div className="flex justify-between gap-1">
                                <span>{clipboard.accountName ? "Copied!" : state.output.collection?.bankAccount?.accountName}</span>
                                {!clipboard.accountName ? <ClipboardIcon className="w-5 h-5"/> : <></> }
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 cursor-pointer" onClick={() => copyToClipboard("accountNumber", state.output.collection?.bankAccount?.accountNumber)}>
                            <span className="text-md font-medium text-gray-600">Account number</span>
                            <div className="flex justify-between gap-1">
                                <span>{clipboard.accountNumber ? "Copied!" : state.output.collection?.bankAccount?.accountNumber}</span>
                                {!clipboard.accountNumber ? <ClipboardIcon className="w-5 h-5"/> : <></> }
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 cursor-pointer" onClick={() => copyToClipboard("referenceNumber", state.output.collection?.bankAccount?.referenceNumber)}>
                            <span className="text-md font-medium text-gray-600">Reference number</span>
                            <div className="flex justify-between gap-1">
                                <span>{clipboard.referenceNumber ? "Copied!" : state.output.collection?.bankAccount?.referenceNumber}</span>
                                {!clipboard.referenceNumber ? <ClipboardIcon className="w-5 h-5"/> : <></> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }


        // digital
        const handleCopyLink = () => {
            if (state.output.assetType !== "digital") return
            setCopied(true)
            window.navigator.clipboard?.writeText(state.output.collection?.address);
            setTimeout(() => setCopied(false), 500)
        }
        return <div className="flex flex-col gap-3">
            <div className="flex gap-3 mt-5 mx-5 text-sm bg-red-700 text-red-100 p-3 rounded-md">
                <span>{state.output.collection?.warningText}</span>
            </div>
            <div className="flex gap-2 justify-center">
                <HSBTCFLogo className="w-6 h-6"/>
                <span className="text-xl uppercase font-medium">{state.output.asset}</span>
                <span className="text-xs rounded-sm bg-gray-400 text-gray-100 my-auto p-1 capitalize">{state.output.assetLabel}</span>
            </div>
            <div className="flex flex-col gap-3 mx-5">
                <img src={`data:image/png;base64, ${state.output.collection?.qrcodeBase64Image}`} onClick={handleCopyLink} />
                <p className="text-sm font-medium mx-5" style={{wordWrap: "break-word"}} onClick={handleCopyLink}>{state.output.collection?.address}</p>
                <div className="flex justify-center">
                    <button className={`mx-auto p-3 text-white rounded-full ${!copied ? "hg-bg-blue" : "bg-blue-400"}`} onClick={handleCopyLink}>
                        {!copied && `Copy ${asset?.toUpperCase()} address`}
                        {copied && "Copied!"}
                    </button>
                </div>
            </div>
        </div>
    }

    if (state.loading) {
        return <div className="flex items-center justify-center h-full">
            <Loader classNames="w-12 h-12 text-blue-100" />
        </div>
    }

    return <div className="pb-16">
        <div className="relative h-16 text-center hg-bg-blue">
            <ArrowLeftCircleIcon className="fixed mt-4 ml-4 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={handleBack}/>
            <h1 className="pt-5 text-md text-white">Deposit</h1>
        </div>
        <div className="flex flex-col md:w-2/3 md:mx-auto">
            {renderCollection()}
        </div>
    </div>
}

