import { ArrowTrendingUpIcon, CubeIcon, CubeTransparentIcon, StopCircleIcon } from "@heroicons/react/24/outline";
import {  ArrowUpCircleIcon } from "@heroicons/react/20/solid";
import HalogenLogo from "../asset/halogen-logo";
import HSRIFLogo from "../asset/hsrif-logo";

export function AccountValuePlaceholderColorful() {
    return <div className="mr-3 mt-3 from-blue-400 to-emerald-400 bg-gradient-to-tr rounded-xl animate-pulse">
    <div className="pl-3 pt-3">
        <span className="block text-emerald-900">Portfolio value</span>
        <span className="block text-2xl text-white font-bold">.. ...</span>
    </div>
    <div className="pl-3 pt-1">
        <span className="text-sm font-medium text-white">... ..</span>
        <span className="text-xs text-white block">.. ...</span>
    </div>
    <div className="pt-5 pb-3 pr-3">
        <span className="block text-xs font-medium text-white text-right">... - ...</span>
    </div>
</div>
}

{/* <div className="mt-5 rounded-xl" style={{background: "linear-gradient(to top right, #004BC3, 70%, #116cff)"}}>
    <div className="pl-3 pt-3">
        <span className="block text-blue-100">Portfolio value</span>
        <span className="block text-2xl text-white font-bold">{state.accountValue?.portfolioValue.toLocaleString("en-my", { style: "currency", currency: "myr", maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
    </div>
    <div className="pl-3 pt-1">
        <span className="text-sm font-medium text-white">{state.accountValue?.pnlAmount > 0 && "+"}{state.accountValue?.pnlAmount.toLocaleString("en-my", {style: "currency", currency: "myr", maximumFractionDigits: 2, minimumFractionDigits: 2})} ({state.accountValue.pnlPercentage?.toLocaleString("en-my", {maximumFractionDigits: 2, minimumFractionDigits: 2})}%)</span>
        <span className="text-xs text-white block">Since {moment(state.accountValue.since).format("DD MMM YYYY")}</span>
    </div>
    <div className="pt-5 pb-3 pr-3">
        <span className="block text-xs font-medium text-white text-right">{state.accountValue.accountName} - {state.accountValue.accountId}</span>
    </div>
</div> */}
export function AccountValuePlaceholder() {
    return <div className="mt-5 rounded-xl animate-pulse mr-3" style={{background: "linear-gradient(to top right, #004BC3, 70%, #116cff)"}}>
    <div className="pl-3 pt-3">
        <span className="block text-blue-100">Portfolio value</span>
        <span className="block text-2xl text-white font-bold">.. ...</span>
    </div>
    <div className="pl-3 pt-1">
        <span className="text-sm font-medium text-white">... ..</span>
        <span className="text-xs text-white block">.. ...</span>
    </div>
    <div className="pt-5 pb-3 pr-3">
        <span className="block text-xs font-medium text-white text-right">... - ...</span>
    </div>
</div>
}

export function AccountBreakdownPlaceholder() {
    return <div className="flex justify-between mt-2 animate-pulse">
    <div className="w-2/6 ml-2 break-words uppercase">...</div>
    <div className="w-3/6 flex flex-col gap-1">
        <span className="text-sm text-black font-medium">...</span>
        <span className={`text-xs text-gray-600 font-medium`}>...</span>
    </div>
    <div className="">
        <div className="ml-2 break-words uppercase">...</div>
    </div>
</div>
}

export function AccountBreakdownCardPlaceholder() {
    return <div className="relative inline-block mr-5 bg-gray-50 hover:bg-gray-100 rounded-xl cursor-pointer w-72 h-48 border border-blue-100 animate-pulse">
        <div className="p-3 h-full ">
            <div className="flex flex-col justify-between h-full">
                <div className="flex flex-col">
                    <div className="flex gap-1">
                        {/* <StopCircleIcon className="text-gray-300 w-10 h-10" /> */}
                        <div className="flex flex-col">
                            <span className="text-lg font-medium text-black">...</span>
                            <label className="text-xs font-medium text-gray-700">...</label>
                        </div>
                    </div>
                </div>
                
                <div className="flex flex-col">
                    <span className="text-lg text-black font-medium">...</span>
                    <div className="flex">
                        <span className={`text-sm mt-auto mb-auto text-gray-600 font-medium`}>...</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="absolute right-0 bottom-0" style={{ width: 240, height: 80 }}>
        </div>
    </div>
}

export function AccountRequestsPlaceholder() {
    return  <>
        <span className=" bg-gray-50 mb-1 block text-sm rounded-sm text-gray-800 border p-2 font-medium capitalize">...</span>
        <div className="flex justify-between p-2 animate-pulse">
            <div><ArrowUpCircleIcon className="w-6 h-6 hg-text-blue"/></div>
            <div className="w-2/6 ml-2 break-words uppercase">...</div>
            <div className="w-3/6 flex flex-col gap-1">
                    <span className="text-sm">...</span>
            </div>
            <div>
                <span className="text-xs ml-2 font-medium bg-gray-200 text-gray-800 border-gray-300 border p-1 rounded-md">...</span>
            </div>
        </div>
    </>
}

export function HeaderPlaceholder() {
    return  <div className="flex justify-between animate-pulse">
        <div className="flex">
            <HalogenLogo color="blue" className="w-9" />
            <h1 className="text-lg font-bold ml-3 mt-auto mb-auto">...</h1>
        </div>
        {/* <div className="mt-auto mb-auto mr-3">
            <span className="text-xs font-medium bg-white text-gray-500 border-gray-500 border p-1 rounded-md">...</span>
        </div> */}
    </div>
}

