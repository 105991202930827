interface BitcoinProps {
    className?: string
}


export default function HSETHFLogo({ className }: BitcoinProps) {
    return <svg className={className} viewBox="0 0 87 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M43.1201 0.587932C43.4517 0.401115 43.8585 0.408956 44.1827 0.608412L85.7657 26.1965C86.0832 26.3919 86.2728 26.7414 86.2636 27.1141C86.2543 27.4868 86.0476 27.8264 85.7208 28.0058L43.8447 50.9923C43.5202 51.1705 43.1257 51.164 42.8072 50.9753L0.931149 26.1595C0.609521 25.9689 0.413943 25.6212 0.418032 25.2474C0.422121 24.8736 0.625256 24.5303 0.950976 24.3468L43.1201 0.587932ZM3.557 25.2817L43.3608 48.8695L83.1355 27.0365L43.6106 2.71483L3.557 25.2817Z" fill="#6D8FF3"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.1201 0.587932C43.4517 0.401115 43.8585 0.408956 44.1827 0.608412L85.7657 26.1965C86.0832 26.3919 86.2728 26.7414 86.2636 27.1141C86.2543 27.4868 86.0476 27.8264 85.7208 28.0058L43.8447 50.9923C43.5202 51.1705 43.1257 51.164 42.8072 50.9753L0.931149 26.1595C0.609521 25.9689 0.413943 25.6212 0.418032 25.2474C0.422121 24.8736 0.625256 24.5303 0.950976 24.3468L43.1201 0.587932ZM3.557 25.2817L43.3608 48.8695L83.1355 27.0365L43.6106 2.71483L3.557 25.2817Z" fill="#F6B444"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.1201 0.587932C43.4517 0.401115 43.8585 0.408956 44.1827 0.608412L85.7657 26.1965C86.0832 26.3919 86.2728 26.7414 86.2636 27.1141C86.2543 27.4868 86.0476 27.8264 85.7208 28.0058L43.8447 50.9923C43.5202 51.1705 43.1257 51.164 42.8072 50.9753L0.931149 26.1595C0.609521 25.9689 0.413943 25.6212 0.418032 25.2474C0.422121 24.8736 0.625256 24.5303 0.950976 24.3468L43.1201 0.587932ZM3.557 25.2817L43.3608 48.8695L83.1355 27.0365L43.6106 2.71483L3.557 25.2817Z" fill="#88A4F6"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.1201 0.587932C43.4517 0.401115 43.8585 0.408956 44.1827 0.608412L85.7657 26.1965C86.0832 26.3919 86.2728 26.7414 86.2636 27.1141C86.2543 27.4868 86.0476 27.8264 85.7208 28.0058L43.8447 50.9923C43.5202 51.1705 43.1257 51.164 42.8072 50.9753L0.931149 26.1595C0.609521 25.9689 0.413943 25.6212 0.418032 25.2474C0.422121 24.8736 0.625256 24.5303 0.950976 24.3468L43.1201 0.587932ZM3.557 25.2817L43.3608 48.8695L83.1355 27.0365L43.6106 2.71483L3.557 25.2817Z" fill="#6D84F3"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.3399 49.0235C43.9181 49.0235 44.3868 49.4922 44.3868 50.0704V97.5398L84.6727 73.0823C85.1669 72.7822 85.8108 72.9396 86.1109 73.4339C86.4109 73.9281 86.2535 74.572 85.7593 74.872L43.8832 100.295C43.56 100.491 43.156 100.498 42.8265 100.312C42.4969 100.127 42.293 99.7783 42.293 99.4001V50.0704C42.293 49.4922 42.7617 49.0235 43.3399 49.0235Z" fill="#6D8FF3"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.3399 49.0235C43.9181 49.0235 44.3868 49.4922 44.3868 50.0704V97.5398L84.6727 73.0823C85.1669 72.7822 85.8108 72.9396 86.1109 73.4339C86.4109 73.9281 86.2535 74.572 85.7593 74.872L43.8832 100.295C43.56 100.491 43.156 100.498 42.8265 100.312C42.4969 100.127 42.293 99.7783 42.293 99.4001V50.0704C42.293 49.4922 42.7617 49.0235 43.3399 49.0235Z" fill="#F6B444"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.3399 49.0235C43.9181 49.0235 44.3868 49.4922 44.3868 50.0704V97.5398L84.6727 73.0823C85.1669 72.7822 85.8108 72.9396 86.1109 73.4339C86.4109 73.9281 86.2535 74.572 85.7593 74.872L43.8832 100.295C43.56 100.491 43.156 100.498 42.8265 100.312C42.4969 100.127 42.293 99.7783 42.293 99.4001V50.0704C42.293 49.4922 42.7617 49.0235 43.3399 49.0235Z" fill="#88A4F6"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.3399 49.0235C43.9181 49.0235 44.3868 49.4922 44.3868 50.0704V97.5398L84.6727 73.0823C85.1669 72.7822 85.8108 72.9396 86.1109 73.4339C86.4109 73.9281 86.2535 74.572 85.7593 74.872L43.8832 100.295C43.56 100.491 43.156 100.498 42.8265 100.312C42.4969 100.127 42.293 99.7783 42.293 99.4001V50.0704C42.293 49.4922 42.7617 49.0235 43.3399 49.0235Z" fill="#6D84F3"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.46487 24.2109C2.04306 24.2109 2.51178 24.6796 2.51178 25.2578V73.6224L43.8806 98.5073C44.3761 98.8053 44.5361 99.4486 44.2381 99.944C43.94 100.439 43.2968 100.599 42.8013 100.301L0.925227 75.1115C0.610472 74.9221 0.417969 74.5817 0.417969 74.2144V25.2578C0.417969 24.6796 0.886684 24.2109 1.46487 24.2109Z" fill="#6D8FF3"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.46487 24.2109C2.04306 24.2109 2.51178 24.6796 2.51178 25.2578V73.6224L43.8806 98.5073C44.3761 98.8053 44.5361 99.4486 44.2381 99.944C43.94 100.439 43.2968 100.599 42.8013 100.301L0.925227 75.1115C0.610472 74.9221 0.417969 74.5817 0.417969 74.2144V25.2578C0.417969 24.6796 0.886684 24.2109 1.46487 24.2109Z" fill="#F6B444"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.46487 24.2109C2.04306 24.2109 2.51178 24.6796 2.51178 25.2578V73.6224L43.8806 98.5073C44.3761 98.8053 44.5361 99.4486 44.2381 99.944C43.94 100.439 43.2968 100.599 42.8013 100.301L0.925227 75.1115C0.610472 74.9221 0.417969 74.5817 0.417969 74.2144V25.2578C0.417969 24.6796 0.886684 24.2109 1.46487 24.2109Z" fill="#88A4F6"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.46487 24.2109C2.04306 24.2109 2.51178 24.6796 2.51178 25.2578V73.6224L43.8806 98.5073C44.3761 98.8053 44.5361 99.4486 44.2381 99.944C43.94 100.439 43.2968 100.599 42.8013 100.301L0.925227 75.1115C0.610472 74.9221 0.417969 74.5817 0.417969 74.2144V25.2578C0.417969 24.6796 0.886684 24.2109 1.46487 24.2109Z" fill="#6D84F3"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M85.2188 26.0391C85.797 26.0391 86.2657 26.5078 86.2657 27.086V73.9793C86.2657 74.5574 85.797 75.0261 85.2188 75.0261C84.6406 75.0261 84.1719 74.5574 84.1719 73.9793V27.086C84.1719 26.5078 84.6406 26.0391 85.2188 26.0391Z" fill="#6D8FF3"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M85.2188 26.0391C85.797 26.0391 86.2657 26.5078 86.2657 27.086V73.9793C86.2657 74.5574 85.797 75.0261 85.2188 75.0261C84.6406 75.0261 84.1719 74.5574 84.1719 73.9793V27.086C84.1719 26.5078 84.6406 26.0391 85.2188 26.0391Z" fill="#F6B444"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M85.2188 26.0391C85.797 26.0391 86.2657 26.5078 86.2657 27.086V73.9793C86.2657 74.5574 85.797 75.0261 85.2188 75.0261C84.6406 75.0261 84.1719 74.5574 84.1719 73.9793V27.086C84.1719 26.5078 84.6406 26.0391 85.2188 26.0391Z" fill="#88A4F6"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M85.2188 26.0391C85.797 26.0391 86.2657 26.5078 86.2657 27.086V73.9793C86.2657 74.5574 85.797 75.0261 85.2188 75.0261C84.6406 75.0261 84.1719 74.5574 84.1719 73.9793V27.086C84.1719 26.5078 84.6406 26.0391 85.2188 26.0391Z" fill="#6D84F3"/>
        <path d="M11.1641 33.1257V67.3861C11.1641 67.8501 11.2863 68.306 11.5184 68.7079C11.7505 69.1098 12.0844 69.4435 12.4864 69.6756L42.1673 86.8058C42.5694 87.0378 43.0255 87.16 43.4897 87.16C43.954 87.16 44.41 87.0378 44.8121 86.8058L74.4923 69.6756C74.8943 69.4435 75.2281 69.1098 75.4602 68.7079C75.6923 68.306 75.8146 67.8501 75.8146 67.3861V33.1257C75.8146 32.6616 75.6924 32.2057 75.4603 31.8038C75.2282 31.4019 74.8943 31.0681 74.4923 30.8361L44.8119 13.7059C44.4099 13.4738 43.9538 13.3516 43.4895 13.3516C43.0253 13.3516 42.5692 13.4738 42.1671 13.7059L12.4864 30.8361C12.0843 31.0681 11.7505 31.4019 11.5184 31.8038C11.2862 32.2057 11.1641 32.6616 11.1641 33.1257Z" fill="#6D8FF3"/>
        <path d="M11.1641 33.1257V67.3861C11.1641 67.8501 11.2863 68.306 11.5184 68.7079C11.7505 69.1098 12.0844 69.4435 12.4864 69.6756L42.1673 86.8058C42.5694 87.0378 43.0255 87.16 43.4897 87.16C43.954 87.16 44.41 87.0378 44.8121 86.8058L74.4923 69.6756C74.8943 69.4435 75.2281 69.1098 75.4602 68.7079C75.6923 68.306 75.8146 67.8501 75.8146 67.3861V33.1257C75.8146 32.6616 75.6924 32.2057 75.4603 31.8038C75.2282 31.4019 74.8943 31.0681 74.4923 30.8361L44.8119 13.7059C44.4099 13.4738 43.9538 13.3516 43.4895 13.3516C43.0253 13.3516 42.5692 13.4738 42.1671 13.7059L12.4864 30.8361C12.0843 31.0681 11.7505 31.4019 11.5184 31.8038C11.2862 32.2057 11.1641 32.6616 11.1641 33.1257Z" fill="#F6B444"/>
        <path d="M11.1641 33.1257V67.3861C11.1641 67.8501 11.2863 68.306 11.5184 68.7079C11.7505 69.1098 12.0844 69.4435 12.4864 69.6756L42.1673 86.8058C42.5694 87.0378 43.0255 87.16 43.4897 87.16C43.954 87.16 44.41 87.0378 44.8121 86.8058L74.4923 69.6756C74.8943 69.4435 75.2281 69.1098 75.4602 68.7079C75.6923 68.306 75.8146 67.8501 75.8146 67.3861V33.1257C75.8146 32.6616 75.6924 32.2057 75.4603 31.8038C75.2282 31.4019 74.8943 31.0681 74.4923 30.8361L44.8119 13.7059C44.4099 13.4738 43.9538 13.3516 43.4895 13.3516C43.0253 13.3516 42.5692 13.4738 42.1671 13.7059L12.4864 30.8361C12.0843 31.0681 11.7505 31.4019 11.5184 31.8038C11.2862 32.2057 11.1641 32.6616 11.1641 33.1257Z" fill="#88A4F6"/>
        <path d="M11.1641 33.1257V67.3861C11.1641 67.8501 11.2863 68.306 11.5184 68.7079C11.7505 69.1098 12.0844 69.4435 12.4864 69.6756L42.1673 86.8058C42.5694 87.0378 43.0255 87.16 43.4897 87.16C43.954 87.16 44.41 87.0378 44.8121 86.8058L74.4923 69.6756C74.8943 69.4435 75.2281 69.1098 75.4602 68.7079C75.6923 68.306 75.8146 67.8501 75.8146 67.3861V33.1257C75.8146 32.6616 75.6924 32.2057 75.4603 31.8038C75.2282 31.4019 74.8943 31.0681 74.4923 30.8361L44.8119 13.7059C44.4099 13.4738 43.9538 13.3516 43.4895 13.3516C43.0253 13.3516 42.5692 13.4738 42.1671 13.7059L12.4864 30.8361C12.0843 31.0681 11.7505 31.4019 11.5184 31.8038C11.2862 32.2057 11.1641 32.6616 11.1641 33.1257Z" fill="#6D8FF3"/>
        <path d="M11.1641 33.1257V67.3861C11.1641 67.8501 11.2863 68.306 11.5184 68.7079C11.7505 69.1098 12.0844 69.4435 12.4864 69.6756L42.1673 86.8058C42.5694 87.0378 43.0255 87.16 43.4897 87.16C43.954 87.16 44.41 87.0378 44.8121 86.8058L74.4923 69.6756C74.8943 69.4435 75.2281 69.1098 75.4602 68.7079C75.6923 68.306 75.8146 67.8501 75.8146 67.3861V33.1257C75.8146 32.6616 75.6924 32.2057 75.4603 31.8038C75.2282 31.4019 74.8943 31.0681 74.4923 30.8361L44.8119 13.7059C44.4099 13.4738 43.9538 13.3516 43.4895 13.3516C43.0253 13.3516 42.5692 13.4738 42.1671 13.7059L12.4864 30.8361C12.0843 31.0681 11.7505 31.4019 11.5184 31.8038C11.2862 32.2057 11.1641 32.6616 11.1641 33.1257Z" fill="url(#paint0_linear_7507_2163)"/>
        <path d="M11.1641 33.1257V67.3861C11.1641 67.8501 11.2863 68.306 11.5184 68.7079C11.7505 69.1098 12.0844 69.4435 12.4864 69.6756L42.1673 86.8058C42.5694 87.0378 43.0255 87.16 43.4897 87.16C43.954 87.16 44.41 87.0378 44.8121 86.8058L74.4923 69.6756C74.8943 69.4435 75.2281 69.1098 75.4602 68.7079C75.6923 68.306 75.8146 67.8501 75.8146 67.3861V33.1257C75.8146 32.6616 75.6924 32.2057 75.4603 31.8038C75.2282 31.4019 74.8943 31.0681 74.4923 30.8361L44.8119 13.7059C44.4099 13.4738 43.9538 13.3516 43.4895 13.3516C43.0253 13.3516 42.5692 13.4738 42.1671 13.7059L12.4864 30.8361C12.0843 31.0681 11.7505 31.4019 11.5184 31.8038C11.2862 32.2057 11.1641 32.6616 11.1641 33.1257Z" fill="url(#paint1_linear_7507_2163)"/>
        <circle opacity="0.18" cx="44.0449" cy="50.459" r="22.834" fill="url(#paint2_radial_7507_2163)"/>
        <path d="M43.338 30.6875V45.5183L31.918 50.9822L43.338 30.6875Z" fill="#6D8FF3"/>
        <path d="M43.338 30.6875V45.5183L31.918 50.9822L43.338 30.6875Z" fill="#F6B444"/>
        <path d="M43.338 30.6875V45.5183L31.918 50.9822L43.338 30.6875Z" fill="#88A4F6"/>
        <path d="M43.338 30.6875V45.5183L31.918 50.9822L43.338 30.6875Z" fill="#F5F8FC"/>
        <path d="M43.338 45.5183V58.4114L31.918 50.9822L43.338 45.5183Z" fill="#6D8FF3"/>
        <path d="M43.338 45.5183V58.4114L31.918 50.9822L43.338 45.5183Z" fill="#F6B444"/>
        <path d="M43.338 45.5183V58.4114L31.918 50.9822L43.338 45.5183Z" fill="#88A4F6"/>
        <path d="M43.338 45.5183V58.4114L31.918 50.9822L43.338 45.5183Z" fill="#F5F8FC"/>
        <path d="M43.338 61.0531V70.9956L31.918 53.8287L43.338 61.0531Z" fill="#6D8FF3"/>
        <path d="M43.338 61.0531V70.9956L31.918 53.8287L43.338 61.0531Z" fill="#F6B444"/>
        <path d="M43.338 61.0531V70.9956L31.918 53.8287L43.338 61.0531Z" fill="#88A4F6"/>
        <path d="M43.338 61.0531V70.9956L31.918 53.8287L43.338 61.0531Z" fill="#F5F8FC"/>
        <path d="M43.338 30.6875V45.5183L54.7595 50.9822L43.338 30.6875Z" fill="#6D8FF3"/>
        <path d="M43.338 30.6875V45.5183L54.7595 50.9822L43.338 30.6875Z" fill="#F6B444"/>
        <path d="M43.338 30.6875V45.5183L54.7595 50.9822L43.338 30.6875Z" fill="#88A4F6"/>
        <path d="M43.338 30.6875V45.5183L54.7595 50.9822L43.338 30.6875Z" fill="#F5F8FC"/>
        <path d="M43.338 45.5183V58.4114L54.7595 50.9822L43.338 45.5183Z" fill="#6D8FF3"/>
        <path d="M43.338 45.5183V58.4114L54.7595 50.9822L43.338 45.5183Z" fill="#F6B444"/>
        <path d="M43.338 45.5183V58.4114L54.7595 50.9822L43.338 45.5183Z" fill="#88A4F6"/>
        <path d="M43.338 45.5183V58.4114L54.7595 50.9822L43.338 45.5183Z" fill="#F5F8FC"/>
        <path d="M43.338 61.0531V70.9956L54.7595 53.8287L43.338 61.0531Z" fill="#6D8FF3"/>
        <path d="M43.338 61.0531V70.9956L54.7595 53.8287L43.338 61.0531Z" fill="#F6B444"/>
        <path d="M43.338 61.0531V70.9956L54.7595 53.8287L43.338 61.0531Z" fill="#88A4F6"/>
        <path d="M43.338 61.0531V70.9956L54.7595 53.8287L43.338 61.0531Z" fill="#F5F8FC"/>
        <defs>
            <linearGradient id="paint0_linear_7507_2163" x1="43.4893" y1="13.3516" x2="43.4893" y2="87.16" gradientUnits="userSpaceOnUse">
            <stop stopColor="#6D8FF3"/>
            <stop offset="1" stopColor="#004BC3"/>
            </linearGradient>
            <linearGradient id="paint1_linear_7507_2163" x1="43.4893" y1="13.3516" x2="43.4893" y2="87.16" gradientUnits="userSpaceOnUse">
            <stop stopColor="#6DA1F3"/>
            <stop offset="1" stopColor="#6161F2"/>
            </linearGradient>
            <radialGradient id="paint2_radial_7507_2163" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(44.0449 50.459) rotate(90) scale(22.834)">
            <stop stopColor="#090C37"/>
            <stop offset="1" stopColor="#090C37" stopOpacity="0"/>
            </radialGradient>
        </defs>
    </svg>
}