import 'chart.js/auto';
import { ScriptableContext, TooltipItem, TooltipModel } from 'chart.js';
import { MouseEvent, useContext, useEffect, useState } from "react"
import { FundListContext } from "../fund/context"
import { useNavigate, useParams } from "react-router-dom"
import { ArrowLeftIcon, ArrowUturnDownIcon, ArrowsRightLeftIcon } from "@heroicons/react/24/outline"
import {  ArrowUpCircleIcon, GiftIcon } from "@heroicons/react/20/solid";
import { AccountRequest, IPortfolioContext, PortfolioContext } from "./context"
import { AccountBreakdown, AllocationPerformanceBalance, GetClientAccountAllocationPerformanceOutput, ListClientAccountFundClassRequests, ListClientAccountFundClassRequestsOutput, Timeframe, getClientAccountAllocationPerformance } from "../../api"
import HSRIFLogo from "../../asset/hsrif-logo"
import HSETHFLogo from "../../asset/hsethf-logo"
import HSCTFLogo from "../../asset/hsctf-logo"
import HSBTCFLogo from "../../asset/hsbtcf-logo"
import moment from "moment"
import { AccountRequestsPlaceholder } from "../../component/placceholder"
import { PersonaContext } from "../login/context"
import { Line } from "react-chartjs-2";
import { Loader } from '../../component';

interface AllocationState {
    fundName: string
    fundClassLabel: string
    
    breakdown: AccountBreakdown
    breakdownLoading: boolean
    
    performance: AllocationPerformanceBalance[]
    performanceLoading: boolean
    timeframe: Timeframe
    chartType: "balance" | "pnl"


    requests?: AccountRequest[]
    requestsLoading: boolean
}

export default function Allocation() {
    const {fundId, fundClassSequence} = useParams()
    const [state, setState] = useState<AllocationState>({
        fundName: "",
        fundClassLabel: "",

        breakdown: {} as AccountBreakdown,
        breakdownLoading: true,

        performance: [],
        timeframe: "MAX",
        chartType: "balance",
        performanceLoading: true,

        requests: [],
        requestsLoading: true,
    })
    const navigate = useNavigate()
    
    const persona = useContext(PersonaContext)
    const portfolio = useContext(PortfolioContext)
    const fundList = useContext(FundListContext)

    useEffect(() => {
        if (fundList.loading || portfolio.breakdownLoading || !fundId || !fundClassSequence) return
        if (!persona.authenticated) {
            navigate("/auth/email")
            return
        }
        const fundClassSequenceInt = parseInt(fundClassSequence)
        if (fundClassSequenceInt < 1) {
            navigate("/")
            return
        }
        const fund = fundList.funds?.filter(f => f.id === fundId)[0]
        const fundClass = fund.classes[fundClassSequenceInt -1]
        const breakdown = portfolio.breakdown?.filter(b => b.fundId === fundId && b.fundClassSequence === fundClassSequenceInt)
        if (!breakdown || breakdown?.length === 0) {
            return
        }
        getClientAccountAllocationPerformance({
            clientId: persona.clientId,
            accountId: persona.accountId,
            fundId,
            fundClassSequence: fundClassSequenceInt,
            interval: "day",
            timeframe: state.timeframe,
        }).then((resp) => {
            const data = resp.data as GetClientAccountAllocationPerformanceOutput
            setState(prev => ({
                ...prev,
                fundName: fund?.name,
                fundClassLabel: fundClass?.label,
                breakdown: breakdown[0],
                breakdownLoading: false,
                accountRequests: portfolio.accountRequests,
                performance: data.balance,
                performanceLoading: false,
            }))
        })
        ListClientAccountFundClassRequests({
            clientId: persona.clientId,
            accountId: persona.accountId,
            fundId,
            fundClassSequence: fundClassSequenceInt,
        }).then(resp => {
            const data = resp.data as ListClientAccountFundClassRequestsOutput
            setState(prev => ({
                ...prev,
                requestsLoading: false,
                requests: data.requests,
            }))
        })
    }, [fundList.loading, portfolio.breakdownLoading, portfolio.accountRequestsLoading])

    useEffect(() => {
        if(!fundId || !fundClassSequence) return
        setState(prev => ({...prev, performanceLoading: true}))
        getClientAccountAllocationPerformance({
            clientId: persona.clientId,
            accountId: persona.accountId,
            fundId,
            fundClassSequence: parseInt(fundClassSequence),
            interval: "day",
            timeframe: state.timeframe,
        }).then((resp) => {
            const data = resp.data as GetClientAccountAllocationPerformanceOutput
            setState(prev => ({
                ...prev,
                performance: data.balance,
                performanceLoading: false
            }))
        })
    }, [state.timeframe])

    const handleTimeframeClick = (ev: MouseEvent<HTMLLIElement>) => {
        const {timeframe} = ev.currentTarget.dataset
        if (timeframe !== "1M" && timeframe !== "3M" && timeframe !== "6M" && timeframe !== "1Y" && timeframe !== "YTD" && timeframe !== "MAX") {
            return
        }
        setState(prev => ({
            ...prev,
            timeframe,
        }))
    }
    
    const handleChartTypeClick = (ev: MouseEvent<HTMLLIElement>) => {
        const chartType = ev.currentTarget.dataset.charttype
        if (chartType !== "balance" && chartType !== "pnl") {
            return
        }
        setState(prev => ({
            ...prev,
            chartType,
        }))
    }

    const renderRequestType = (r: AccountRequest) => {
        if (r.switchId && r.type === "purchase") {
            return "switch in"
        }
        if (r.switchId && r.type === "redeem") {
            return "switch out"
        }
        if (r.rebateId && r.type === "purchase") {
            return "rebate"
        }
        if (r.type === "purchase") {
            return "subscription"
        }
        if (r.type === "redeem") {
            return "redemption"
        }
        return ""
    }

    const verticalLinePlugin = {
        id: 'verticalLinePlugin',
        afterDraw: (chart: any) => {
            if (chart.tooltip._active && chart.tooltip._active.length) {
                const ctx = chart.ctx;
                ctx.save();
    
                const activePoint = chart.tooltip._active[0];
                const x = activePoint.element.x;
                const topY = chart.scales.y.top;
                const bottomY = chart.scales.y.bottom;
    
                ctx.beginPath();
                ctx.setLineDash([5, 5]);
                ctx.moveTo(x, topY);
                ctx.lineTo(x, bottomY);
                ctx.lineWidth = 1;
                ctx.strokeStyle = 'rgba(169, 169, 169, 0.8)';
                ctx.stroke();
                ctx.restore();
            }
        }
    };

    let lastRequestDate = ""

    if (state.breakdownLoading || state.requestsLoading) {
        return <div className="flex justify-center items-center h-full w-full">
            <Loader classNames="w-12 h-12 text-blue-100" />
        </div>
    }

    return <div className="pb-24">
        {/* Header */}
        <div className="flex justify-between hg-bg-blue text-white py-5 px-3">
            <div className="bg-blue-800 w-fit h-fit p-2.5 rounded-full cursor-pointer hover:bg-blue-900" onClick={() => navigate("/portfolio")}>
                <ArrowLeftIcon className="w-5 h-5" />
            </div>
            <div className="mx-1 flex flex-col items-center text-center">
                <label className="">{state.fundName}</label>
                <label className="text-sm">Class {state.fundClassLabel}</label>
            </div>
            <div className="p-2.5"></div>
        </div>

        {/* Body container */}
        <div className="flex flex-col gap-5 m-3">
            {/* Logo with portfolio value */}
            <div className="flex justify-center">
                <div className="flex flex-col items-center gap-2">
                    <div>
                        {state.breakdown?.fundCode === "hsrif" && <HSRIFLogo className="w-16 h-16" />}
                        {state.breakdown?.fundCode === "hsethf" && <HSETHFLogo className="w-16 h-16" />}
                        {state.breakdown?.fundCode === "hsctf" && <HSCTFLogo className="w-16 h-16" />}
                        {state.breakdown?.fundCode === "hsbtcf" && <HSBTCFLogo className="w-16 h-16" />}
                    </div>
                    <div className="flex flex-col gap-1 items-center">
                        <label className="font-medium 2xs:text-3xl md:text-4xl">{state.breakdown?.value?.toLocaleString("en-my", { style: "currency", currency: state.breakdown?.quoteAsset, minimumFractionDigits: 2, maximumFractionDigits: 2})}</label>
                        <label className="font-medium 2xs:text-md md:text-lg text-green-700">
                            {state.breakdown?.pnlAmount === 0 && <span className={`mt-auto mb-auto text-green-600 font-medium`}>{state.breakdown?.pnlAmount?.toLocaleString("en-my", { style: "currency", currency: state.breakdown?.quoteAsset, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                            {state.breakdown?.pnlAmount > 0 && <span className={`mt-auto mb-auto text-green-600 font-medium`}>+{state.breakdown?.pnlAmount?.toLocaleString("en-my", { style: "currency", currency: state.breakdown?.quoteAsset, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                            {state.breakdown?.pnlAmount < 0 && <span className={`mt-auto mb-auto text-red-600 font-medium`}>{state.breakdown?.pnlAmount?.toLocaleString("en-my", { style: "currency", currency: state.breakdown?.quoteAsset, minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                            
                            {state.breakdown?.pnlPercentage > 0 && <span className={`p-1 font-medium text-green-600`}>(+{state.breakdown?.pnlPercentage?.toLocaleString("en-my", {minimumFractionDigits:2, maximumFractionDigits: 2})}%)</span>}
                            {state.breakdown?.pnlPercentage === 0 && <span className={`p-1 font-medium text-green-600`}>({state.breakdown?.pnlPercentage?.toLocaleString("en-my", {minimumFractionDigits:2, maximumFractionDigits: 2})}%)</span>}
                            {state.breakdown?.pnlPercentage < 0 && <span className={`p-1 font-medium text-red-600`}>({state.breakdown?.pnlPercentage?.toLocaleString("en-my", {minimumFractionDigits:2, maximumFractionDigits: 2})}%)</span>}
                        </label>
                        {state.breakdown?.valuationDate ? <label className="text-xs text-gray-700">Valued on {moment(state.breakdown?.valuationDate).format("Do MMMM YYYY")}</label> : ""}
                    </div>
                </div>
            </div>

            {/* Chart */}
            <Line className="flex chart" data={
                {
                    labels: state.performance.map(b => b.date),
                    datasets: [
                        {
                            label: "Total value",
                            data: state.performance.map(b => b.value.amount),
                            segment: {
                                borderColor: (ctx) => {
                                    if (ctx.p0DataIndex === 0) {
                                        return
                                    }
                                    const value0 = state.performance[ctx.p0DataIndex]?.value?.amount
                                    const postFeeAmount0 = state.performance[ctx.p0DataIndex]?.postFeeAmount?.amount
                                    const value1 = state.performance[ctx.p1DataIndex]?.value?.amount
                                    const postFeeAmount1 = state.performance[ctx.p1DataIndex]?.postFeeAmount?.amount
                                    if( value0 < postFeeAmount0 || value1 < postFeeAmount1) {
                                        // red
                                        return "rgb(192, 75, 75)"
                                    }
                                    // green
                                    return "rgb(75, 192, 192)"
                                },
                            },
                            borderColor: "rgb(75, 192, 192)",
                            tension: 0,
                            pointRadius: 0,
                        },
                        {
                            label: "Total deposit",
                            data: state.performance.map(b => b.postFeeAmount.amount),
                            fill: false,
                            borderColor: "rgb(200, 200, 200)",
                            tension: 0,
                            pointRadius: 0,
                        },
                    ],
                }
            } options={
                {
                    responsive: true,
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    scales: {
                        x: {
                            ticks: {
                                maxTicksLimit: 6,
                                callback: function(value, index, ticks) {
                                    const date = this.getLabelForValue(value as number) as string
                                    return moment(date).format("DD MMM")
                                },
                            },
                            grid: {
                                display: false,
                            },
                        },
                        y: {
                            grid: {
                                display: false
                            },
                            ticks: {
                                maxTicksLimit: 4,
                                callback: function(value, index, ticks) {
                                    const valueNumber = value as number
                                    if (valueNumber >= 1000000) {
                                        return ((value as number) / 1000000).toString() + "M"
                                    }
                                    if (valueNumber >= 1000) {
                                        return ((value as number) / 1000).toString() + "K"
                                    }
                                    return ((value as number)).toString()
                                },
                            }
                            
                        },
                    },
                    plugins: {
                        tooltip: {
                            position: "nearest",
                            backgroundColor: "#fff",
                            titleColor: "rgb(31, 41, 55, 0.7)",
                            borderColor: "#ccc",
                            borderWidth: 1,
                            boxWidth: 0,
                            boxHeight: 0,
                            bodySpacing: 5,
                            titleMarginBottom: 10,
                            caretSize: 5,

                            callbacks: {
                                title: (items: TooltipItem<"line">[]) => {
                                    return moment(items[0].label).format("Do MMMM YYYY")
                                },
                                labelTextColor: (item: TooltipItem<"line">) => {
                                    if (item.datasetIndex === 0) {
                                        return "rgb(75, 192, 192)"
                                    }
                                    return "rgb(100, 100, 100)"
                                },
                                label: (item: TooltipItem<"line">) => {
                                    const value  =  (item.raw as number)?.toLocaleString("en-my", { style: "currency", currency: state.performance.at(0)?.value?.asset, minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                    if (item.datasetIndex === 0) {
                                        return item.dataset.label+"\t\t\t\t\t\t\t\t" + value
                                    }
                                    return item.dataset.label+"\t\t\t\t\t" + value
                                },
                            }
                        },
                        legend: {
                            labels: {
                                pointStyle: false,
                                usePointStyle: true,
                            },
                            display: false,
                        },
                    }
                }
            } 
            plugins={[verticalLinePlugin]}
            />

            {/* Timeframe */}
            <div className="flex justify-center">
                <ul className={`flex justify-around 2xs:w-full xs:w-full md:w-1/2 rounded-full p-1 bg-gray-50 text-xs`}>
                    <li className={`py-0.5 px-3 rounded-full cursor-pointer ${state.timeframe === "1M" && state.performanceLoading && "animate-pulse"} ${state.timeframe === "1M" ? "text-blue-700 bg-blue-200" : "text-gray-500"}`} onClick={handleTimeframeClick} data-timeframe="1M">1M</li>
                    <li className={`py-0.5 px-3 rounded-full cursor-pointer ${state.timeframe === "3M" && state.performanceLoading && "animate-pulse"} ${state.timeframe === "3M" ? "text-blue-700 bg-blue-200" : "text-gray-500"}`} onClick={handleTimeframeClick} data-timeframe="3M">3M</li>
                    <li className={`py-0.5 px-3 rounded-full cursor-pointer ${state.timeframe === "6M" && state.performanceLoading && "animate-pulse"} ${state.timeframe === "6M" ? "text-blue-700 bg-blue-200" : "text-gray-500"}`} onClick={handleTimeframeClick} data-timeframe="6M">6M</li>
                    <li className={`py-0.5 px-3 rounded-full cursor-pointer ${state.timeframe === "YTD" && state.performanceLoading && "animate-pulse"} ${state.timeframe === "YTD" ? "text-blue-700 bg-blue-200" : "text-gray-500"}`} onClick={handleTimeframeClick} data-timeframe="YTD">YTD</li>
                    <li className={`py-0.5 px-3 rounded-full cursor-pointer ${state.timeframe === "1Y" && state.performanceLoading && "animate-pulse"} ${state.timeframe === "1Y" ? "text-blue-700 bg-blue-200" : "text-gray-500"}`} onClick={handleTimeframeClick} data-timeframe="1Y">1Y</li>
                    <li className={`py-0.5 px-3 rounded-full cursor-pointer ${state.timeframe === "MAX" && state.performanceLoading && "animate-pulse"} ${state.timeframe === "MAX" ? "text-blue-700 bg-blue-200" : "text-gray-500"}`} onClick={handleTimeframeClick} data-timeframe="MAX">All-time</li>
                </ul>
            </div>

            {/* Details */}
            <div className="border border-blue-100 rounded-xl p-2">
                <div className="flex flex-col mr-auto ml-auto gap-3">
                    <div className="flex justify-between border-b pb-1">
                        <span className="font-medium text-sm text-slate-500 pr-10">Total deposit</span>
                        <span className="text-sm">{state.breakdown?.totalInflow?.toLocaleString("en-my", { currency: state.breakdown?.quoteAsset, style:"currency", minimumFractionDigits: 2, maximumFractionDigits:2 })}</span>
                    </div>
                    <div className="flex justify-between border-b pb-1">
                        <span className="font-medium text-sm text-slate-500 pr-10">Total withdrawal</span>
                        <span className="text-sm">{state.breakdown?.totalOutflow?.toLocaleString("en-my", { currency: state.breakdown?.quoteAsset, style:"currency", minimumFractionDigits: 2, maximumFractionDigits:2 })}</span>
                    </div>
                    <div className="flex justify-between border-b pb-1">
                        <span className="font-medium text-sm text-slate-500 pr-10">Net deposit</span>
                        <span className="text-sm">{state.breakdown?.netInflow?.toLocaleString("en-my", { currency: state.breakdown?.quoteAsset, style:"currency", minimumFractionDigits: 2, maximumFractionDigits:2 })}</span>
                    </div>
                    {state.breakdown?.totalRebate > 0 && <div className="flex justify-between border-b pb-1">
                        <span className="font-medium text-sm text-slate-500 pr-10">Total rebate</span>
                        <span className="text-sm">{state.breakdown?.totalRebate?.toLocaleString("en-my", { currency: state.breakdown?.quoteAsset, style:"currency", minimumFractionDigits: 2, maximumFractionDigits:2 })}</span>
                    </div>}
                    {state.breakdown?.canDistribute && <div className="flex justify-between border-b pb-1">
                        <span className="font-medium text-sm text-slate-500 pr-10">Distributed amount</span>
                        <span className="text-sm">{state.breakdown?.distributedAmount?.toLocaleString("en-my", { currency: state.breakdown?.quoteAsset, style:"currency", minimumFractionDigits: 2, maximumFractionDigits:2 })}</span>
                    </div>}
                    <div className="flex justify-between border-b pb-1">
                        <span className="font-medium text-sm text-slate-500 pr-10">Units</span>
                        <span className="text-sm">{state.breakdown?.units?.toLocaleString("en-my", { minimumFractionDigits: 4, maximumFractionDigits: 4 })}</span>
                    </div>
                    <div className="flex justify-between">
                        <span className="font-medium text-sm text-slate-500 pr-10">Allocation %</span>
                        <span className="text-sm">{state.breakdown?.allocationPercentage?.toLocaleString("en-my", { minimumFractionDigits: 2, maximumFractionDigits:2 })}%</span>
                    </div>
                </div>
            </div>

            {/* Transactions */}
            <div>
                <h1 className="text-md font-medium mb-3 text-blue-950">Transactions</h1>
                {state?.requestsLoading && <div className="pb-5 mr-3">
                    <AccountRequestsPlaceholder />
                </div>}
                {!state?.requestsLoading && <div className="pb-5 mr-3">
                    {state?.requests && state?.requests.length === 0 && <div className="text-center text-gray-700 border border-dashed p-6">
                        <p className="text-sm font-medium">No requests made yet</p>
                    </div>}
                    {state?.requests && state?.requests.length > 0 && state?.requests.map((r) => {
                        const date = moment(r.createdAt).format("Do MMMM")
                        const item =  <div key={r.id}>
                            {date !== lastRequestDate && <span className=" bg-gray-50 mb-1 block text-sm rounded-sm text-gray-800 border p-2 font-medium capitalize">{date}</span>}
                            <div className="flex justify-between py-2 pb-3 cursor-pointer" onClick={() => navigate(`/portfolio/request/${r.id}`)}>
                                {!r.switchId && r.type === "purchase"  && !r.rebateId && <div><ArrowUpCircleIcon className="w-6 h-6 hg-text-blue"/></div>}
                                {!r.switchId && r.type === "purchase" && r.rebateId && <div><GiftIcon className="w-5 h-5 text-white hg-bg-blue rounded-3xl p-1 mt-0.5"/></div>}
                                {!r.switchId && r.type === "redeem" && !r.switchId && <div><ArrowUturnDownIcon className="text-white hg-bg-blue rounded-3xl p-1 w-5 h-5" style={{ strokeWidth: 3}}/></div>}
                                {r.switchId && <div><ArrowsRightLeftIcon className="w-5 h-5 text-white hg-bg-blue rounded-xl stroke-2 p-0.5 font-bold"/></div>}
                                <div className="w-2/6 ml-1 break-words flex flex-col">
                                    <span className="text-sm capitalize">{r.fundShortName}</span>
                                    <span className="text-xs text-gray-800 capitalize">{renderRequestType(r)}</span>
                                </div>
                                
                                {r.type === "purchase" && <div className="w-3/6 flex flex-col gap-1">
                                    <span className="text-sm">{r.amount?.toLocaleString("en-my", { style: "currency", currency: r.fundClassBaseCurrency, maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                    <span className="text-gray-500 text-xs">{r.units ? r.units.toLocaleString("en-my", { minimumFractionDigits: 4, maximumFractionDigits: 4 }) + " units" : "-"}</span>
                                </div>}
                                {r.type === "redeem" && r.amount > 0 && r.units && <div className="w-3/6 flex flex-col gap-1">
                                    <span className="text-sm">{r.amount ? r.amount?.toLocaleString("en-my", { style: "currency", currency: r.fundClassBaseCurrency, maximumFractionDigits: 2, minimumFractionDigits: 2 }) : "-"}</span>
                                    <span className="text-gray-500 text-xs">{r.units?.toLocaleString("en-my", { maximumFractionDigits: 4, minimumFractionDigits: 4 })} units</span>
                                </div>}
                                {r.type === "redeem" && !r.amount && r.requestedAmount > 0 && <div className="w-3/6 flex flex-col gap-1">
                                    <span className="text-sm">{r.requestedAmount?.toLocaleString("en-my", { style: "currency", currency: r.fundClassBaseCurrency, maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                    <span className="text-gray-500 text-xs">-</span>
                                </div>}
                                {r.type === "redeem" && !r.amount && !r.requestedAmount && r.units && <div className="w-3/6 flex flex-col gap-1">
                                    <span className="text-sm">-</span>
                                    <span className="text-gray-500 text-xs">{r.units?.toLocaleString("en-my", { maximumFractionDigits: 4, minimumFractionDigits: 4 })} units</span>
                                </div>}
                                <div>
                                    {(r.status === "pending" || r.status === "processed") && <span className="text-xs ml-2 bg-orange-100 text-orange-800 border-orange-300 border p-1 rounded-md capitalize">{r.status}</span>}
                                    {(r.status === "completed" || r.status === "deposited") && <span className="text-xs ml-2 bg-green-200 text-green-800 border-green-300 border p-1 rounded-md capitalize">{r.status}</span>}
                                    {r.status === "cancelled" && <span className="text-xs ml-2 bg-gray-200 text-gray-800 border-gray-300 border p-1 rounded-md">Cancelled</span>}
                                </div>
                            </div>
                        </div>
                        lastRequestDate = date
                        return item
                    })}
                </div>}
            </div>

        </div>
    </div>
}
