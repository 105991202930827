import Layout from '../../layout';
import { Navigate } from "react-router-dom";
import Home from './home';
import Portfolio from './portfolio';
import Settings from './settings';
import CreatePortfolio from './create_portfolio';

export default [
  {
    path: "/v2",
    element: <Layout><Home /></Layout>,
    errorElement: <Navigate to="/" />
  },
  {
    path: "/v2/portfolio",
    element: <Layout><Portfolio /></Layout>,
    errorElement: <Navigate to="/" />
  },
  {
    path: "/v2/portfolio/create",
    element: <Layout><CreatePortfolio /></Layout>,
    errorElement: <Navigate to="/" />
  },
  {
    path: "/v2/portfolio/settings",
    element: <Layout><Settings /></Layout>,
    errorElement: <Navigate to="/" />
  }
]
