
import { ArrowTrendingUpIcon, Cog6ToothIcon, PlusCircleIcon, PlusIcon } from "@heroicons/react/24/outline"
import { useEffect, useRef, useState } from "react"
import SwipeableViews from "react-swipeable-views"
import HalogenLogo from "../../asset/halogen-logo"
import { useNavigate } from "react-router-dom"
import { Line } from "react-chartjs-2"

export default function Home() {
    const [slideIndex, setSlideIndex] = useState(0)

    const navigate = useNavigate()
    const canvasRef = useRef<HTMLCanvasElement>(null)

    const handleIndexChange = (index: number) => {
        setSlideIndex(index)
    }

    useEffect(() => {
        const canvas = canvasRef.current as HTMLCanvasElement
        if (!canvas) return
        const context = canvas.getContext('2d')
        if (!context) return
        // context.fillStyle = '#000000'
        // context.beginPath()
        // context.moveTo(0, 160)
        // context.lineTo(280, 0)
        // context.stroke()
    })

    
    return <div className="h-full">
        <div className="flex flex-col gap-12 h-full bg-black">
        {/* <div className="flex flex-col gap-12 h-full" style={{background: "linear-gradient(to top right, #00112e, 0%, #001435)"}}> */}
        {/* <div className="flex flex-col gap-12 h-full" style={{background: "radial-gradient(24% 12% at 15% 25%, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 1) 100%)"}}> */}
            <div className="flex flex-col">
                <div className="flex justify-between mt-5 mx-3">
                    <div className="flex">
                        <HalogenLogo color="blue" className="w-8" />
                        <h1 className="text-gray-300 text-2xl font-bold pl-3">Wallet</h1>
                    </div>
                    <div className="flex gap-5">
                        <ArrowTrendingUpIcon className="text-gray-300 stroke-2 w-6 h-6" />
                        <Cog6ToothIcon className="text-gray-300 w-6 h-6 stroke-2" />                
                    </div>
                </div>
                {/* <div className="flex justify-center mt-12 ">
                    <h3 className="text-xl px-3 text-gray-300">Good evening, Moon Ho!</h3>
                </div> */}
                <div className="flex flex-col items-center gap-1 mt-12 ">
                    <h3 className="text-md font-medium text-gray-400">Current investments</h3>
                    <h3 className="font-medium text-2xl text-gray-100">RM 1,736,934.43</h3>
                </div>
            </div>

            <SwipeableViews className="px-16" slideClassName="mx-1" enableMouseEvents onChangeIndex={handleIndexChange}>
                <div onClick={() => navigate("/v2/portfolio")} className={`flex flex-col justify-between h-60 w-60 rounded-2xl ${slideIndex !== 0 ? "scale-90" : ""}`} style={{background: "linear-gradient(to bottom left, #004BC3, 40%, #002561)"}}>
                    {/* <canvas ref={canvasRef} className="absolute w-full h-full boder z-10"></canvas> */}
                    <Line
                        className="absolute w-full h-full z-10"
                        height={300}
                        data={{
                            labels: ["", "", "", "", "", "", ""],
                            datasets: [
                                {
                                    label: "",
                                    data: [1, 3, 2, 4, 3, 5, 4],
                                    // backgroundColor: "green",
                                    // borderColor: "rgb(0 0 255 / 0.5)",
                                    borderColor: "#003c9c",
                                    fill: false,
                                    pointBorderWidth: 0.5,
                                    tension: 0.3,
                                },
                            ],
                        }}
                        options={{
                            responsive: true,
                            events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                            scales: {
                                x: {
                                    grid: {
                                        display: false
                                    }
                                },
                                y: {
                                    display: false,
                                    grid: {
                                        display: false
                                    },
                                },

                            },
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        label: (item) => {
                                            return (item.raw as number)?.toLocaleString("en-my", { minimumFractionDigits: 4, maximumFractionDigits: 4 })
                                        }
                                    }
                                },
                                legend: {
                                    display: false,
                                },
                            }
                        }}
                    />
                {/* <div className={`flex flex-col justify-between h-60 w-60 bg-blue-700 rounded-2xl ${slideIndex !== 0 ? "scale-90" : ""}`}> */}
                    <div className="z-20">
                        <div className="pl-3 pt-3">
                            <span className="block text-blue-100">Portfolio value</span>
                            <span className="block text-xl text-white font-medium">RM 302,635.40</span>
                        </div>
                        <div className="pl-3">
                            <span className="text-xs font-medium text-white">+RM 124,012.01</span>
                        </div>
                    </div>
                    <div className="pt-5 pb-3 pr-3">
                        <span className="block text-xs font-medium text-white text-right">NG MOON HO - 2307001003</span>
                    </div>
                </div>
                
                <div onClick={() => navigate("/v2/portfolio")} className={`flex flex-col justify-between h-60 w-60 rounded-2xl ${slideIndex !== 1 ? "scale-90" : ""}`} style={{background: "linear-gradient(to bottom left, #501078, 40%, #28083c)"}}>
                    {/* <div className={`flex flex-col justify-between h-60 w-60 bg-purple-700 rounded-2xl ${slideIndex !== 1 ? "scale-90" : ""}`}> */}
                    <div>
                        <div className="pl-3 pt-3">
                            <span className="block text-blue-100">Portfolio value</span>
                            <span className="block text-xl text-white font-medium">RM 302,635.40</span>
                        </div>
                        <div className="pl-3 pt-1">
                            <span className="text-xs font-medium text-white">+RM 124,012.01</span>
                        </div>
                    </div>
                    <div className="pt-5 pb-3 pr-3">
                        <span className="block text-xs font-medium text-white text-right">NG MOON HO - 2307001003</span>
                    </div>
                </div>
                
                <div onClick={() => navigate("/v2/portfolio")} className={`flex flex-col justify-between h-60 w-60 rounded-2xl ${slideIndex !== 2 ? "scale-90" : ""}`} style={{background: "linear-gradient(to bottom left, #E0218A, 40%, #590d37)"}}>
                {/* <div className={`flex flex-col justify-between h-60 w-60 bg-pink-700 rounded-2xl ${slideIndex !== 2 ? "scale-90" : ""}`}> */}
                    <div>
                        <div className="pl-3 pt-3">
                            <span className="block text-blue-100">Portfolio value</span>
                            <span className="block text-xl text-white font-medium">RM 302,635.40</span>
                        </div>
                        <div className="pl-3 pt-1">
                            <span className="text-xs font-medium text-white">+RM 124,012.01</span>
                        </div>
                    </div>
                    <div className="pt-5 pb-3 pr-3">
                        <span className="block text-xs font-medium text-white text-right">NG MOON HO - 2307001003</span>
                    </div>
                </div>
            </SwipeableViews>

            <div className="flex justify-center mt-12 border-blue-700">
                <PlusIcon onClick={() => navigate("/v2/portfolio/create")} className="p-1 bg-gray-800 rounded-full text-gray-300 w-12 h-12"/>
            </div>
        </div>
    </div>
}
