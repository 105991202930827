import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import OnboardingUspFirst from "../../asset/onboarding-usp-first";
import OnboardingUspSecond from "../../asset/onboarding-usp-second";
import OnboardingUspThird from "../../asset/onboarding-usp-third";
import { getSession } from "../../api";

export default function List() {
    const navigate = useNavigate()

    const [slideIndex, setSlideIndex] = useState(0)

    const handleIndexChange = (index: number) => {
        setSlideIndex(index)
    }
    
    const handleLogin = () => {
        navigate("/auth/email")
    }
    const handleRegister = () => {
        navigate("/create-account")
    }
   
    useEffect(() => {
        getSession().then((resp) => {
            if (resp.data.clientAuthenticated) {
                navigate(`/portfolio`)
                return
            }
        }).catch(console.error)
    }, [])

    return <div className="h-full flex flex-col justify-between md:justify-normal md:gap-6 overflow-hidden">
        <div className="flex flex-col items-center overflow-hidden">
            <SwipeableViews enableMouseEvents onChangeIndex={handleIndexChange}>
                <div className="flex flex-col items-center mx-10 my-5">
                    <OnboardingUspFirst />
                </div>
                <div className="flex flex-col items-center mx-10 my-5">
                    <OnboardingUspSecond />
                </div>
                <div className="flex flex-col items-center mx-10 my-5">
                    <OnboardingUspThird />
                </div>
            </SwipeableViews>
            <ul className="flex gap-3">
                <li className={`${slideIndex === 0 ? "bg-blue-600 px-4" : "bg-gray-300 px-2"} h-1 rounded-full`}></li>
                <li className={`${slideIndex === 1 ? "bg-blue-600 px-4" : "bg-gray-300 px-2"} h-1 rounded-full`}></li>
                <li className={`${slideIndex === 2 ? "bg-blue-600 px-4" : "bg-gray-300 px-2"} h-1 rounded-full`}></li>
            </ul>
            <div className="flex flex-col items-center text-center gap-3 mx-5 mt-5">
                {slideIndex === 0 && <>
                    <h3 className="text-4xl font-bold">Welcome to Halogen Wallet</h3>
                    <p className="px-5 text-gray-500">Your trusted gateway to the world of Digital Assets</p>
                </>}
                {slideIndex === 1 && <>
                    <h3 className="text-4xl font-bold">Wide range of funds</h3>
                    <p className="px-5 text-gray-500">Invest with confidence and grow with us</p>
                </>}
                {slideIndex === 2 && <>
                    <h3 className="text-4xl font-bold">Track and manage your portfolio</h3>
                    <p className="px-5 text-gray-500">Keeping track of your investment portfolio and more</p>
                </>}
            </div>
        </div>
        <div className="flex items-center flex-col gap-2 my-2">
            <button onClick={handleLogin} className="w-64 p-3 hg-bg-blue text-white rounded-full">Log in</button>
            <button onClick={handleRegister} className="w-64 p-3 hg-text-blue">Register</button>
        </div>
    </div>
}
