import {createContext, useContext, useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import { getFund, listClientAccountDepositAddresses, ListClientAccountDepositAddressesOutput, listFundHoldings, listFunds } from '../../api'
import { PersonaContext } from '../login/context';

export interface IDepositContext {
    output: ListClientAccountDepositAddressesOutput
    loading: boolean
}

const initialState: IDepositContext = {
    output: {} as ListClientAccountDepositAddressesOutput,
    loading: true,
}

export const DepositContext = createContext<IDepositContext>(initialState)

const DepositProvider = (props: any) => {
    const navigate = useNavigate()
    const persona = useContext(PersonaContext)
    const [state, setState] = useState<IDepositContext>(initialState)

    useEffect(() => {
        if (!persona.clientId || !persona.accountId) {
            navigate("/auth/email")
            return
        }
        listClientAccountDepositAddresses({ clientId: persona.clientId, accountId: persona.accountId }).then(resp => {
            setState(prev => ({
                ...prev,
                output: resp.data as ListClientAccountDepositAddressesOutput,
                loading: false,
            }))
        }).catch(err => {
            if (err.response?.status === 401) navigate("/auth/email")
        })
    }, [])
    

    return <DepositContext.Provider value={ state }>
        {props.children}
    </DepositContext.Provider>
}

export default {
    DepositContext,
    DepositProvider,
}
