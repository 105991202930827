import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HalogenLogo from "../../asset/halogen-logo";
import { Transition } from "@headlessui/react";
import { PersonaContext } from "./context";

export default function Onboarding() {
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const persona = useContext(PersonaContext)
    useEffect(() => {
        setShow(true)
        if (!persona.title) {
            setTimeout(() => {
                navigate("/persona/title")
            }, 2000)
            return
        }
        if (persona.clientId && persona.accountId) {
            navigate(`/portfolio`)
            return
        } else {
            navigate("/auth/email")
            return
        }
    }, [])
    return <div className="flex flex-col gap-12 w-full h-full hg-bg-blue opaci">
        <Transition show={show} enter="transition-opacity duration-1000" enterFrom="opacity-0" enterTo="opacity-100">
            <HalogenLogo color="white" className="ml-auto mr-auto pt-24 2xs:w-1/2 xs:w-1/2 md:w-1/3 animate-pulse" />
            <h1 className="mt-5 text-center 2xs:text-4xl xs:text-5xl md:text-7xl text-white font-medium  animate-pulse">Halo!</h1>
        </Transition>
    </div>
}
