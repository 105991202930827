interface HalogenLogoProps {
    color: "white" | "blue"
    className?: string
}


export default function HalogenLogo({ color, className }: HalogenLogoProps) {
    const colorHex = color === "white" ? "#FFFFFF" : "#004BC3"
    return <svg className={className} viewBox="0 0 702 800" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M408.545 297.903L258.683 385.27V68.2902C258.683 55.8146 265.329 44.2718 276.133 38.0535L333.925 4.66872C344.768 -1.58849 358.138 -1.54963 368.981 4.74645L425.801 37.8203C436.528 44.0775 443.135 55.5814 443.135 68.0181V237.662C443.135 262.458 429.96 285.427 408.507 297.903H408.545Z" fill={colorHex}/>
        <path d="M425.84 761.713L368.32 795.254C357.477 801.55 344.108 801.589 333.265 795.331L276.172 762.296C265.368 756.039 258.722 744.535 258.722 732.06V563.659C258.722 538.863 271.897 515.894 293.35 503.419L443.213 416.051V731.554C443.213 743.991 436.606 755.456 425.879 761.713H425.84Z" fill={colorHex}/>
        <path d="M184.452 418.927V709.168L34.745 622.384C13.2139 609.908 0 586.939 0 562.066V270.814L150.018 358.764C171.354 371.279 184.491 394.17 184.491 418.927H184.452Z" fill={colorHex}/>
        <path d="M517.405 381.462V91.0654L667.268 178.433C688.682 190.909 701.896 213.878 701.896 238.673V529.576L551.878 441.625C530.542 429.11 517.405 406.219 517.405 381.462Z" fill={colorHex}/>
        <path d="M51.7288 227.597L131.984 274.623C155.225 288.264 184.49 271.475 184.49 244.542V151.266C184.49 124.45 155.459 107.66 132.218 121.069L51.962 167.279C28.7986 180.648 28.6432 214.033 51.7288 227.558V227.597Z" fill={colorHex}/>
        <path d="M650.128 572.637L569.873 525.611C546.632 511.969 517.366 528.759 517.366 555.692V648.967C517.366 675.784 546.398 692.574 569.639 679.165L649.895 632.955C673.058 619.586 673.214 586.201 650.128 572.676V572.637Z" fill={colorHex}/>
    </svg>

}