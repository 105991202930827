import Layout from '../../layout';
import { Navigate } from "react-router-dom";
import Redeem from './redeem';

export default [
  {
    path: "/redeem",
    element: <Layout><Redeem /></Layout>,
    errorElement: <Navigate to="/" />
  }
]
