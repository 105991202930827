import { Fragment, useContext } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { CheckIcon, InformationCircleIcon, PencilSquareIcon, SparklesIcon, XMarkIcon, XCircleIcon } from '@heroicons/react/20/solid'
import Context from '../layout/context'

export default function Notification() {
    const notification = useContext(Context.NotificationContext)

    return <div className="pointer-events-none fixed inset-0 flex px-5 py-6 items-start p-6 z-50">
        <div className="flex w-full flex-col space-y-4 items-end">
        <Transition
            show={notification.visible}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="opacity-0 translate-y-0 translate-x-2"
            enterTo="translate-y-0 opacity-200 translate-x-0"
            leave="transition ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0 translate-x-0"
            leaveTo="opacity-0 translate-y-0 translate-x-20"
        >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-4">
                <div className="flex items-start">
                <div className="flex-shrink-0">
                    {notification.type === "success" && <CheckCircleIcon className="h-6 w-6 text-green-400" />}
                    {notification.type === "error" && <XCircleIcon className="h-6 w-6 text-red-400" />}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{notification.title}</p>
                    <p className="mt-1 text-sm text-gray-500">{notification.text}</p>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                    <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => notification.onClear()}
                    >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
                </div>
            </div>
            </div>
        </Transition>
        </div>
    </div>
}