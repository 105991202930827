interface TitansProps {
    color: "white" | "blue"
    className?: string
}


export default function Titans({ color, className }: TitansProps) {
    return <svg width="23" height="23" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0164 0.160889C5.38642 0.160889 0.0164185 5.54089 0.0164185 12.1609C0.0164185 18.7809 5.38642 24.1609 12.0164 24.1609C18.6464 24.1609 24.0164 18.7909 24.0164 12.1609C24.0164 5.53089 18.6464 0.160889 12.0164 0.160889ZM12.6764 7.64089L15.4464 2.96089L18.7464 4.91089L16.4464 8.80089C15.7364 10.0109 13.9764 9.99089 13.2764 8.77089L12.6764 7.72089C12.6764 7.72089 12.6664 7.67089 12.6764 7.64089ZM8.69642 3.07089L12.5664 9.38089H9.29642C8.63642 9.39089 8.03642 9.06089 7.69642 8.50089L5.54642 4.99089L8.69642 3.07089ZM2.64642 13.7109L2.66642 10.0009H7.04642C8.41642 10.0109 9.26642 11.4909 8.58642 12.6709L8.00642 13.6909C8.00642 13.6909 7.96642 13.7309 7.94642 13.7309L2.64642 13.7109ZM11.2064 17.7109L9.09642 21.3109L5.85642 19.4209L9.64642 12.9309L11.2364 15.8509C11.5464 16.4309 11.5364 17.1409 11.2064 17.7109ZM10.2764 11.1009H13.7464L12.0164 14.1009L10.2764 11.1009ZM15.0264 21.3709L12.8664 17.5709C12.1964 16.3909 13.0464 14.9209 14.4064 14.9209H15.4964C15.5764 14.9209 15.6464 14.9609 15.6864 15.0309L18.2464 19.5409L15.0264 21.3709ZM13.8964 13.7309L15.6164 10.9009C15.9564 10.3409 16.5664 9.99089 17.2264 9.99089H21.3864V13.7309H13.8964Z" fill="white"/>
    </svg>
}

