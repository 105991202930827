import { useNavigate } from "react-router-dom";
import { ChartPieIcon, Cog8ToothIcon, GiftIcon } from "@heroicons/react/24/outline";

export default function Tabs() {
    const activeTab = {
        portfolio: window.location.hash.startsWith("#/portfolio"),
        rewards: window.location.hash.startsWith("#/rewards"),
        settings: window.location.hash.startsWith("#/settings"),
    }
    const navigate = useNavigate()
    return <ul className="fixed bottom-3 left-3 right-3 bg-white grid grid-cols-3 border shadow-2xl rounded-2xl md:max-w-3xl md:mr-auto md:ml-auto">
        <li className={`col-span-1 p-3 mr-auto ml-auto cursor-pointer ${activeTab.portfolio && "hg-border-b-blue border-b-4"}`} onClick={() => navigate("/portfolio")}>
            <ChartPieIcon className={`w-6 h-6 mr-auto ml-auto ${activeTab.portfolio && "hg-text-blue"}`} />
            <span className={`text-xs ${activeTab.portfolio && "hg-text-blue"}`}>Portfolio</span>
        </li>
        <li className={`col-span-1 p-3 mr-auto ml-auto cursor-pointer ${activeTab.rewards && "hg-border-b-blue border-b-4"}`} onClick={() => navigate("/rewards")}>
            <GiftIcon className={`w-6 h-6 mr-auto ml-auto ${activeTab.rewards && "hg-text-blue"}`} />
            <span className={`text-xs ${activeTab.rewards && "hg-text-blue"}`}>Rewards</span>
        </li>
        <li className={`col-span-1 p-3 mr-auto ml-auto cursor-pointer ${activeTab.settings && "hg-border-b-blue border-b-4"}`} onClick={() => navigate("/settings")}>
            <Cog8ToothIcon className={`w-6 h-6 mr-auto ml-auto ${activeTab.settings && "hg-text-blue"}`} />
            <span className={`text-xs ${activeTab.settings && "hg-text-blue"}`}>Settings</span>
        </li>
    </ul>
}
