interface BitcoinProps {
    color: "white" | "blue"
    className?: string
}


export default function Bitcoin({ color, className }: BitcoinProps) {
    if (color == "blue") {
        return <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" stroke="#004BC3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9 8H14C15.1046 8 16 8.89543 16 10V10C16 11.1046 15.1046 12 14 12H9V8Z" stroke="#004BC3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9 12H14C15.1046 12 16 12.8954 16 14V14C16 15.1046 15.1046 16 14 16H9V12Z" stroke="#004BC3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 8V6M13.5 8V6" stroke="#004BC3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 18V16M13.5 18V16" stroke="#004BC3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    }
    return <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="10" fill="white"/>
        <path d="M9 8H14C15.1046 8 16 8.89543 16 10V10C16 11.1046 15.1046 12 14 12H9V8Z" stroke="#154BC5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 12H14C15.1046 12 16 12.8954 16 14V14C16 15.1046 15.1046 16 14 16H9V12Z" stroke="#154BC5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11 8V6M13.5 8V6" stroke="#154BC5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11 18V16M13.5 18V16" stroke="#154BC5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}