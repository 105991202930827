
import { ChangeEvent, MouseEvent, useEffect, useState, KeyboardEvent, useContext } from "react"
import { setProfileField } from "../../api"
import { Loader } from "../../component"
import { useNavigate } from "react-router-dom"
import { ArrowLeftCircleIcon, CheckBadgeIcon } from "@heroicons/react/24/outline"
import { ProfileContext } from "./context"
import { PersonaContext } from "../login/context"

interface ProfileWizardState {
    questions: Question[]

    stack: Question[]
    values: Map<string, any>

    currentQuestionIndex: number
    loading: boolean
}

interface Question {
    name: string
    component: (values: Map<string, any>) => JSX.Element
    showIf?: (values: Map<string, any>) => boolean
}

interface SubmitInput {
    name: string
    value: string | number | boolean | Object
    waitForServer?: boolean
}

export default function ProfileWizard() {
    const navigate = useNavigate()
    const [state, setState] = useState<ProfileWizardState>({
        questions: [],
        stack: [],
        loading: true,
        values: new Map<string, string | number | Object>(),
        currentQuestionIndex: 0,
    })

    const persona = useContext(PersonaContext)
    const profile = useContext(ProfileContext)

    useEffect(() => {
        if (profile.loading) {
            return
        }
        if (!profile.client.incompleteProfile) {
            navigate("/portfolio")
            return
        }
        // only individual clients allowed to complete profile (corporate onboarded with forms)
        if (profile.client.type !== "individual") {
            navigate("/portfolio")
            return
        }
        const values = new Map<string, string | number | Object>(Object.entries(profile.client.individual))
        const stack = updateStack(values)
        setState(prev => ({ ...prev, loading: false, stack, values }))
    }, [profile.loading])

    const updateStack = (values: Map<string, any>): Question[] => {
        const stack: Question[] = []
        for (let i = 0; i < questions.length; i++) {
            const q = questions[i]
            stack.push(q)
            if (!values.get(q.name)) {
                if (q.showIf) {
                    if (q.showIf(values)){
                        break
                    } else {
                        stack.pop()
                        continue
                    }
                }
                break
            }
        }
        return stack
    }
    
    const handleSubmit = (input: SubmitInput) => {
        const request = Object.fromEntries(state.values.set(input.name, input.value))
        const {clientId, accountId} = persona
        
        setState(prev => ({ ...prev, loading: true }))
        setProfileField({clientId, accountId, ...request}).then(() => {
            setState((prev) => {
                const values = new Map<string, string | number | Object>(prev.values).set(input.name, input.value)
                const stack = updateStack(values)
                return {
                    ...prev,
                    loading: false,
                    stack,
                    values,
                }
            })
            profile.reload()
        }).catch(() => {
            setState(prev => ({ ...prev, loading: false }))
        })
    }

    const handleBack = () => {
        setState(prev => {
            if (prev.stack.length <= 1) {
                return prev
            }
            const lastQuestion = prev.stack[prev.stack.length - 2]
            const values = new Map<string, any>(prev.values)
            // shouldn't happen lastQuestion is undefined
            if (lastQuestion) {
                values.delete(lastQuestion.name)
            }
            const stack = updateStack(values)
            return {
                ...prev,
                stack,
                values,
            }
        })
    }

    const ethnicityOptions = [
        { label: "Bumiputera", value: "bumiputera" },
        { label: "Chinese", value: "chinese" },
        { label: "Indian", value: "indian" },
        { label: "Other", value: "other" },
    ]
    const domesticRinggitBorrowingOptions = [
        { label: "I am a resident individual without Domestic Ringgit Borrowing /  I am a non-resident of Malaysia", value: "residentWithoutDrbOrNonResidentOfMalaysia" },
        { label: "I am a resident individual with Domestic Ringgit Borrowing but my investments in foreign currency assets have not exceeded RM1 million in aggregate this calendar year", value: "residentWithDRBButNotExceeded1M" },
        { label: "I am a resident individual with Domestic Ringgit Borrowing and my investments in foreign currency assets have exceeded RM1 million in aggregate this calendar year", value: "residentWithDRBExceeded1M" },
    ]
    const taxResidencyOptions = [
        { label: "I am a Malaysia tax resident only", value: "onlyMalaysia" },
        { label: "I am a Malaysia and non-Malaysia tax resident", value: "multiple" },
        { label: "I am a Non-Malaysia tax resident", value: "nonMalaysia" },
    ]
    const countryTaxOptions = [
        { label: "Singapore", value: "singapore" },
        { label: "Hong Kong", value: "hongkong" },
        { label: "Australia", value: "australia" },
        { label: "New Zealand", value: "newzealand" },
    ]
    const questions: Question[] = [
        {
            name: "ethnicity",
            component: () => <QuestionSelect text="What's your ethnicity?" name="ethnicity" handleSubmit={handleSubmit} options={ethnicityOptions} />,
            showIf: (values) => values.get("nationality") === "malaysia",
        },
        {
            name: "otherEthnicity",
            component: () => <QuestionText text="Please provide your other ethnicity" name="otherEthnicity" placeholder="Serani" handleSubmit={handleSubmit} />,
            showIf: (values) => values.get("ethnicity") === "other"
        },
        {
            name: "domesticRinggitBorrowing",
            component: () => <QuestionSelect text="Please select what describes your Domestic Ringgit Borrowing (DRB)." name="domesticRinggitBorrowing" handleSubmit={handleSubmit} options={domesticRinggitBorrowingOptions} />,
            showIf: (values) => values.get("nationality") === "malaysia",
        },
        {
            name: "taxResidency",
            component: () => <QuestionSelect text="Please select what describes your Tax Residency." name="taxResidency" handleSubmit={handleSubmit} options={taxResidencyOptions} />,
        },
        {
            name: "countryTax",
            component: () => <QuestionSelect text="What country you pay tax to?" name="countryTax" handleSubmit={handleSubmit} options={countryTaxOptions} />,
            showIf: (values) => values.get("taxResidency") !== "onlyMalaysia"
        },
        {
            name: "taxIdentificationNo",
            component: (values) => <QuestionText name="taxIdentificationNo" text={`What is your Tax identification number?`} placeholder="AB11223344" handleSubmit={handleSubmit} />,
            showIf: (values) => values.get("countryTax"),
        },
        {
            name: "outro",
            component: () => <Outro name="outro" text="" handleSubmit={handleSubmit}/>,
        },
    ]

    if (state.loading) {
        return <div className="flex justify-center items-center h-full">
            <Loader classNames="w-12 h-12 text-blue-50" />
        </div>
    }

    const lastQuestion = state.stack[state.stack.length - 1]
    if (lastQuestion.name === "onfido") {
        return lastQuestion.component(state.values)
    }

    return <div className="flex flex-col md:w-1/2 p-5 mx-auto h-full">
        {state.stack.length >= 2 && lastQuestion.name !== "employmentType" && lastQuestion.name !== "outro" && lastQuestion.name !== "blocked" && <div className="relative mb-10">
            <ArrowLeftCircleIcon className="fixed w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={handleBack}/>
        </div>}

        {lastQuestion.component(state.values)}
    </div>
}

interface QuestionProps {
    name: string
    text: string
    subtext?: string
    placeholder?: string
    handleSubmit: (input: SubmitInput) => void
    handleSubmitWithNoSave?: (input: SubmitInput) => void
}

function QuestionText(props: QuestionProps) {
    const [value, setValue] = useState("")
    const handleOnChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setValue(ev.currentTarget?.value)
    }
    const submitDisabled = !value || value === ""
    const handleSubmit = () => {
        if (submitDisabled) {
            return
        }
        const input= {name: props.name, value}
        props.handleSubmit(input)
        setValue("")
    }
    const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
        if (ev.key !== "Enter") {
            return
        }
        handleSubmit()
    }

    return <div className="flex flex-col xs:justify-between sm:justify-between md:justify-normal md:gap-20 h-full">
        <div className="flex flex-col items-start w-full gap-6">
            <div className="flex flex-col gap-2">
                <h3 className="text-blue-800 font-medium text-xl">
                    {props.text}
                </h3>
                {props.subtext && <h6 className="text-blue-800 font-medium text-md">
                    {props.subtext}
                </h6>}
            </div>
            <input type="text" onKeyDown={handleKeyDown} autoFocus={true} value={value} className="w-full p-3 hg-border-blue rounded-full" placeholder={props.placeholder} onChange={handleOnChange}/>
        </div>
        <div className="w-full">
            <button onClick={handleSubmit} disabled={submitDisabled} className="w-full p-3 hg-bg-blue text-white rounded-full disabled:bg-blue-300">Next</button>
        </div>
    </div>
}

interface QuestionSelectProps extends QuestionProps {
    multiple?: boolean
    detailsRenderer?: () => JSX.Element
    options: {
        label: string
        value: string
    }[]
}

function QuestionSelect(props: QuestionSelectProps) {
    const [selected, setSelected] = useState<Set<string>>(new Set<string>())
    const handleSelect = (ev: MouseEvent<HTMLLIElement>) => {
        const value = ev.currentTarget.dataset.value
        if (!value) return
        if (!props.multiple) {
            props.handleSubmit({name: props.name, value})
            return
        }
        setSelected(prev => {
            const set = new Set<string>(prev)
            if (prev.has(value)) {
                set.delete(value)
                return set
            }
            set.add(value)
            return set
        })
    }

    const handleSubmit = () => {
        props.handleSubmit({name: props.name, value: Array.from(selected)})
    }

    return <div className="flex flex-col xs:justify-between sm:justify-between md:justify-normal h-full md:gap-20">
        <div className="flex flex-col items-start w-full gap-6">
            <div className="flex flex-col gap-2">
                <h3 className="text-blue-800 font-medium text-xl">
                    {props.text}
                </h3>
                {props.multiple && <h6 className="text-blue-800 font-medium text-md">
                    Select one or more that apply to you.
                </h6>}
            </div>
            {!props.multiple && <ul className="w-full flex flex-col gap-5">
                {props.options?.map((o) => {
                    return <li key={o.value} onClick={handleSelect} data-value={o.value} className="bg-blue-50 font-medium text-blue-800 border border-blue-800 p-4 rounded-3xl cursor-pointer">{o.label}</li>
                })}
            </ul>}
            {props.multiple && <ul className="w-full flex flex-col gap-5">
                {props.options?.map((o) => {
                    return <li key={o.value} onClick={handleSelect} data-value={o.value} className={`font-medium p-4 rounded-3xl cursor-pointer border border-blue-800 ${!selected.has(o.value) ? "bg-blue-50 text-blue-800" : "bg-blue-800 text-white"}`}>{o.label}</li>
                })}
            </ul>}
            {props.detailsRenderer ? props.detailsRenderer() : <></>}
        </div>

        {props.multiple && <div className="w-full">
            <button onClick={handleSubmit} className="w-full p-3 hg-bg-blue text-white rounded-full disabled:bg-blue-300" disabled={selected.size === 0}>Next</button>
        </div>}
    </div>
}

function Outro(props: QuestionProps) {
    return <div className="flex flex-col xs:justify-between sm:justify-between md:justify-normal md:gap-20 h-full">
        <div className="flex flex-col items-center text-center w-full gap-6">
            <CheckBadgeIcon className="text-blue-700 w-24 h-24" />
            <h3 className="text-blue-800 font-medium text-xl">
                Thank you for completing your profile.
            </h3>
        </div>
        <a href="#/portfolio" className="text-center w-full p-3 hg-bg-blue text-white rounded-full">Home</a>
    </div>
}
