import { useContext, useEffect } from "react";
import { GetRequestByDuitNowOutput, getRequestByDuitNow } from "../../api";
import { PersonaContext } from "../login/context";
import { AxiosError } from "axios";

export default function DuitNow() {
    const navigate = (path: string) => {
        window.location.href = window.location.origin + path
    }
    const persona = useContext(PersonaContext)
    useEffect(() => {
        if (window.location.pathname !== "/RPP/MY/Redirect/RTP") {
            navigate("/#/portfolio")
            return
        }
        // https://wallet.halogen.my/RPP/MY/Redirect/RTP?EndtoEndId=20240527M0007615861OBW17573224&EndtoEndIdSignature=t13%2FN3cEE1PQ1cZpMNFZpt7M1EVrT84ZSTlBwDXJ6Ouf6crqIkx4smoc%2BaO4MI76sdIX7T7JI2uv3%2FpsCf3apztDPkwJY%2F6oIkjXiO%2FjG0xBCRK8vX4jC%2FDl3X%2BJqpomiaF%2F04V5zHnJb4bXObhMkOpu3X27dH0nYHk%2FR6455Lk7bTTGu3bsFnTnJ9%2B5E7Lk7lilpYJ6CE%2BAv%2BMMHWKeYL29paA700wOcZchREkferol3cLybu2j0ua9AHVH6rK9OWfcGTwCCR6FiNdZa8JwoYEZC4ajN9vSvUrMRDPT1UfNwmqKdiWCsb%2BXPJVRCdWEw6dhExL6XjbOx8PESXYpHg%3D%3D#/portfolio
        const url = new URL(window.location.href)
        const endToEndId = url.searchParams.get("EndtoEndId")
        const endToEndIdSignature = url.searchParams.get("EndtoEndIdSignature")
        if (!endToEndId || !endToEndIdSignature) {
            navigate("/#/portfolio")
            return
        }

        getRequestByDuitNow({
            clientId: persona.clientId,
            accountId: persona.accountId,
            endToEndId: endToEndId,
        }).then((resp) => {
            const data = resp.data as GetRequestByDuitNowOutput
            if (!data.request?.id) {
                navigate("/#/portfolio")
                return    
            }
            navigate("/#/portfolio/request/"+data.request.id)
        }).catch((err: AxiosError<Error>) => {
            navigate("/#/portfolio")
            return
        })
    }, [])

    return <div>Redirecting..</div>
}
