interface HourglassProps {
    className?: string
}


// export default function Hourglass({ className }: HourglassProps) {
//     return <svg className={className} width="151" height="245" viewBox="0 0 201 295" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <path d="M50.2415 100.483C77.9891 100.483 100.483 77.9891 100.483 50.2415C100.483 22.4939 77.9891 0 50.2415 0C22.4939 0 0 22.4939 0 50.2415C0 77.9891 22.4939 100.483 50.2415 100.483Z" fill="#A8C0CC"/>
//         <path d="M50.2416 93.6917C44.3771 93.6917 38.6838 92.5429 33.3332 90.2757C28.164 88.0891 23.5187 84.9553 19.5284 80.965C15.5381 76.9747 12.4043 72.3295 10.2177 67.1602C7.95048 61.8096 6.80176 56.1164 6.80176 50.2518C6.80176 44.3873 7.95048 38.6941 10.2177 33.3435C12.4043 28.1742 15.5381 23.5289 19.5284 19.5386C23.5187 15.5483 28.164 12.4145 33.3332 10.2279C38.6838 7.96073 44.3771 6.81201 50.2416 6.81201C56.1061 6.81201 61.7993 7.96073 67.15 10.2279C72.3192 12.4145 76.9645 15.5483 80.9548 19.5386C84.9451 23.5289 88.0789 28.1742 90.2655 33.3435C92.5327 38.6941 93.6814 44.3873 93.6814 50.2518C93.6814 56.1164 92.5327 61.8096 90.2655 67.1602C88.0789 72.3295 84.9451 76.9747 80.9548 80.965C76.9645 84.9553 72.3192 88.0891 67.15 90.2757C61.7993 92.5429 56.1061 93.6917 50.2416 93.6917Z" fill="white"/>
//         <path d="M51.0173 49.4757H49.4756V26.9043C49.4756 26.4811 49.8182 26.1284 50.2515 26.1284C50.6747 26.1284 51.0274 26.471 51.0274 26.9043V49.4757H51.0173Z" fill="#004BC3"/>
//         <path d="M51.0173 9.59279C51.0173 9.16427 50.6722 8.81689 50.2464 8.81689C49.8207 8.81689 49.4756 9.16427 49.4756 9.59279V12.8374C49.4756 13.2659 49.8207 13.6133 50.2464 13.6133C50.6722 13.6133 51.0173 13.2659 51.0173 12.8374V9.59279Z" fill="#004BC3"/>
//         <path d="M22.0336 20.9536C21.7306 20.6506 21.2409 20.649 20.9399 20.95C20.6388 21.2511 20.6404 21.7408 20.9434 22.0438L23.2377 24.3381C23.5407 24.6411 24.0304 24.6427 24.3314 24.3416C24.6325 24.0406 24.6309 23.5509 24.3279 23.2479L22.0336 20.9536Z" fill="#004BC3"/>
//         <path d="M9.59278 49.4754C9.16427 49.4754 8.81689 49.8205 8.81689 50.2462C8.81689 50.672 9.16427 51.0171 9.59278 51.0171H12.8374C13.2659 51.0171 13.6133 50.672 13.6133 50.2462C13.6133 49.8205 13.2659 49.4754 12.8374 49.4754H9.59278Z" fill="#004BC3"/>
//         <path d="M20.9556 78.4467C20.6526 78.7497 20.651 79.2393 20.952 79.5404C21.253 79.8414 21.7427 79.8398 22.0457 79.5368L24.34 77.2425C24.643 76.9395 24.6446 76.4498 24.3436 76.1488C24.0425 75.8478 23.5529 75.8494 23.2499 76.1524L20.9556 78.4467Z" fill="#004BC3"/>
//         <path d="M49.4756 90.9004C49.4756 91.3289 49.8207 91.6763 50.2465 91.6763C50.6722 91.6763 51.0173 91.3289 51.0173 90.9004V87.6557C51.0173 87.2272 50.6722 86.8799 50.2465 86.8799C49.8207 86.8799 49.4756 87.2272 49.4756 87.6557V90.9004Z" fill="#004BC3"/>
//         <path d="M78.4447 79.5396C78.7477 79.8426 79.2374 79.8442 79.5384 79.5431C79.8395 79.2421 79.8379 78.7524 79.5349 78.4494L77.2406 76.1551C76.9375 75.8521 76.4479 75.8505 76.1468 76.1515C75.8458 76.4526 75.8474 76.9422 76.1504 77.2453L78.4447 79.5396Z" fill="#004BC3"/>
//         <path d="M90.9001 51.0178C91.3287 51.0178 91.676 50.6727 91.676 50.2469C91.676 49.8212 91.3287 49.4761 90.9001 49.4761H87.6555C87.227 49.4761 86.8796 49.8212 86.8796 50.2469C86.8796 50.6727 87.227 51.0178 87.6555 51.0178H90.9001Z" fill="#004BC3"/>
//         <path d="M79.5422 22.0358C79.8452 21.7328 79.8468 21.2431 79.5458 20.942C79.2448 20.641 78.7551 20.6426 78.4521 20.9456L76.1578 23.2399C75.8548 23.5429 75.8532 24.0326 76.1542 24.3336C76.4553 24.6347 76.9449 24.6331 77.2479 24.3301L79.5422 22.0358Z" fill="#004BC3"/>
//         <path d="M49.1532 50.2412L50.2415 51.3295L39.8526 61.7184C39.5503 62.0206 39.0565 62.0206 38.7643 61.7184C38.462 61.4161 38.462 60.9223 38.7643 60.6301L49.1532 50.2412Z" fill="#004BC3"/>
//         <path d="M50.2415 54.3424C52.5065 54.3424 54.3427 52.5063 54.3427 50.2413C54.3427 47.9763 52.5065 46.1401 50.2415 46.1401C47.9765 46.1401 46.1404 47.9763 46.1404 50.2413C46.1404 52.5063 47.9765 54.3424 50.2415 54.3424Z" fill="#004BC3"/>
//         <path d="M195.111 283.321H56.0254C53.2595 283.321 51.0173 285.563 51.0173 288.329V289.992C51.0173 292.758 53.2595 295 56.0254 295H195.111C197.877 295 200.119 292.758 200.119 289.992V288.329C200.119 285.563 197.877 283.321 195.111 283.321Z" fill="#004BC3"/>
//         <path d="M55.3099 283.292L195.827 283.352C198.195 283.352 200.119 285.972 200.119 289.186L51.0173 289.126C51.0173 285.901 52.9419 283.292 55.3099 283.292Z" fill="#004BC3"/>
//         <path d="M181.625 102.265V74H69.0101V102.265C69.0101 128.836 82.0289 153.846 104.016 168.759C109.588 172.538 114.727 175.319 118.566 177.183C119.634 177.697 119.634 179.229 118.566 179.743C114.727 181.617 109.578 184.398 104.006 188.167C82.0088 203.08 69 228.09 69 254.662V282.926H181.615V254.662C181.615 228.09 168.596 203.08 146.609 188.167C141.037 184.388 135.898 181.607 132.059 179.743C130.991 179.229 130.991 177.697 132.059 177.183C135.898 175.309 141.047 172.528 146.619 168.759C168.616 153.846 181.625 128.836 181.625 102.265Z" fill="url(#paint0_linear_2705_793)"/>
//         <path d="M176.047 118.389C134.431 129.181 111.577 119.417 111.577 119.417C92.4116 115.023 81.1662 117.694 75.0901 120.727C78.3952 133.383 88.7236 159.229 119.578 174.102C122.692 175.603 124.626 178.808 124.626 182.264V252.517C124.626 257.304 122.651 261.868 119.175 265.153L99.9186 283.291H150.533L131.559 265.415C128.072 262.14 126.107 257.566 126.107 252.779V182.183C126.107 178.707 128.082 175.513 131.216 174.001C163.955 158.121 173.477 129.886 176.057 118.399L176.047 118.389Z" fill="#658396"/>
//         <g opacity="0.28">
//         <path d="M125.563 74.3652H69.2559V102.63C69.2559 129.202 82.2748 154.211 104.262 169.125C109.834 172.903 114.973 175.684 118.812 177.549C119.87 178.063 119.87 179.594 118.812 180.108C114.973 181.982 109.824 184.763 104.252 188.532C82.2546 203.445 69.2458 228.455 69.2458 255.027V283.292H125.553V74.3652H125.563Z" fill="#E5F3FF"/>
//         </g>
//         <path d="M195.111 62.6562H56.0254C53.2595 62.6562 51.0173 64.8984 51.0173 67.6643V69.3269C51.0173 72.0928 53.2595 74.3349 56.0254 74.3349H195.111C197.877 74.3349 200.119 72.0928 200.119 69.3269V67.6643C200.119 64.8984 197.877 62.6562 195.111 62.6562Z" fill="#004BC3"/>
//         <path d="M55.3099 62.6357L195.827 62.6962C198.195 62.6962 200.119 65.3161 200.119 68.5305L51.0173 68.47C51.0173 65.2456 52.9419 62.6357 55.3099 62.6357Z" fill="#004BC3"/>
//         <defs>
//         <linearGradient id="paint0_linear_2705_793" x1="57.1903" y1="178.473" x2="282.703" y2="178.473" gradientUnits="userSpaceOnUse">
//             <stop stopColor="white"/>
//             <stop offset="0.83" stopColor="#B2C9ED"/>
//             <stop offset="1" stopColor="#99B7E7"/>
//         </linearGradient>
//         </defs>
//     </svg>
// }

export default function Hourglass({ className }: HourglassProps) {
    return <svg width="140" height="268" viewBox="0 0 180 308" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M50.2415 100.483C77.9891 100.483 100.483 77.9891 100.483 50.2415C100.483 22.4939 77.9891 0 50.2415 0C22.4939 0 0 22.4939 0 50.2415C0 77.9891 22.4939 100.483 50.2415 100.483Z" fill="#A8C0CC"/>
        <path d="M50.2416 93.6917C44.3771 93.6917 38.6838 92.5429 33.3332 90.2757C28.164 88.0891 23.5187 84.9553 19.5284 80.965C15.5381 76.9747 12.4043 72.3295 10.2177 67.1602C7.95048 61.8096 6.80176 56.1164 6.80176 50.2518C6.80176 44.3873 7.95048 38.6941 10.2177 33.3435C12.4043 28.1742 15.5381 23.5289 19.5284 19.5386C23.5187 15.5483 28.164 12.4145 33.3332 10.2279C38.6838 7.96073 44.3771 6.81201 50.2416 6.81201C56.1061 6.81201 61.7993 7.96073 67.15 10.2279C72.3192 12.4145 76.9645 15.5483 80.9548 19.5386C84.9451 23.5289 88.0789 28.1742 90.2655 33.3435C92.5327 38.6941 93.6814 44.3873 93.6814 50.2518C93.6814 56.1164 92.5327 61.8096 90.2655 67.1602C88.0789 72.3295 84.9451 76.9747 80.9548 80.965C76.9645 84.9553 72.3192 88.0891 67.15 90.2757C61.7993 92.5429 56.1061 93.6917 50.2416 93.6917Z" fill="white"/>
        <path d="M51.0173 49.4757H49.4756V26.9043C49.4756 26.4811 49.8182 26.1284 50.2515 26.1284C50.6747 26.1284 51.0274 26.471 51.0274 26.9043V49.4757H51.0173Z" fill="#004BC3"/>
        <path d="M51.0173 9.59279C51.0173 9.16427 50.6722 8.81689 50.2464 8.81689C49.8207 8.81689 49.4756 9.16427 49.4756 9.59279V12.8374C49.4756 13.2659 49.8207 13.6133 50.2464 13.6133C50.6722 13.6133 51.0173 13.2659 51.0173 12.8374V9.59279Z" fill="#004BC3"/>
        <path d="M22.0336 20.9536C21.7306 20.6506 21.2409 20.649 20.9399 20.95C20.6388 21.2511 20.6404 21.7408 20.9434 22.0438L23.2377 24.3381C23.5407 24.6411 24.0304 24.6427 24.3314 24.3416C24.6325 24.0406 24.6309 23.5509 24.3279 23.2479L22.0336 20.9536Z" fill="#004BC3"/>
        <path d="M9.59278 49.4754C9.16427 49.4754 8.81689 49.8205 8.81689 50.2462C8.81689 50.672 9.16427 51.0171 9.59278 51.0171H12.8374C13.2659 51.0171 13.6133 50.672 13.6133 50.2462C13.6133 49.8205 13.2659 49.4754 12.8374 49.4754H9.59278Z" fill="#004BC3"/>
        <path d="M20.9556 78.4467C20.6526 78.7497 20.651 79.2393 20.952 79.5404C21.253 79.8414 21.7427 79.8398 22.0457 79.5368L24.34 77.2425C24.643 76.9395 24.6446 76.4498 24.3436 76.1488C24.0425 75.8478 23.5529 75.8494 23.2499 76.1524L20.9556 78.4467Z" fill="#004BC3"/>
        <path d="M49.4756 90.9004C49.4756 91.3289 49.8207 91.6763 50.2465 91.6763C50.6722 91.6763 51.0173 91.3289 51.0173 90.9004V87.6557C51.0173 87.2272 50.6722 86.8799 50.2465 86.8799C49.8207 86.8799 49.4756 87.2272 49.4756 87.6557V90.9004Z" fill="#004BC3"/>
        <path d="M78.4447 79.5396C78.7477 79.8426 79.2374 79.8442 79.5384 79.5431C79.8395 79.2421 79.8379 78.7524 79.5349 78.4494L77.2406 76.1551C76.9375 75.8521 76.4479 75.8505 76.1468 76.1515C75.8458 76.4526 75.8474 76.9422 76.1504 77.2453L78.4447 79.5396Z" fill="#004BC3"/>
        <path d="M90.9001 51.0178C91.3287 51.0178 91.676 50.6727 91.676 50.2469C91.676 49.8212 91.3287 49.4761 90.9001 49.4761H87.6555C87.227 49.4761 86.8796 49.8212 86.8796 50.2469C86.8796 50.6727 87.227 51.0178 87.6555 51.0178H90.9001Z" fill="#004BC3"/>
        <path d="M79.5422 22.0358C79.8452 21.7328 79.8468 21.2431 79.5458 20.942C79.2448 20.641 78.7551 20.6426 78.4521 20.9456L76.1578 23.2399C75.8548 23.5429 75.8532 24.0326 76.1542 24.3336C76.4553 24.6347 76.9449 24.6331 77.2479 24.3301L79.5422 22.0358Z" fill="#004BC3"/>
        <path d="M49.1532 50.2412L50.2415 51.3295L39.8526 61.7184C39.5503 62.0206 39.0565 62.0206 38.7643 61.7184C38.462 61.4161 38.462 60.9223 38.7643 60.6301L49.1532 50.2412Z" fill="#004BC3"/>
        <path d="M50.2415 54.3424C52.5065 54.3424 54.3427 52.5063 54.3427 50.2413C54.3427 47.9763 52.5065 46.1401 50.2415 46.1401C47.9765 46.1401 46.1404 47.9763 46.1404 50.2413C46.1404 52.5063 47.9765 54.3424 50.2415 54.3424Z" fill="#004BC3"/>
        <path d="M174.094 295.686H35.008C32.2422 295.686 30 297.928 30 300.694V302.356C30 305.122 32.2422 307.364 35.008 307.364H174.094C176.86 307.364 179.102 305.122 179.102 302.356V300.694C179.102 297.928 176.86 295.686 174.094 295.686Z" fill="#004BC3"/>
        <path d="M34.2926 295.656L174.809 295.716C177.177 295.716 179.102 298.336 179.102 301.55L30 301.49C30 298.266 31.9246 295.656 34.2926 295.656Z" fill="#004BC3"/>
        <path d="M160.608 114.629V86.3643H47.9927V114.629C47.9927 141.201 61.0116 166.21 82.9985 181.124C88.5708 184.902 93.7099 187.684 97.549 189.548C98.6171 190.062 98.6171 191.593 97.549 192.107C93.7099 193.981 88.5608 196.762 82.9884 200.531C60.9914 215.444 47.9827 240.454 47.9827 267.026V295.291H160.598V267.026C160.598 240.454 147.579 215.444 125.592 200.531C120.02 196.752 114.881 193.971 111.041 192.107C109.973 191.593 109.973 190.062 111.041 189.548C114.881 187.673 120.03 184.892 125.602 181.124C147.599 166.21 160.608 141.201 160.608 114.629Z" fill="url(#paint0_linear_2770_239)"/>
        <path d="M155.029 130.753C113.413 141.545 90.5598 131.781 90.5598 131.781C71.3943 127.388 60.1489 130.058 54.0728 133.091C57.3778 145.747 67.7063 171.593 98.5605 186.466C101.674 187.968 103.609 191.172 103.609 194.628V264.882C103.609 269.668 101.634 274.233 98.1575 277.518L78.9013 295.655H129.516L110.542 277.78C107.055 274.505 105.09 269.93 105.09 265.144V194.548C105.09 191.071 107.065 187.877 110.199 186.365C142.937 170.485 152.46 142.25 155.039 130.763L155.029 130.753Z" fill="#658396"/>
        <g opacity="0.28">
        <path d="M104.546 86.7295H48.2386V114.994C48.2386 141.566 61.2574 166.576 83.2444 181.489C88.8167 185.268 93.9557 188.049 97.7949 189.913C98.8529 190.427 98.8529 191.958 97.7949 192.472C93.9557 194.347 88.8066 197.128 83.2343 200.896C61.2373 215.81 48.2285 240.819 48.2285 267.391V295.656H104.536V86.7295H104.546Z" fill="#E5F3FF"/>
        </g>
        <path d="M174.094 75.0205H35.008C32.2422 75.0205 30 77.2627 30 80.0285V81.6912C30 84.457 32.2422 86.6992 35.008 86.6992H174.094C176.86 86.6992 179.102 84.457 179.102 81.6912V80.0285C179.102 77.2627 176.86 75.0205 174.094 75.0205Z" fill="#004BC3"/>
        <path d="M34.2926 75L174.809 75.0605C177.177 75.0605 179.102 77.6804 179.102 80.8948L30 80.8343C30 77.6098 31.9246 75 34.2926 75Z" fill="#004BC3"/>
        <defs>
        <linearGradient id="paint0_linear_2770_239" x1="36.173" y1="190.837" x2="261.685" y2="190.837" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="0.83" stopColor="#B2C9ED"/>
            <stop offset="1" stopColor="#99B7E7"/>
        </linearGradient>
        </defs>
    </svg>
}