import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { contactSupport, getSession } from "../../api";
import { Loader } from "../../component";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import { PersonaContext } from "../login/context";

export default function Help() {
    const navigate = useNavigate()
    const persona = useContext(PersonaContext)
    
    const [message, setMessage] = useState("")
    const [messageSending, setMessageSending] = useState(false)
    const [messageSent, setMessageSent] = useState(false)

    useEffect(() => {
        if (!persona.clientId || !persona.accountId) {
            navigate("/auth/email")
            return
        }
    }, [])

    const handleMessageChange = (ev: ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(ev.target.value?.trim())
    }

    const handleOnSend = () => {
        setMessageSending(true)
        contactSupport(persona.clientId, persona.accountId, message).then(() => {
            setMessageSending(false)
            setMessageSent(true)
        }).catch(() => {
            navigate("/auth/email")
            return
        })
    }

    return <div className="pb-24">
        <div className="relative h-32 text-center hg-bg-blue mb-10" style={{ borderBottomLeftRadius: "40%", borderBottomRightRadius: "40%"}}>
            <ArrowLeftCircleIcon className="fixed mt-2 ml-2 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={() => navigate(-1)}/>
            <h1 className="pt-12 text-xl text-white pl-3 pr-6">How may we help you?</h1>
        </div>
        {!messageSent && !messageSending && <div className="flex flex-col">
            <div className="ml-3 mr-3">
                <textarea rows={5} placeholder="I have an enquiry about .." className="border p-2 rounded-md w-full" onChange={handleMessageChange}/>
            </div>
            <button disabled={message.length < 10 || messageSending} className="ml-3 mr-3 mt-5 text-white p-2 rounded-full hg-bg-blue disabled:bg-blue-300 cursor-pointer" onClick={handleOnSend}>Send</button>
        </div>}
        {messageSent && !messageSending && <div className="flex flex-col gap-3 text-center mr-3 ml-3 font-medium text-blue-950">
            <CheckBadgeIcon className="w-16 h-16 mr-auto ml-auto hg-text-blue" />
            <p>Thank you!</p> 
            <p>We received your feedback, and we will get back to you within 24 hours.</p>
        </div>}
        {!messageSent && messageSending && <div className="text-center mr-3 ml-3">
            <Loader classNames="w-12 h-12 text-blue-100" />
        </div>}

    </div>
}
