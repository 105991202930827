import {createContext, useContext, useEffect, useState} from 'react'
import { BankAccount, ListClientBankAccountsOutput, Promo, Voucher, getClient, getPersona, getReferredClientsCount, listClientBankAccounts, listPromos, listReferredClients, listVouchers } from '../../api'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { PersonaContext } from '../login/context'

export interface Client {
    name: string
    type: "corporate" | "individual"
    status: string
    investorCategory: "accreditedInvestor" | "highNetworthInvestor" | "sophisticatedInvestor250k" | "retailInvestor"
    tags: string[]
    referrerCode: string
    hashedRefereeCode?: string
    riskScore: number
    individual: IndividualClient
    corporate : CorporateClient
    incompleteProfile: boolean
    referredClients: ReferredClient[]
    referredClientsCount: number
    hideReferralScreen: boolean
}

export interface ReferredClient {
    clientName: string
    aumBaseCurrency: string
    aum: number
}

export interface IndividualClient {
    nationality: string
    nricNo: string
    passportNo: string
    msisdn: string
    email: string

    ethnicity: string
    domesticRinggitBorrowing: string
    taxResidency: string
    countryTax: string
    taxIdentificationNo: string
    unavailableTIN: string
    reasonBExplaination: string
    
    permanentAddressLine1: string
    permanentAddressLine2: string
    permanentAddressCity: string
    permanentAddressPostcode: string
    permanentAddressCountry: string
    permanentAddressState: string

    correspondenceAddressLine1: string
    correspondenceAddressLine2: string
    correspondenceAddressCity: string
    correspondenceAddressPostcode: string
    correspondenceAddressCountry: string
    correspondenceAddressState: string
}

export interface CorporateClient {
    companyRegistrationNo: string
    oldCompanyRegistrationNo: string
    dateOfIncorporation: string
    countryOfIncorporation: string
    
    authorisedPersonName: string
    authorisedPersonEmail: string
    authorisedPersonMsisdn: string

    registeredAddressLine1: string
    registeredAddressLine2: string
    registeredAddressCity: string
    registeredAddressPostcode: string
    registeredAddressCountry: string
    registeredAddressState: string

    correspondenceAddressLine1: string
    correspondenceAddressLine2: string
    correspondenceAddressCity: string
    correspondenceAddressPostcode: string
    correspondenceAddressCountry: string
    correspondenceAddressState: string
}

const initialState = {
    client: {} as Client,
    bankAccounts: [],
    promos: [],
    vouchers: [],
    loading: true,
    incompleteProfile: false,
    reloadToggle: false,
    reload: () => {}
}

export interface IProfileContext {
    client: Client
    bankAccounts: BankAccount[]
    promos: Promo[]
    vouchers: Voucher[]
    loading: boolean
    
    reloadToggle: boolean
    reload: () => void
}

export const ProfileContext = createContext<IProfileContext>(initialState)

const ProfileProvider = (props: any) => {
    const navigate = useNavigate()
    const [state, setState] = useState<IProfileContext>(initialState)
    
    const persona = useContext(PersonaContext)

    useEffect(() => {
        if (!persona.clientId || !persona.accountId) {
            navigate("/auth/email")
            return
        }
        getClient(persona.clientId, persona.accountId).then(resp => {
            const client = resp.data.client as Client
            if (!client) return
            setState(prev => ({
                ...prev,
                client,
                loading: false,
                reload: reload,
            }))
            getReferredClientsCount({
                clientId: persona.clientId,
                accountId: persona.accountId,
                referrerCode: client.referrerCode,
            }).then(resp => {
                setState(prev => ({
                    ...prev,
                    client: {
                        ...prev.client,
                        referredClientsCount: resp.data.count
                    }
                }))
            }).catch(() => {})
            if (client.tags.filter(t => t === "mr").length > 0) {
                listReferredClients({
                    clientId: persona.clientId,
                    accountId: persona.accountId,
                    referrerCode: client.referrerCode,
                }).then(resp => {
                    setState(prev => ({
                        ...prev,
                        client: {
                            ...prev.client,
                            referredClients: resp.data.clients
                        }
                    }))
                }).catch(() => {})
            }
        }).catch((err: AxiosError) => {
            if (err.response?.status === 401) navigate("/auth/email")
        })
        listClientBankAccounts(persona.clientId, persona.accountId).then((resp) => {
            const {bankAccounts} = resp.data as ListClientBankAccountsOutput
            if (!bankAccounts) return
            setState(prev => ({
                ...prev,
                bankAccounts,
                reload: reload,
            }))
        }).catch((err: AxiosError) => {
            if (err.response?.status === 401) navigate("/auth/email")
        })
        listPromos({clientId: persona.clientId, accountId: persona.accountId}).then(resp => {
            setState(prev => ({
                ...prev,
                promos: resp.data.promos,
            }))
        }).catch((err: AxiosError) => {
            if (err.response?.status === 401) navigate("/auth/email")
        })
        listVouchers({ clientId: persona.clientId, accountId: persona.accountId}).then(resp => {
            setState(prev => ({
                ...prev,
                vouchers: resp.data.vouchers,
            }))
        }).catch((err: AxiosError) => {
            if (err.response?.status === 401) navigate("/auth/email")
        })
    }, [ state.reloadToggle ])

    const reload = () => {
        setState(prev => ({...prev, reloadToggle: !prev.reloadToggle}))
    }

    return <ProfileContext.Provider value={state}>
        {props.children}
    </ProfileContext.Provider>
}

export default {
    ProfileContext,
    ProfileProvider,
}
