import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, MouseEvent, ChangeEvent, useContext, KeyboardEvent} from "react";
import { GetBalanceOutput, getBalance, getSession, redeem } from "../../api";
import { Loader } from "../../component";
import { FundListContext } from "../fund/context";
import { PortfolioContext } from "../portfolio/context";
import { PersonaContext } from "../login/context";
import moment from "moment";
import { ProfileContext } from "../settings/context";

interface RedeemState {
    step: number

    fundId?: string
    fundClassSequence?: string
    toBankAccountNumber?: string

    amount?: number
}

export default function Redeem() {
    const [state, setState] = useState<RedeemState>({
        step: 1,
    })
    const persona = useContext(PersonaContext)
    const portfolio = useContext(PortfolioContext)
    
    const navigate = useNavigate()

    useEffect(() => {
        if (!persona.clientId || !persona.accountId) {
            navigate("/auth/email")
            return
        }
        if (!persona.canRedeem) {
            navigate("/portfolio")
            return
        }
        if (portfolio.accountValue && portfolio.accountValue.portfolioValue <= 0) {
            navigate("/portfolio")
            return
        }
    },[])
    
    const handleSelectFund = (ev: MouseEvent<HTMLLIElement>) => {
        const {fundid, fundclasssequence} = ev.currentTarget.dataset
        setState(prev=> ({
            ...prev,
            fundId: fundid,
            fundClassSequence: fundclasssequence,
            step: prev.step + 1,
        }))
    }
    
    const handleSelectBankAccount = (ev: MouseEvent<HTMLLIElement>) => {
        const { accountnumber } = ev.currentTarget.dataset
        if (!accountnumber) return
        setState(prev=> ({
            ...prev,
            toBankAccountNumber: accountnumber,
            step: prev.step + 1,
        }))
    }
    
    const handlePreviewClick = () => {
        if (!state.amount) {
            return
        }
        setState(prev=> ({
            ...prev,
            step: prev.step + 1,
        }))
    }
    const handleAmountChange = (amount: number) => {
        setState(prev => ({
            ...prev,
            amount: amount
        }))
    }
    
    const handleBack = () => {
        if (state.step > 1) {
            if (state.step === 4 && state.fundId === "1d3ede3d8023fb76a6b73ab2972bdc28b73cc549") {
                setState(prev => ({ ...prev, step: 1, amount: 0 }))
                return
            }
            if (state.step === 4) {
                setState(prev => ({ ...prev, step: prev.step - 1, amount: 0 }))
                return
            }
            setState(prev => ({ ...prev, step: prev.step - 1 }))
            return
        }
        navigate("/portfolio")
    }

    switch (state.step) {
        case 1:
            return <Step1 handleBack={handleBack} handleSelectFund={handleSelectFund} />
        case 2:
            if (!state.fundId || !state.fundClassSequence) {
                setState(prev => ({...prev, step: prev.step - 1}))
                return <></>
            }
            return <Step2 handleBack={handleBack} handleSelectBankAccount={handleSelectBankAccount} />
        case 3:
            if (!state.fundId || !state.fundClassSequence) {
                setState(prev => ({...prev, step: prev.step - 1}))
                return <></>
            }
            return <Step3 handleBack={handleBack} handlePreviewClick={handlePreviewClick} handleAmountChange={handleAmountChange} fundId={state.fundId} fundClassSequence={state.fundClassSequence}/>
        case 4:
            if (!state.fundId || !state.fundClassSequence || !state.amount || !state.toBankAccountNumber) {
                setState(prev => ({...prev, step: prev.step - 1}))
                return <></>
            }
            if (!persona.clientId || !persona.accountId) {
                navigate("/invest")
                return <></>
            }
            return <Step4 handleBack={handleBack} clientId={persona.clientId} accountId={persona.accountId} fundId={state.fundId} fundClassSequence={state.fundClassSequence} amount={state.amount} toBankAccountNumber={state.toBankAccountNumber} />
        default:
            navigate("/portfolio")
            return <></>
    }
}

interface Step1Props {
    handleBack: (ev: MouseEvent) => void
    handleSelectFund: (ev: MouseEvent<HTMLLIElement>) => void
}

const Step1 = (props: Step1Props) => {
    const portfolio = useContext(PortfolioContext)

    const {handleBack, handleSelectFund} = props
    return <div className="flex flex-col gap-8 m-5">
        <div className="relative">
            <ArrowLeftCircleIcon className="fixed w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={handleBack}/>
            <h1 className="pt-10 text-xl hg-text-blue pl-3 pr-6">What would you like to redeem?</h1>
        </div>
        <ul className="text-white flex flex-wrap justify-around gap-5 text-center">
            {portfolio.breakdown?.map(b => {
                return <li key={b.fundId + b.fundClassLabel} className="border text-white text-sm cursor-pointer w-72 hg-bg-light-blue" data-fundid={b.fundId} data-fundclasssequence={b.fundClassSequence} onClick={handleSelectFund}>
                <span className="block text-sm pt-8 pb-8 pr-5 pl-5 hg-text-blue font-medium">
                    {b.fundName} - Class {b.fundClassLabel}
                </span>
            </li>
            })}
        </ul>
    </div>
}

interface Step2Props {
    handleBack: (ev: MouseEvent) => void
    handleSelectBankAccount: (ev: MouseEvent<HTMLLIElement>) => void
}

const Step2 = (props: Step2Props) => {
    const navigate = useNavigate()
    const profile = useContext(ProfileContext)

    const { handleBack, handleSelectBankAccount } = props
    return <div className="flex flex-col justify-between h-full p-5">
        <div className="flex flex-col gap-8">
            <div className="relative">
                <ArrowLeftCircleIcon className="fixed w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={handleBack}/>
                <h1 className="pt-10 text-xl hg-text-blue pl-3 pr-6">What bank account would you like to redeem to?</h1>
            </div>
            {profile.bankAccounts?.length === 0 && <div className="flex flex-col gap-5 p-5 bg-amber-100 border border-amber-700 text-amber-700 rounded-3xl text-center text-sm">
                <span>No bank accounts were added.</span>
                <span>Please Create a bank account to proceed with redemption.</span>
            </div>}
            {profile.bankAccounts?.length > 0 && <ul className="text-white flex flex-wrap justify-around gap-5 text-center">
                {profile.bankAccounts?.map(b => {
                    return <li key={b.accountNumber} className="border text-white text-sm cursor-pointer w-72 hg-bg-light-blue" data-accountnumber={b.accountNumber} onClick={handleSelectBankAccount}>
                    <span className="block text-sm pt-8 pb-8 pr-5 pl-5 hg-text-blue font-medium">
                        {b.bankName} - {b.accountName} - {b.accountNumber}
                    </span>
                </li>
                })}
            </ul>}
        </div>
        <button type="submit" onClick={() => navigate("/settings/bank-account/list")} className="p-3 hg-bg-blue text-white rounded-full">
            Manage bank accounts
        </button>
    </div>
}

interface Step3Props {
    fundId: string
    fundClassSequence: string
    handleBack: (ev: MouseEvent) => void
    handleAmountChange: (amount: number) => void
    handlePreviewClick: () => void
}

const Step3 = (props: Step3Props) => {
    const [amount, setAmount] = useState(0)
    const [units, setUnits] = useState(0)
    const [value, setValue] = useState(0)
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()
    const persona = useContext(PersonaContext)
    const fundList = useContext(FundListContext)
    const targetFund = fundList.funds.filter(f => f.id === props.fundId)[0]
    const targetClass = targetFund.classes.filter(c => c.label === targetFund.classes[parseInt(props.fundClassSequence) - 1].label)[0]

    let minimumRedemptionAmount = targetClass.limits.minimumRedemptionAmount
    const maximumRedemptionAmount = value
    
    useEffect(() => {
        getBalance(persona.clientId, persona.accountId).then((resp) => {
            setUnits(resp.data.balance?.[props.fundId]?.[props.fundClassSequence].units || 0)
            setValue(resp.data.balance?.[props.fundId]?.[props.fundClassSequence].value || 0)
            setLoading(false)
        }).catch(err => {
            navigate("/portfolio")
            return
        })
    }, [])

    const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
        if (ev.key !== "Enter") {
            return
        }
        props.handlePreviewClick()
    }

    const handleAmountChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const amount = parseFloat(ev.currentTarget.value || "0")
        setAmount(amount)
        props.handleAmountChange(amount)
    }
    
    const handleMax = () => {
        setAmount(maximumRedemptionAmount)
        props.handleAmountChange(maximumRedemptionAmount)
    }

    if (loading) {
        return <div className="flex justify-center items-center h-full">
            <Loader classNames="w-16 h-16 text-blue-100"/>
        </div>
    }

    return <div className="flex flex-col justify-between p-5 h-full">
        <ArrowLeftCircleIcon className="fixed w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={props.handleBack}/>
        <div className="flex flex-col gap-5 pt-10 pl-3">
            <h1 className="text-xl hg-text-blue">You currently have {units.toLocaleString("en-my", { minimumFractionDigits: 4, maximumFractionDigits: 4 })} units of  <span className="font-medium">{targetFund?.name} - Class {targetFund?.classes[parseInt(props.fundClassSequence) - 1]?.label}</span>, 
                valued at <span className="font-medium">{value.toLocaleString("en-my", { currency: "myr", style: "currency" ,minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>.
            </h1>
            <div className="flex flex-col gap-2">
                <span className="block text-blue-900 text-lg font-medium">Redemption amount</span>
                <div className="relative">
                    <span className="absolute bottom-3 left-4 text-gray-700">RM</span>
                    <input type="number" inputMode="numeric" onKeyDown={handleKeyDown} onChange={handleAmountChange} value={amount <= 0 ? "" : amount} className="w-full border p-3 pl-12 rounded-full" placeholder="10,000.00"/>
                    <span className="absolute bottom-3 right-4 hg-text-blue cursor-pointer" onClick={handleMax}>Max</span>
                </div>
                {amount < minimumRedemptionAmount && <span className="ml-4 text-xs text-red-700 font-medium">Minimum redemption is {minimumRedemptionAmount?.toLocaleString("en-my", { currency: "myr", style: "currency", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                {amount > maximumRedemptionAmount && <div className="flex ml-4 gap-1">
                    <span className="text-xs text-red-700 font-medium">
                        Estimated maximum redemption is {maximumRedemptionAmount?.toLocaleString("en-my", { currency: "myr", style: "currency", minimumFractionDigits: 2, maximumFractionDigits: 2 })}.
                    </span>
                </div>}
            </div>
        </div>
        <button onClick={props.handlePreviewClick} disabled={!amount || amount < minimumRedemptionAmount || amount > maximumRedemptionAmount} className="block p-3 hg-bg-blue text-white border rounded-full disabled:bg-blue-300">
            Preview
        </button>
    </div>
}

interface Step4Props {
    clientId: string
    accountId: string
    fundId: string
    fundClassSequence: string
    toBankAccountNumber: string
    
    amount: number
    
    handleBack: (ev: MouseEvent) => void
}

interface Step4State {
    redeemLoading: boolean
    redeemFailed: boolean
}

const Step4 = (props: Step4Props) => {
    const [ state, setState ] = useState<Step4State>({
        redeemLoading: false,
        redeemFailed: false,
    })
    const portfolio = useContext(PortfolioContext)
    const profile = useContext(ProfileContext)
    const fundList = useContext(FundListContext)
    const targetFund = fundList.funds.filter(f => f.id === props.fundId)[0]
    const toBankAccount = profile.bankAccounts.filter(b => b.accountNumber === props.toBankAccountNumber)[0]

    const navigate = useNavigate()

    const handleRedeem = (ev: MouseEvent) => {
        setState(prev => ({...prev, redeemLoading: true}))
        redeem({
            source: "wallet",
            type: "redeem",
            clientId: props.clientId,
            accountId: props.accountId,
            fundId: props.fundId,
            fundClassSequence: parseInt(props.fundClassSequence),
            toBankAccountNumber: props.toBankAccountNumber,
            requestedAmount: props.amount,
        }).then(resp => {
            portfolio.reloadAccountRequests()
            navigate(`/portfolio/request/${resp.data?.request?.id}`)
        }).catch(() => {
            setState(prev => ({
                ...prev,
                redeemLoading: false,
                redeemFailed: true
            }))
        })
    }

    const renderRedeemButton = () => {
        if (state.redeemLoading) {
            return <button disabled={state.redeemLoading} className="hg-bg-blue mt-12 p-3 text-white border shadow-2xl rounded-full disabled:bg-blue-300">
                <Loader classNames="w-7 h-7 text-blue-300" />
            </button>
        }
        return <button onClick={handleRedeem} className="hg-bg-blue mt-12 p-3 text-white border shadow-2xl rounded-full outline-0">
            Redeem
        </button>
    }

    return <div className="flex flex-col justify-between p-5 h-full">
        <ArrowLeftCircleIcon className="fixed w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={props.handleBack}/>
        <div className="flex flex-col pt-10 gap-6">
            <h1 className="text-xl hg-text-blue">Preview of redemption</h1>
            <div className="flex">
                <span className="text-blue-900 font-medium w-36">Fund</span>
                <span className="w-full">{targetFund.name}</span>
            </div>
            <div className="flex">
                <span className="text-blue-900 font-medium w-36">Class</span>
                <span className="w-full">{targetFund.classes[parseInt(props.fundClassSequence) - 1]?.label}</span>
            </div>
            <div className="flex">
                <span className="text-blue-900 font-medium w-36">Amount</span>
                <span className="w-full">{props.amount?.toLocaleString("en-my", {currency: "myr", style:"currency", maximumFractionDigits: 2, minimumFractionDigits: 2})}</span>
            </div>
            <div className="flex">
                <span className="text-blue-900 font-medium w-36">Bank account</span>
                <div className="flex flex-col w-full">
                    <span>{toBankAccount.bankName}</span>
                    <span>{toBankAccount.accountName} - {toBankAccount.accountNumber}</span>
                </div>
            </div>
            {state.redeemFailed && <div className="mt-5 w-5/6 p-3 border border-red-700 bg-red-100 text-sm rounded-full text-center">
                An error ocurred, please try again.
            </div>}
        </div>
        {renderRedeemButton()}
    </div>
}
