import Context from './context'
import FundContext from '../module/fund/context'
import Notification from '../component/notification';
import PortfolioContext from '../module/portfolio/context';
import PersonaContext from '../module/login/context';
import ProfileContext from '../module/settings/context';

interface LayoutProps {
    children: JSX.Element
}

export default function Layout ({children}: LayoutProps) {
    return  <div className="h-full md:max-w-3xl mr-auto ml-auto">
        <div className="flex flex-1 flex-col h-full">
            <PersonaContext.PersonaProvider>
                <PersonaContext.PersonaListProvider>
                    <Context.NotificationProvider>
                        <PortfolioContext.PortfolioProvider>
                            <FundContext.FundListProvider>
                                <ProfileContext.ProfileProvider>
                                    <main className="h-full">
                                        <Notification />
                                        {children}
                                    </main>
                                </ProfileContext.ProfileProvider>
                            </FundContext.FundListProvider>
                        </PortfolioContext.PortfolioProvider>
                    </Context.NotificationProvider>
                </PersonaContext.PersonaListProvider>
            </PersonaContext.PersonaProvider>
        </div>
    </div>
}