import Layout from '../../layout';
import { Navigate } from "react-router-dom";
import DuitNow from './duitnow';

export default [
  {
    path: "/duitnow",
    element: <Layout><DuitNow /></Layout>,
    errorElement: <Navigate to="/" />
  }
]
