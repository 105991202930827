import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { MouseEvent, useContext, useEffect, useState } from "react";
import { Loader } from "../../component";
import { ProfileContext } from "../settings/context";
import { CalculateVoucherValueOutput, CreateVoucherInput, calculateVoucherValue, createVoucher } from "../../api";
import { PersonaContext } from "../login/context";
import { debounce } from "lodash";

interface CreateVoucherState {
    loading: boolean
    loadingVoucherCalculation: boolean
    triggerVoucherCalculation: boolean
    form: CreateVoucherInput
    voucherCalculation: CalculateVoucherValueOutput
}

export default function CreateVoucher() {
    const profile = useContext(ProfileContext)
    const persona = useContext(PersonaContext)
    const [state, setState] = useState<CreateVoucherState>({
        loading: false,
        loadingVoucherCalculation: true,
        triggerVoucherCalculation: false,
        form: {
            clientId: persona.clientId,
            accountId: persona.accountId,
            code: "",
            discountPercentage: 10,
        },
        voucherCalculation: {
            subscriptionAmount: 100000,
            originalFeePercentage: 2,
            chargeableFeePercentage: 0,
            discountPercentage: 10,
            feeAmount: 0,
        }
    })
    const navigate = useNavigate()

    useEffect(() => {
        if (!persona.canInvest) {
            navigate("/rewards")
        }
    }, [])
    
    useEffect(() => {
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                triggerVoucherCalculation: !prev.triggerVoucherCalculation
            }))
        }, 200)
    }, [state.form.discountPercentage])
    
    useEffect(() => {
        setState(prev => ({
            ...prev,
            loadingVoucherCalculation: true
        }))
        calculateVoucherValue({
            clientId: persona.clientId,
            accountId: persona.accountId,
            subscriptionAmount: state.voucherCalculation.subscriptionAmount,
            originalFeePercentage: state.voucherCalculation.originalFeePercentage,
            discountPercentage: state.form.discountPercentage,
        }).then(resp => {
            const output = resp.data as CalculateVoucherValueOutput
            setState(prev => ({
                ...prev,
                loadingVoucherCalculation: false,
                voucherCalculation: output
            }))
        }).catch(() => {
            setState(prev => ({...prev, loadingVoucherCalculation: false}))
        })
    }, [state.triggerVoucherCalculation])

    const submitDisabled = state.loading || state.form.clientId === "" || state.form.accountId === "" || state.form.code === "" || state.form.discountPercentage < 1 || state.form.discountPercentage > 100

    const handleInputChange = (field: string, value: string) => {
        if (field === "code") {
            value = value.replaceAll(" ", "")
        }
        setState(prev => ({
            ...prev,
            form: {
                ...prev.form,
                [field]: value
            }
        }))
    }
    
    const handleDiscountPercentageChange = (ev: MouseEvent<HTMLButtonElement>) => {
        const {direction} = ev.currentTarget.dataset
        if (!direction) return
        let step = 10
        if (direction === "down") {
            step *= -1
        }
        setState(prev => {
            const value = prev.form.discountPercentage + step
            if (value > 100 || value < 10) {
                return prev
            }
            return {
                ...prev,
                form: {
                    ...prev.form,
                    discountPercentage: prev.form.discountPercentage + step,
                }
            }
        })
    }

    const handleSubmit = async () => {
        try {
            setState(prev => ({...prev, loading: true}))
            const resp = await createVoucher(state.form)
            if (resp.status >= 400) {
                setState(prev => ({...prev, loading: false}))
                return
            }
            profile.reload()
            navigate("/rewards")
        } catch (err) {
            setState(prev => ({...prev, loading: false}))
        }
    }


    return <div className="flex flex-col h-full">
        <div>
            <div className="relative h-32 text-center hg-bg-blue" style={{ borderBottomLeftRadius: "40%", borderBottomRightRadius: "40%"}}>
                <ArrowLeftCircleIcon className="fixed mt-2 ml-2 w-8 h-8 text-white hg-bg-blue rounded-zxl cursor-pointer" onClick={() => navigate("/rewards")}/>
                <h1 className="pt-12 text-xl text-white pl-3 pr-6">
                    New voucher
                </h1>
            </div>
        </div>
        <div className="flex flex-col justify-between h-full p-5">
            <div className="flex flex-col gap-5">
                <div className="flex items-center gap-2">
                    <label className="w-2/6 font-medium">Code</label>
                    <input type="text" className="border p-2 rounded-full w-4/6 uppercase" placeholder="WELCOME10" value={state.form.code} onChange={ev => handleInputChange("code", ev.currentTarget.value)}/>
                </div>

                <div className="flex items-center ap-2">
                    <label className="w-2/6 font-medium">Discount %</label>
                    <div className="w-4/6 flex justify-between">
                        <button className="w-24 h-12 text-2xl bg-gray-100 text-gray-600 rounded-full" onClick={handleDiscountPercentageChange} data-direction="up">+</button>
                        <input type="number" className="border-0 text-center text-xl w-full disabled:text-black" disabled={true} min={1} max={100} placeholder="10" value={state.form.discountPercentage} />
                        <button className="w-24 h-12 text-2xl bg-gray-100 text-gray-600 rounded-full" onClick={handleDiscountPercentageChange} data-direction="down">-</button>
                    </div>
                </div>
                
                {state.loadingVoucherCalculation && <div className="text-center mt-10">
                    <Loader classNames="w-10 h-10 text-blue-50" />
                </div>}
                {!state.loadingVoucherCalculation && <div className="flex flex-col gap-3 border border-blue-700 p-2 rounded-xl">
                    <h3 className="text-sm text-blue-700 border-blue-700 border-b-2 border-dotted">Preview</h3>
                    <div className="flex justify-between gap-3 text-sm">
                        <label>Subscription amount</label>
                        <label>{state.voucherCalculation.subscriptionAmount?.toLocaleString("en-my", { style: "currency", currency: "myr", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</label>
                    </div>
                    <div className="flex justify-between gap-3 text-sm">
                        <label>Original fee %</label>
                        <label>{state.voucherCalculation.originalFeePercentage?.toLocaleString("en-my", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %</label>
                    </div>
                    <div className="flex justify-between gap-3 text-sm">
                        <label>Discount %</label>
                        <label>{state.voucherCalculation.discountPercentage?.toLocaleString("en-my", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %</label>
                    </div>
                    <div className="flex justify-between gap-3 text-sm">
                        <label>Chargeable fee %</label>
                        <label>{state.voucherCalculation.chargeableFeePercentage?.toLocaleString("en-my", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %</label>
                    </div>
                    <div className="flex justify-between gap-3 text-sm">
                        <label>Fee amount</label>
                        <label>{state.voucherCalculation.feeAmount?.toLocaleString("en-my", { style: "currency", currency: "myr", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</label>
                    </div>
                </div>}
            </div>

            <div className="flex justify-center">
                <button type="submit" disabled={submitDisabled} onClick={handleSubmit} className="w-full p-3 hg-bg-blue text-white rounded-full disabled:bg-blue-300">
                    {!state.loading && "Create"}
                    {state.loading && <Loader classNames="text-blue-300 h-5 w-5"/>}
                </button>
            </div>
        </div>
    </div>
}
