import Layout from '../../layout';
import View from './view';
import { Navigate } from "react-router-dom";

export default [
    {
      path: "/fund/:fundCode",
      element: <Layout>
            <View />
        </Layout>,
      errorElement: <Navigate to="/" />
    }
]
