import { ArrowLeftCircleIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, MouseEvent, ChangeEvent, useContext} from "react";
import { getSession, CreateSuitabilityAssessmentInput, createSuitabilityAssessment } from "../../api";
import { Fund, FundListContext } from "../fund/context";
import { ProfileContext } from "../settings/context";
import { Loader } from "../../component";
import { PersonaContext } from "../login/context";

interface SuitabilityAssessmentState {
    step: number
    autoCreateSuitabilityAssessment: boolean
    answers: number[]

    createForm: CreateSuitabilityAssessmentInput
    creating: boolean
    creatingFailed: boolean
}

const initialState = {
    step: 1,
    autoCreateSuitabilityAssessment: false,
    answers: [0, 0, 0, 0, 0],

    creating: false,
    creatingFailed: false,
    createForm: {
        clientId: "",
        accountId: "",
        suitabilityAssessment: {
            investmentExperience: "",
            investmentObjective: "",
            investmentHorizon: "",
            currentInvestment: "",
            returnExpectations: "",
            source: "wallet",
        }
    },
}

export default function SuitabilityAssessment() {
    const [state, setState] = useState<SuitabilityAssessmentState>(initialState)
    const { fundId, fundClassSequence } = useParams()

    const persona = useContext(PersonaContext)
    const profile = useContext(ProfileContext)
    const fundList = useContext(FundListContext)
    
    const navigate = useNavigate()
    
    useEffect(() => {
        if (!persona.clientId || !persona.accountId) {
            navigate("/auth/email")
            return
        }
        if (!persona.canInvest) {
            navigate("/portfolio")
            return
        }
    },[])

    useEffect(() => {
        if (!state.autoCreateSuitabilityAssessment) return
        handleConfirm()
    }, [ state.autoCreateSuitabilityAssessment ])

    const getRiskTolerance = () => {
        const totalScore = state.answers.reduce((p, c) => p + c)
        if (totalScore >= 5 && totalScore <= 10) return "conservative"
        else if (totalScore >= 11 && totalScore <= 15) return "moderate"
        else if (totalScore >= 16 && totalScore <= 20) return "aggressive"
        return ""
    }

    const handleQuestion = (ev: MouseEvent<HTMLElement>) => {
        const questionIndex = parseInt(ev.currentTarget.dataset.questionindex || "0")
        const questionName = ev.currentTarget.dataset.questionname || ""
        const answerScore = parseInt(ev.currentTarget.dataset.answerscore || "0")
        const answerValue = ev.currentTarget.dataset.answervalue || ""
        
        const answers = Object.assign([] as number[], state.answers)
        answers[questionIndex] = answerScore
        
        setState(prev => ({
            ...prev,
            answers,
            createForm: {
                ...prev.createForm,
                clientId: persona.clientId || "",
                accountId: persona.accountId || "",
                suitabilityAssessment: {
                    ...prev.createForm?.suitabilityAssessment,
                    [questionName]: answerValue
                },
            },
        }))

        if (questionIndex === answers.length - 1) {
            const totalScore = answers.reduce((p, c) => p + c)
            const selectedFund = fundList.funds.filter(f => f.id === fundId)[0]
            if (totalScore >= selectedFund.riskScore) {
                // auto submit
                setState(prev => ({
                    ...prev,
                    autoCreateSuitabilityAssessment: true
                }))
                return
            }
            // proceed to score review
            setState(prev => ({
                ...prev,
                step: prev.step + 1,
            }))
            return
        }
        setState(prev => ({
            ...prev,
            step: prev.step + 1,
        }))
    }

    const handleBack = () => {
        if (state.step > 1) {
            setState(prev => ({ ...prev, step: prev.step - 1 }))
            return
        }
        navigate("/invest")
    }
    
    const handleConfirm = () => {
        setState(prev => ({
            ...prev,
            creating: true,
        }))
        createSuitabilityAssessment(state.createForm).then(() => {
            profile.reload()
            const totalScore = state.answers.reduce((p, c) => p + c)
            const selectedFund = fundList.funds.filter(f => f.id === fundId)[0]
            if (totalScore >= selectedFund.riskScore) {
                navigate(`/invest/fund/${fundId}/class/${fundClassSequence}`)
                return
            }
            navigate(`/portfolio`)
        }).catch(() => {
            setState(prev => ({
                ...prev,
                creating: false,
                creatingFailed: true,
                autoCreateSuitabilityAssessment: false,
            }))
        })
    }

    const handleRedo = (ev: MouseEvent) => {
        setState(initialState)
    }

    const questions: JSX.Element[] = [
        <Question1 handleBack={handleBack} handleQuestion={handleQuestion} />,
        <Question2 handleBack={handleBack} handleQuestion={handleQuestion} />,
        <Question3 handleBack={handleBack} handleQuestion={handleQuestion} />,
        <Question4 handleBack={handleBack} handleQuestion={handleQuestion} />,
        <Question5 handleBack={handleBack} handleQuestion={handleQuestion} creating={state.creating} />,
        <PreviewScore handleBack={handleBack} handleConfirm={handleConfirm} handleRedo={handleRedo} creating={state.creating} creatingFailed={state.creatingFailed} investorRiskTolerance={getRiskTolerance()} targetFund={fundList.funds.filter(f => f.id === fundId)[0]}/>
    ]
    if (!questions[state.step - 1]) {
        navigate("/invest")
        return <></>
    }
    return questions[state.step - 1]
}

interface QuestionProps {
    handleBack: (ev: MouseEvent) => void
    handleQuestion: (ev: MouseEvent<HTMLElement>) => void
    // used for Q5 to propagate creation status
    creating?: boolean
}

const Question1 = (props: QuestionProps) => {
    return <div className="pb-24">
        <div className="relative">
            <ArrowLeftCircleIcon className="fixed mt-4 ml-4 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={props.handleBack}/>
            <h1 className="ml-8 pt-20 text-xl hg-text-blue pl-4 pr-4">What's your investment experience?</h1>
        </div>
        <ul className="mt-8 text-white flex flex-col ml-12 gap-5 text-center">
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={0} data-questionname="investmentExperience" data-answerscore={4} data-answervalue="extensive">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    Extensive knowledge - I have complete understanding of investment products and strategies
                </span>
            </li>
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={0} data-questionname="investmentExperience" data-answerscore={3} data-answervalue="considerable">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    Considerable knowledge - I understand different investment philosophies
                </span>
            </li>
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={0} data-questionname="investmentExperience" data-answerscore={2} data-answervalue="basic">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    Basic knowledge - I understand the differences between equities, bonds and fixed income
                </span>
            </li>
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={0} data-questionname="investmentExperience" data-answerscore={1}  data-answervalue="limited">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    Very limited - I have little knowledge
                </span>
            </li>
        </ul>
    </div>
}

const Question2 = (props: QuestionProps) => {
    return <div className="pb-24">
        <div className="relative">
            <ArrowLeftCircleIcon className="fixed mt-4 ml-4 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={props.handleBack}/>
            <h1 className="ml-8 pt-20 text-xl hg-text-blue pl-4 pr-4">What's your investment objective?</h1>
        </div>
        <ul className="mt-8 text-white flex flex-col ml-12 gap-5 text-center">
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={1} data-questionname="investmentObjective" data-answerscore={4} data-answervalue="aggressiveGrowth">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    Aggressive growth
                </span>
            </li>
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={1} data-questionname="investmentObjective" data-answerscore={3} data-answervalue="growthCapitalGain">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    Growth / Capital gain
                </span>
            </li>
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={1} data-questionname="investmentObjective" data-answerscore={2} data-answervalue="income">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    Income
                </span>
            </li>
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={1} data-questionname="investmentObjective" data-answerscore={1} data-answervalue="capitalPreservation">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    Capital preservation
                </span>
            </li>
        </ul>
    </div>
}

const Question3 = (props: QuestionProps) => {
    return <div className="pb-24">
        <div className="relative">
            <ArrowLeftCircleIcon className="fixed mt-4 ml-4 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={props.handleBack}/>
            <h1 className="ml-8 pt-20 text-xl hg-text-blue pl-4 pr-4">What's your investment horizon?</h1>
        </div>
        <ul className="mt-8 text-white flex flex-col ml-12 gap-5 text-center">
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={2} data-questionname="investmentHorizon" data-answerscore={4} data-answervalue="moreThan5Years">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    More than 5 years
                </span>
            </li>
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={2} data-questionname="investmentHorizon" data-answerscore={3} data-answervalue="3To5Years">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    3 to 5 years
                </span>
            </li>
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={2} data-questionname="investmentHorizon" data-answerscore={2} data-answervalue="1To3Years">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    1 to 3 years
                </span>
            </li>
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={2} data-questionname="investmentHorizon" data-answerscore={1} data-answervalue="lessThan1Year">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    Less than 1 year
                </span>
            </li>
        </ul>
    </div>
}

const Question4 = (props: QuestionProps) => {
    return <div className="pb-24">
        <div className="relative">
            <ArrowLeftCircleIcon className="fixed mt-4 ml-4 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={props.handleBack}/>
            <h1 className="ml-8 pt-20 text-xl hg-text-blue pl-4 pr-4">Majority of your current investments are?</h1>
        </div>
        <ul className="mt-8 text-white flex flex-col ml-12 gap-5 text-center">
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={3} data-questionname="currentInvestment" data-answerscore={4} data-answervalue="equitiesDerivatives">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    Crypto assets / Equities / Derivatives
                </span>
            </li>
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={3} data-questionname="currentInvestment" data-answerscore={3} data-answervalue="properties">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    Properties
                </span>
            </li>
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={3} data-questionname="currentInvestment" data-answerscore={2} data-answervalue="fixedIncomeSecurities">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    Fixed income securities
                </span>
            </li>
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={3} data-questionname="currentInvestment" data-answerscore={1} data-answervalue="cashFixedDeposits">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    Cash / Fixed deposits
                </span>
            </li>
        </ul>
    </div>
}

const Question5 = (props: QuestionProps) => {
    if (props.creating) {
        return <div className="flex justify-center items-center h-full">
            <Loader classNames="w-16 h-16 text-blue-100"/>
        </div>
    }

    return <div className="pb-24">
        <div className="relative">
            <ArrowLeftCircleIcon className="fixed mt-4 ml-4 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={props.handleBack}/>
            <h1 className="ml-8 pt-20 text-xl hg-text-blue pl-4 pr-4">What are your return expectations?</h1>
        </div>
        <ul className="mt-8 text-white flex flex-col ml-12 gap-5 text-center">
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={4} data-questionname="returnExpectations" data-answerscore={4} data-answervalue="3018-15">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    30% at best, 18% on average, -15% at worst
                </span>
            </li>
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={4} data-questionname="returnExpectations" data-answerscore={3} data-answervalue="1812-10">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    18% at best, 12% on average, -10% at worst
                </span>
            </li>
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={4} data-questionname="returnExpectations" data-answerscore={2} data-answervalue="1208-05">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    12% at best, 8% on average, -5% at worst
                </span>
            </li>
            <li className="border text-white text-sm cursor-pointer w-72 md:w-96 hg-bg-light-blue" onClick={props.handleQuestion} data-questionindex={4} data-questionname="returnExpectations" data-answerscore={1} data-answervalue="08040">
                <span className="block text-sm p-5 hg-text-blue font-medium">
                    8% at best, 4% on average, 0% at worst
                </span>
            </li>
        </ul>
    </div>
}

interface PreviewScoreProps {
    targetFund: Fund
    investorRiskTolerance: string
    creating: boolean
    creatingFailed: boolean
    handleBack: (ev: MouseEvent) => void
    handleRedo: (ev: MouseEvent) => void
    handleConfirm: (ev: MouseEvent) => void
}

const PreviewScore = (props: PreviewScoreProps) => {
    const fundList = useContext(FundListContext)
    // if (props.creating) {
    //     return <div className="flex justify-center items-center h-full">
    //         <Loader classNames="w-16 h-16 text-blue-100"/>
    //     </div>
    // }
    return <div className="pb-24 flex flex-col xs:gap-12 md:gap-20">
        <div className="relative">
            <ArrowLeftCircleIcon className="fixed mt-4 ml-4 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={props.handleBack}/>
            <p className="ml-8 pt-20 md:text-2xl hg-text-blue pl-4 pr-4 w-5/6">
                Based on your answers, your risk tolerance is rated as <span className="font-bold capitalize">{props.investorRiskTolerance} investor</span>. Therefore, you should only consider investing in funds with a risk rating that falls within your risk profile.
            </p>
        </div>
        <ul className="w-5/6 text-white flex flex-col ml-12 gap-5 sm:items-center text-center">
            <li className="flex flex-col gap-2 border p-10 text-white text-sm w-72 md:w-96 hg-bg-light-blue">
                <span className="block sm:text-sm md:text-xl hg-text-blue font-medium">{props.targetFund.name}</span>
                <span className="block sm:text-xs md:text-lg text-blue-400 font-medium capitalize">Risk rating: {props.targetFund.riskRating}</span>
            </li>
        </ul>
        <div className="flex flex-col gap-5 ml-12 mr-12">
            {!props.creating ? <>
                <button onClick={props.handleRedo} className="p-3 sm:text-md md:text-xl border-blue-700 text-white hg-bg-blue border shadow-xl rounded-full disabled:bg-blue-300">
                    Redo assessment
                </button>
                <button onClick={props.handleConfirm} disabled={props.creating} className="p-3 sm:text-md md:text-xl border-blue-700 bg-blue-50 hg-text-blue border shadow-xl rounded-full">
                    Confirm assessment
                </button>
            </> : <Loader classNames="w-12 h-12 text-blue-100 mr-auto ml-auto" />}
            {!props.creating && props.creatingFailed && <div className="mt-5 p-3 border border-red-700 bg-red-100 text-sm text-center">
                An error ocurred, please try again.
            </div>}
        </div>
    </div>
}

