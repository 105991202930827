import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, MouseEvent, useContext} from "react";
import { PersonaContext } from "../login/context";
import Tabs from "../../layout/tabs";
import { ShareIcon, TicketIcon, TrophyIcon } from "@heroicons/react/24/outline";
import { ProfileContext } from "../settings/context";
import { Voucher, listReferredClients, updateVoucherStatus } from "../../api";
import { AxiosError } from "axios";
import { Loader } from "../../component";

interface RewardsState {
    selectedView: "refer" | "promos" | "vouchers"
    ready: boolean
    isMR: boolean
}

export default function List() {
    const [state, setState] = useState<RewardsState>({
        selectedView: "refer",
        ready: false,
        isMR: false,
    })
    const persona = useContext(PersonaContext)
    const profile = useContext(ProfileContext)
    
    const navigate = useNavigate()

    useEffect(() => {
        if (!persona.clientId || !persona.accountId) {
            navigate("/auth/email")
            return
        }
        {/* Avoid showing Refer section for `bm-*` referrer codes, default to `promos` */}
        if (profile.client?.hideReferralScreen) {
            setState(prev => ({
                ...prev,
                selectedView: "promos"
            }))
        }
        if (!profile.loading) {
            const isMR = profile.client?.tags?.filter(v => v === "mr").length > 0
            setState(prev => ({
                ...prev,
                ready: true,
                isMR,
            }))
        }
    },[profile.loading])

    const handleViewChange = (ev: MouseEvent<HTMLLIElement>) => {
        const { view } = ev.currentTarget.dataset
        if (!view || (view !== "refer" && view !== "promos" && view !== "vouchers") ) return
        setState(prev=> ({
            ...prev,
            selectedView: view,
        }))
    }

    const ReferredClients = () => {
        return <div className="flex flex-col gap-3 mx-5">
            <h1 className="text-md font-medium mt-5 text-blue-950">Referred clients</h1>
            {profile.client.referredClients?.length === 0 && <div className="text-center text-sm text-gray-600">
                Invite investors using your referral link
            </div>}
            {profile.client.referredClients?.sort((a, b) => a.clientName > b.clientName ? 1 : -1).map(c => <div key={c.clientName} className="flex justify-between text-sm px-1 border-b border-dotted">
                <label className="uppercase">{c.clientName}</label>
                <label>{c.aum?.toLocaleString("en-my", { style: "currency", currency: c.aumBaseCurrency, minimumFractionDigits: 2, maximumFractionDigits: 2})}</label>
            </div>)}
        </div>
    }

    const ReferView = () => {
        const [copied, setCopied] = useState(false)
        const handleCopyLink = () => {
            setCopied(true)
            window.navigator.clipboard?.writeText("https://wallet.halogen.my/" + profile.client.referrerCode?.toUpperCase());
            setTimeout(() => setCopied(false), 500)
        }
        return <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-3 text-center hg-text-blue">
                <h1 className="text-xl">Your referral code</h1>
                <h1 className="text-4xl font-semibold uppercase">{profile.client.referrerCode}</h1>
                <h1 className="text-md">Referred count: {profile.client.referredClientsCount}</h1>
            </div>
            <button className={`mx-auto w-1/3 p-3 text-white rounded-full ${!copied ? "hg-bg-blue" : "bg-blue-400"}`} onClick={handleCopyLink}>
                {!copied && "Copy link"}
                {copied && "Copied!"}
            </button>
            {state.isMR && <ReferredClients />}
        </div>
    }

    const PromosView = () => {
        return <div className="flex flex-col gap-5 mx-5">
            {profile.promos?.map(p => <div key={p.label} className="flex flex-col gap-1 mt-3">
                <label className="w-fit bg-blue-400 p-2 text-white text-xs uppercase">{p.label}</label>
                <p className="text-gray-700 text-sm">{p.description}</p>
            </div>)}
            {profile.promos?.length === 0 && <div className="mt-10 text-center text-sm text-gray-600">
                You haven't unlocked any promo codes yet. Stay tuned!
            </div>}
        </div>
    }
    
    interface VoucherViewState {
        vouchers: Voucher[]
        editStatus: Map<string, boolean>
        loadingStatus: Map<string, boolean>
    }
    const VouchersView = () => {
        const [state, setState] = useState<VoucherViewState>({
            vouchers: profile.vouchers,
            editStatus: new Map<string, boolean>(),
            loadingStatus: new Map<string, boolean>().set("WELCOME10", true),
        })
        const handleCreateVoucher = () => {
            navigate("/rewards/voucher/create")
        }
        const Row = ({ voucher }: {voucher: Voucher}) => {
            const [editing, setEditing] = useState(false)
            const [loading, setLoading] = useState(false)
            const handleToggleEditStatus = (ev: MouseEvent<HTMLDivElement>) => {
                setEditing(true)
            }
            const handleUpdateStatus = (ev: MouseEvent<HTMLButtonElement>) => {
                const {status} = ev.currentTarget.dataset
                if (!status || (status !== "active" && status !== "frozen")) return
                setEditing(false)
                setLoading(true)
                updateVoucherStatus({
                    clientId: persona.clientId,
                    accountId: persona.accountId,
                    code: voucher.code,
                    status,
                }).then(() => {
                    profile.reload()
                    setLoading(false)
                }).catch((err: AxiosError) => {
                    if (err.response?.status === 401) navigate("/auth/email")
                    setLoading(false)
                })
            }
            return <div className="flex flex-col gap-2 mt-3 pb-2 border-b border-dashed border-gray-700">
                <div key={voucher.code} className="flex justify-between cursor-pointer" data-code={voucher.code} onClick={handleToggleEditStatus}>
                    <div className="flex flex-col gap-4">
                        <label className="w-fit bg-blue-400 p-2 text-white text-xs uppercase">{voucher.code}</label>
                        <p className="text-gray-700 text-sm">{voucher.description}</p>
                    </div>
                    {!loading && <div className="flex flex-col gap-3 text-center">
                        {voucher.status === "active" && <React.Fragment>
                                <label className="text-green-500 capitalize text-sm">{voucher.status}</label>
                                <button className="text-blue-500 border border-blue-500 p-2 rounded-full text-xs hover:bg-blue-700 hover:text-white hover:border-blue-700" data-code={voucher.code} data-status="frozen" onClick={handleUpdateStatus}>Freeze</button>
                            </React.Fragment>
                        }
                        {voucher.status === "frozen" && <React.Fragment> 
                                <label className="text-blue-500 capitalize text-sm">{voucher.status}</label>
                                <button className="text-green-500 border border-green-500 p-2 rounded-full text-xs hover:bg-green-500 hover:text-white hover:border-green-500" data-code={voucher.code} data-status="active" onClick={handleUpdateStatus}>Activate</button>
                            </React.Fragment>
                        }
                    </div>}
                    {loading  && <div className="">
                        <Loader classNames="w-5 h-5 text-blue-300" />
                    </div>}
                </div>
            </div>
        }
        return <div className="flex flex-col gap-2 mx-5">
            {persona.canInvest && <div className="bg-yellow-100 text-yellow-700 border border-yellow-700 text-sm p-3 rounded-xl cursor-pointer" onClick={handleCreateVoucher}>
                Click here to create voucher to offer your client a discount on their subscription fee.
            </div>}
            {/* {persona.canInvest && <button className="self-end p-3 text-sm hg-bg-blue text-white rounded-full" onClick={handleCreateVoucher}>Create voucher</button>} */}
            {profile.vouchers.map(v => <Row key={v.code} voucher={v} />)}
            {profile.vouchers?.length === 0 && <div className="mt-10 text-center text-sm text-gray-600">
                You haven't created any voucher codes yet.
            </div>}
        </div>
    }

    if (!state.ready) return <></>
    return <div className="pb-32">
        <div className="h-32 pt-12 text-center hg-bg-blue" style={{ borderBottomLeftRadius: "40%", borderBottomRightRadius: "40%"}}>
            <h1 className="text-xl text-white pl-3 pr-6">Rewards</h1>
        </div>
        <div className="flex flex-col">
            <ul className="flex justify-center m-5 gap-5">
                {/* Avoid showing Refer section for `bm-*` referrer codes */}
                {!profile.client?.hideReferralScreen && <li className={`w-32 flex flex-col justify-center items-center p-3 border rounded-xl cursor-pointer gap-2 ${state.selectedView === "refer" && "hg-bg-blue text-white border-white"}`} data-view="refer" onClick={handleViewChange}>
                    <ShareIcon className="w-7 h-7" />
                    <label className="text-xs cursor-pointer">Refer</label>
                </li>}
                <li className={`w-32 flex flex-col justify-center items-center p-3 border rounded-xl cursor-pointer gap-2 ${state.selectedView === "promos" && "hg-bg-blue text-white border-white"}`} data-view="promos" onClick={handleViewChange}>
                    <TrophyIcon className="w-7 h-7" />
                    <label className="text-xs cursor-pointer">My Promos</label>
                </li>
                {state.isMR && !profile.client.referrerCode?.includes("-") && <li className={`w-32 flex flex-col justify-center items-center p-3 border rounded-xl cursor-pointer gap-2 ${state.selectedView === "vouchers" && "hg-bg-blue text-white border-white"}`} data-view="vouchers" onClick={handleViewChange}>
                    <TicketIcon className="w-7 h-7" />
                    <label className="text-xs cursor-pointer">Vouchers</label>
                </li>}
            </ul>

            {state.selectedView === "refer" && <ReferView />}
            {state.selectedView === "promos" && <PromosView />}
            {state.selectedView === "vouchers" && <VouchersView />}
        </div>

        <Tabs />
    </div>
}
