import { Navigate } from "react-router-dom";
import ParticipantVerifyEmail from './participant_verify_email';
import ParticipantSignRequest from "./participant_sign_request";

export default [
  {
    path: "/policy/participant/verify/:token",
    element: <ParticipantVerifyEmail />,
    errorElement: <Navigate to="/" />
  },
  {
    path: "/policy/participant/sign/:token",
    element: <ParticipantSignRequest />,
    errorElement: <Navigate to="/" />
  }
]
