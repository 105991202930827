import Layout from '../../layout';
import { Navigate } from "react-router-dom";
import Rewards from './list';
import CreateVoucher from './create_voucher';

export default [
  {
    path: "/rewards",
    element: <Layout><Rewards /></Layout>,
    errorElement: <Navigate to="/" />
  },
  {
    path: "/rewards/voucher/create",
    element: <Layout><CreateVoucher /></Layout>,
    errorElement: <Navigate to="/" />
  }
]
