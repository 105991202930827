
import { ArrowLeftIcon, ArrowTrendingUpIcon, ArrowUpIcon, ArrowUturnDownIcon, ArrowsRightLeftIcon, Cog6ToothIcon, GiftIcon, ScaleIcon } from "@heroicons/react/24/outline"
import { useContext, useEffect, useState } from "react"
import { Doughnut } from "react-chartjs-2"
import { IPortfolioContext, PortfolioContext } from "../portfolio/context"
import { ProfileContext } from "../settings/context"
import { FundListContext } from "../fund/context"
import { PersonaContext } from "../login/context"
import { AccountRequestsPlaceholder } from "../../component/placceholder"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import HalogenLogo from "../../asset/halogen-logo"

export default function Portfolio() {
    const [assetIndex, setAssetIndex] = useState(-1)
    
    
    const navigate = useNavigate()
    const portfolio = useContext(PortfolioContext)
    const profile = useContext(ProfileContext)
    const fundList = useContext(FundListContext)
    const persona = useContext(PersonaContext)
    const [state, setState] = useState<IPortfolioContext>(portfolio)

    useEffect(() => {
        if (!persona.authenticated) {
            navigate("/auth/email")
            return
        }
        setState(portfolio)
    }, [ portfolio.accountRequestsLoading, portfolio.accountValueLoading, portfolio.breakdownLoading, portfolio.switchinValueLoading  ])
    
    let lastRequestDate = ""
    return <div className="h-full">
        <div className="flex flex-col gap-4 h-full bg-black overflow-auto">
            {/* Header */}
            <div className="flex items-center justify-between text-white mt-5 mx-3">
                <ArrowLeftIcon className="w-5 h-5" onClick={() => navigate("/v2/")}/>
                <div className="flex flex-col items-center gap-1">
                    <h3 className="">Balanced</h3>
                </div>
                <div className="flex gap-5 my-auto">
                    <Cog6ToothIcon className="text-gray-300 w-6 h-6 stroke-2" onClick={() => navigate("/v2/portfolio/settings")} />
                </div>
            </div>

            {/* Chart */}
            <div>
                <div className="relative">
                    <div className="flex flex-col justify-center items-center text-white text-sm absolute top-1/2 bottom-1/2 right-1/2 left-1/3 w-1/3">
                        <span className="text-gray-400 font-medium">Value</span>
                        <span className="font-bold">RM 1,827,123.23</span>
                        <h3 className="text-xs font-bold text-green-400">+RM113,012,34</h3>
                    </div>
                    <Doughnut
                        className="p-5"
                        options={{ 
                            maintainAspectRatio: true, 
                            responsive: true,
                            cutout: 100,
                            plugins: {
                                legend: {
                                    display: false,
                                }
                            }
                        }}
                        data={{
                            labels: ["HSBTCF", "HSETHF", "HSRIF"],
                            datasets: [
                                {
                                    
                                    data: [52, 32, 16],
                                    backgroundColor: [ "#FFA500", "#37367b", "#38B261" ],
                                    borderWidth: 0,
                                    // offset: 16,
                                    hoverOffset: 16,
                                    // borderRadius: 64,
                                    // borderDashOffset: 10,
                                },
                                {
                                    data: [],
                                    weight: 0.3,
                                },
                                {
                                    data: [55, 35, 10],
                                    backgroundColor: [ "#FFA500AA", "#37367bAA", "#38B261AA" ],
                                    borderWidth: 0,
                                    // offset: 16,
                                    // borderRadius: 64,
                                    // borderDashOffset: 10,
                                },
                            ],
                            }}
                    />
                </div>

                {/* Bubbles */}
                <div className="flex gap-2 mx-1 overflow-auto no-scrollbar">
                    <div onClick={() => assetIndex === 0 ? setAssetIndex(-1) : setAssetIndex(0)} className={`inline-flex items-center gap-2 py-3 px-3 text-white ${assetIndex === 0 ? "bg-gray-800 rounded-tr-3xl rounded-tl-3xl" : "bg-gray-900 rounded-full mb-2"}`}>
                        <span className="bg-amber-400 rounded-full w-2 h-2 inline-block"></span>
                        <span className="text-xs font-sans">HSBTCF</span>
                        <span className="text-sm font-medium">RM100.4k</span>
                    </div>
                    <div onClick={() => assetIndex === 1 ? setAssetIndex(-1) : setAssetIndex(1)} className={`inline-flex items-center gap-2 py-3 px-3 text-white ${assetIndex === 1 ? "bg-gray-800 rounded-tr-3xl rounded-tl-3xl" : "bg-gray-900 rounded-full mb-2"}`}>
                        <span className="bg-blue-400 rounded-full w-2 h-2 inline-block"></span>
                        <span className="text-xs font-sans">HSETHF</span>
                        <span className="text-sm font-medium">RM60.4k</span>
                    </div>
                    <div onClick={() => assetIndex === 2 ? setAssetIndex(-1) : setAssetIndex(2)} className={`inline-flex items-center gap-2 py-3 px-3 text-white ${assetIndex === 2 ? "bg-gray-800 rounded-tr-3xl rounded-tl-3xl" : "bg-gray-900 rounded-full mb-2"}`}>
                        <span className="bg-green-500 rounded-full w-2 h-2 inline-block"></span>
                        <span className="text-xs font-sans">HSRIF</span>
                        <span className="text-sm font-medium">RM60.4k</span>
                    </div>
                </div>
                {assetIndex >= 0 && <div className="bg-gray-800 flex flex-col text-xs gap-3 py-2 px-2 mx-1 border-b border-b-gray-800 rounded-bl-3xl rounded-br-3xl">
                    <div className="flex justify-between border-b border-b-gray-800 p-1 text-white">
                        <span className="font-medium  text-white pr-10">Name</span>
                        <span className="">Halogen Shariah Bitcoin Fund</span>
                    </div>
                    <div className="flex justify-between border-b border-b-gray-800 p-1 text-white">
                        <span className="font-medium  text-white pr-10">Class</span>
                        <span className="">MYR</span>
                    </div>
                    <div className="flex justify-between border-b border-b-gray-800 p-1 text-white">
                        <span className="font-medium  text-white pr-10">Total inflow</span>
                        <span className="">{123457?.toLocaleString("en-my", { currency: "myr", style:"currency", minimumFractionDigits: 2, maximumFractionDigits:2 })}</span>
                    </div>
                    <div className="flex justify-between border-b border-b-gray-800 p-1 text-white">
                        <span className="font-medium  text-white pr-10">Total outflow</span>
                        <span className="">{123?.toLocaleString("en-my", { currency: "myr", style:"currency", minimumFractionDigits: 2, maximumFractionDigits:2 })}</span>
                    </div>
                    <div className="flex justify-between border-b border-b-gray-800 p-1 text-white">
                        <span className="font-medium  text-white pr-10">Nett inflow</span>
                        <span className="">{123?.toLocaleString("en-my", { currency: "myr", style:"currency", minimumFractionDigits: 2, maximumFractionDigits:2 })}</span>
                    </div>
                    <div className="flex justify-between border-b border-b-gray-800 p-1 text-white">
                        <span className="font-medium  text-white pr-10">Value</span>
                        <span className="">{123?.toLocaleString("en-my", { currency: "myr", style:"currency", minimumFractionDigits: 2, maximumFractionDigits:2 })}</span>
                    </div>
                    <div className="flex justify-between border-b border-b-gray-800 p-1 text-white">
                        <span className="font-medium  text-white pr-10">Valuation date</span>
                        <span className="">01 May 2024</span>
                    </div>
                    {false && <div className="flex justify-between border-b border-b-gray-800 p-1 text-white">
                        <span className="font-medium  text-white pr-10">Distributed amount</span>
                        <span className="">{123?.toLocaleString("en-my", { currency: "myr", style:"currency", minimumFractionDigits: 2, maximumFractionDigits:2 })}</span>
                    </div>}
                    <div className="flex justify-between border-b border-b-gray-800 p-1 text-white">
                        <span className="font-medium  text-white pr-10">Units</span>
                        <span className="">{123?.toLocaleString("en-my", { minimumFractionDigits: 4, maximumFractionDigits: 4 })}</span>
                    </div>
                    <div className="flex justify-between border-b border-b-gray-800 p-1 text-white">
                        <span className="font-medium  text-white pr-10">Allocation %</span>
                        <span className="">{123?.toLocaleString("en-my", { minimumFractionDigits: 2, maximumFractionDigits:2 })}%</span>
                    </div>
                    <div className="flex justify-between p-1 text-white">
                        <span className="font-medium  text-white pr-10">Profit and loss</span>
                        <span className="">{1234?.toLocaleString("en-my", { currency: "myr", style:"currency", minimumFractionDigits: 2, maximumFractionDigits:2 })} ({21?.toLocaleString("en-my", { minimumFractionDigits: 2, maximumFractionDigits:2 })}%)</span>
                    </div>
                </div>}

            </div>
            
            <ul className={`text-white grid grid-cols-3 text-center`}>
                {persona.canInvest && <li className="w-16 text-white p-1 text-sm cursor-pointer mr-auto ml-auto" onClick={() => {}}>
                    <ArrowUpIcon className="block bg-blue-500 rounded-3xl p-2 w-8 h-8 mr-auto ml-auto stroke-2"/>
                    <span className="text-xs text-white font-medium">Invest</span>
                </li>}
                {persona.canSwitch && <li className="w-16 text-white p-1 text-sm cursor-pointer mr-auto ml-auto" onClick={() => {}}>
                    <ArrowsRightLeftIcon className={`block rounded-3xl p-2 w-8 h-8 mr-auto ml-auto stroke-2 ${!state.accountValue || state.accountValue?.portfolioValue <= 0 || fundList.privateMandates ? "bg-blue-300": "bg-blue-500"}`}/>
                    <span className="text-xs text-white font-medium">Switch</span>
                </li>}
                {/* {persona.canInvest && <li className="w-16 text-white p-1 text-sm cursor-pointer mr-auto ml-auto" onClick={() => {}}>
                    <ScaleIcon className={`block rounded-3xl p-2 w-8 h-8 mr-auto ml-auto stroke-2 ${!state.accountValue || state.accountValue?.portfolioValue <= 0 || fundList.privateMandates ? "bg-blue-300": "bg-blue-500"}`}/>
                    <span className="text-xs text-white font-medium">Rebalance</span>
                </li>} */}
                {persona.canRedeem && <li className="w-16 text-white p-1 text-sm cursor-pointer mr-auto ml-auto" onClick={() => {}}>
                    <ArrowUturnDownIcon className={`block rounded-3xl p-2 w-8 h-8 mr-auto ml-auto stroke-2 ${!state.accountValue || state.accountValue?.portfolioValue <= 0 ? "bg-blue-300": "bg-blue-500"}`}/>
                    <span className="text-xs text-white font-medium">Redeem</span>
                </li>}
            </ul>

            {/* Requests */}
            <div className="p-3 bg-gray-900 rounded-tl-3xl rounded-tr-3xl text-white">
                {state.accountRequestsLoading && <div className="pb-5">
                    <AccountRequestsPlaceholder />
                </div>}
                {!state.accountRequestsLoading && <div className="pb-5">
                    {state.accountRequests && state.accountRequests.length === 0 && <div className="text-center text-white border border-dashed p-6">
                        <p className="text-sm font-medium">No requests made yet</p>
                    </div>}
                    {state.accountRequests && state.accountRequests.length > 0 && state.accountRequests.map((r) => {
                        const date = moment(r.createdAt).format("Do MMMM")
                        const item =  <div key={r.id}>
                            {date !== lastRequestDate && <span className="mb-1 block text-white p-2 font-medium capitalize">{date}</span>}
                            <div className="flex justify-between p-2 pb-3 cursor-pointer" onClick={() => /*navigate(`/portfolio/request/${r.id}`)*/ {}}>
                                {!r.switchId && r.type === "purchase"  && !r.rebateId && <div><ArrowUpIcon className="p-1 stroke-2 w-5 h-5 bg-blue-500 text-white rounded-full"/></div>}
                                {!r.switchId && r.type === "purchase" && r.rebateId && <div><GiftIcon className="w-5 h-5 text-white bg-blue-500 rounded-3xl p-1 mt-0.5"/></div>}
                                {!r.switchId && r.type === "redeem" && !r.switchId && <div><ArrowUturnDownIcon className="text-white bg-blue-500 rounded-3xl p-1 w-5 h-5" style={{ strokeWidth: 3}}/></div>}
                                {r.switchId && <div><ArrowsRightLeftIcon className="w-5 h-5 text-white bg-blue-500 rounded-xl stroke-2 p-0.5 font-bold"/></div>}
                                <div className="w-2/6 ml-2 break-words flex flex-col">
                                    <span className="text-sm capitalize">{r.fundShortName}</span>
                                    <span className="text-xs text-white capitalize">{r.type}</span>
                                </div>
                                
                                {r.type === "purchase" && <div className="w-3/6 flex flex-col gap-1">
                                    <span className="text-sm">{r.amount?.toLocaleString("en-my", { style: "currency", currency: r.fundClassBaseCurrency, maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                    <span className="text-white text-xs">{r.units ? r.units.toLocaleString("en-my", { minimumFractionDigits: 4, maximumFractionDigits: 4 }) + " units" : "-"}</span>
                                </div>}
                                {r.type === "redeem" && r.amount > 0 && r.units && <div className="w-3/6 flex flex-col gap-1">
                                    <span className="text-sm">{r.amount ? r.amount?.toLocaleString("en-my", { style: "currency", currency: r.fundClassBaseCurrency, maximumFractionDigits: 2, minimumFractionDigits: 2 }) : "-"}</span>
                                    <span className="text-white text-xs">{r.units?.toLocaleString("en-my", { maximumFractionDigits: 4, minimumFractionDigits: 4 })} units</span>
                                </div>}
                                {r.type === "redeem" && !r.amount && r.requestedAmount > 0 && <div className="w-3/6 flex flex-col gap-1">
                                    <span className="text-sm">{r.requestedAmount?.toLocaleString("en-my", { style: "currency", currency: r.fundClassBaseCurrency, maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                    <span className="text-white text-xs">-</span>
                                </div>}
                                {r.type === "redeem" && !r.amount && !r.requestedAmount && r.units && <div className="w-3/6 flex flex-col gap-1">
                                    <span className="text-sm">-</span>
                                    <span className="text-white text-xs">{r.units?.toLocaleString("en-my", { maximumFractionDigits: 4, minimumFractionDigits: 4 })} units</span>
                                </div>}
                                <div>
                                    {(r.status === "pending" || r.status === "processed" || r.status === "deposited") && <span className="text-xs ml-2 text-amber-400 p-1 rounded-md capitalize font-medium">{r.status}</span>}
                                    {r.status === "completed" && <span className="text-xs ml-2 text-green-400 p-1 rounded-md font-medium">Completed</span>}
                                    {r.status === "cancelled" && <span className="text-xs ml-2 text-red-400 p-1 rounded-md font-medium">Cancelled</span>}
                                </div>
                            </div>
                        </div>
                        lastRequestDate = date
                        return item
                    })}
                </div>}
            </div>


        </div>
    </div>
}
