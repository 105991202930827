interface EthereumProps {
    color: "white" | "blue"
    className?: string
}


export default function Ethereum({ color, className }: EthereumProps) {
    if (color == "blue") {
        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2V14L19 11L12 2Z" fill="#154BC5"/>
            <path d="M12 2V14L5 11L12 2Z" fill="blue"/>
            <path d="M5 13L12 16V22L5 13Z" fill="blue"/>
            <path d="M19 13L12 16V22L19 13Z" fill="#154BC5"/>
        </svg>
    }
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2V14L19 11L12 2Z" fill="white"/>
        <path d="M12 2V14L5 11L12 2Z" fill="white"/>
        <path d="M5 13L12 16V22L5 13Z" fill="white"/>
        <path d="M19 13L12 16V22L19 13Z" fill="white"/>
    </svg>
    
}