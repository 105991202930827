import {createContext, useContext, useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import { getFund, listFundHoldings, listFunds } from '../../api'
import { PersonaContext } from '../login/context';

export interface Fund {
    id: string
    name: string
    code: string
    investmentObjective: string
    riskRating: string
    riskScore: number
    shariahCompliant: boolean
    tagline: string

    documents: {
        label: string
        url: string
    }[]

    bankAccounts: {
        collection: BankAccount
    }

    classes: FundClass[]
}

export interface FundClass {
    sequence: number
    label: string
    netAssetValue: number
    netAssetValuePerUnit: number
    historicalNetAssetValuePerUnit: number[]
    
    launchPrice: number
    lastUpdatedAt: string
    canDistribute: boolean

    performance: {
        oneMonth: number
        sixMonth: number
        yearToDate: number
        oneYear: number
        threeYears: number
        max: number
    }

    limits: {
        minimumInitialInvestment: number
        minimumAdditionalInvestment: number
        minimumUnitsHeld: number
        minimumRedemptionAmount: number
    }
}

export interface IFundListContext {
    funds: Fund[]
    amount: number
    quoteAsset: string
    holdings: FundHolding[]
    privateMandates: boolean
    loading: boolean
}

export interface FundHolding {
    asset: string
    units: number
    amount: number
    percentage: number
}

interface BankAccount {
    accountBank: string
    accountCurrency: string
    accountName: string
    accountNumber: string
    accountType: string
    bic: string
    platform: string
    role: string
}

const initialState: IFundListContext = {
    funds: [],
    amount: 0,
    quoteAsset: "myr",
    holdings: [],
    privateMandates: true,
    loading: true,
}

export const FundListContext = createContext<IFundListContext>(initialState)

const FundListProvider = (props: any) => {
    const navigate = useNavigate()
    const persona = useContext(PersonaContext)
    const [state, setState] = useState<IFundListContext>(initialState)

    useEffect(() => {
        if (!persona.clientId || !persona.accountId) {
            navigate("/auth/email")
            return
        }
        listFunds(persona.clientId, persona.accountId).then(resp => {
            const privateMandates = resp.data?.funds?.filter((f: Fund) => f.code?.startsWith("pm-")).length > 0
            if (privateMandates) {
                listFundHoldings(persona.clientId, persona.accountId, resp.data.funds[0].id).then((holdingsResp) => {
                    const holdings = holdingsResp.data?.holdings || []
                    const amount = holdingsResp.data?.amount || 0
                    const quoteAsset = holdingsResp.data?.quoteAsset || "myr"
                    setState(prev => ({
                        ...prev,
                        funds: resp.data.funds,
                        amount,
                        quoteAsset,
                        holdings,
                        privateMandates,
                        loading: false,
                    }))
                })
                return
            }
            setState(prev => ({
                ...prev,
                funds: resp.data.funds,
                privateMandates,
                loading: false,
            }))
        }).catch(err => {
            if (err.response?.status === 401) navigate("/auth/email")
        })
    }, [])
    

    return <FundListContext.Provider value={ state }>
        {props.children}
    </FundListContext.Provider>
}

export default {
    FundListContext,
    FundListProvider,
}
