interface EmainSentIllustrationProps {
    className?: string
}

export default function EmainSentIllustration({ className }: EmainSentIllustrationProps) {
    return <svg width="150" height="160" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M23.0042 1H1.38498C0.620076 1 0 1.62008 0 2.38498V16.3286C0 17.0935 0.620076 17.7136 1.38498 17.7136H23.0042C23.7691 17.7136 24.3891 17.0935 24.3891 16.3286V2.38498C24.3891 1.62008 23.7691 1 23.0042 1Z" fill="#B2CEE2"/>
        <path d="M23.8261 17.4171C24.179 17.1468 24.3891 16.7265 24.3854 16.2798V2.43002C24.3891 1.64182 23.7473 1 22.9591 1H9.10558L23.7999 17.4058L23.8299 17.4171H23.8261Z" fill="#CEE1F4"/>
        <path d="M15.2836 1H1.43002C0.64182 1 0 1.64182 0 2.43002V16.2798C0 16.7114 0.195173 17.1206 0.52922 17.3908L0.589273 17.4058L15.2836 1Z" fill="#CEE1F4"/>
        <path d="M22.9591 1H1.43002C0.64182 1 0 1.64182 0 2.43002V2.98176L10.3292 10.1018C11.4514 10.875 12.934 10.875 14.0562 10.1018L24.3854 2.98176V2.43002C24.3891 1.64182 23.7473 1 22.9591 1Z" fill="#E6F3FF"/>
        <path d="M6.35064 28.9997C6.11043 28.9997 5.9115 28.8045 5.9115 28.5605C5.9115 28.4329 5.9678 28.3091 6.06539 28.2265L12.112 23.2008C12.2922 23.0431 12.5699 23.0619 12.7275 23.2421C12.8852 23.4222 12.8664 23.7 12.6863 23.8576C12.6825 23.8576 12.6788 23.8651 12.675 23.8689L6.62839 28.8946C6.54957 28.9621 6.44823 28.9959 6.34689 28.9959L6.35064 28.9997Z" fill="#6090DC"/>
        <path d="M11.4814 28.0461C11.2412 28.0461 11.0423 27.8509 11.0423 27.6107C11.0423 27.4831 11.0948 27.363 11.1924 27.2804L12.6825 25.9217C12.8627 25.7603 13.1404 25.7791 13.3018 25.9592C13.4632 26.1394 13.4444 26.4171 13.2643 26.5785L11.7742 27.9372C11.6954 28.0086 11.5903 28.0461 11.4852 28.0461H11.4814Z" fill="#6090DC"/>
        <path d="M13.1029 19.391L15.8616 26.0006L20.9023 18.4376L27.9923 11.6816L13.1029 19.3872V19.391Z" fill="#004BC3"/>
        <path d="M27.9923 11.6367L13.4444 20.123L9.16563 19.012L27.9923 11.6367Z" fill="#6693DB"/>
        <path d="M22.869 17.248L15.8616 26.0046L16.6873 20.9901L22.869 17.248Z" fill="#99B7E7"/>
        <path d="M27.9923 11.6853C27.9923 11.6853 27.9998 11.6628 27.9923 11.6741L16.6873 20.9898L23.691 22.8965L27.9923 11.6853Z" fill="#6693DB"/>
        <path d="M24.2465 6.49287C26.0394 6.49287 27.4929 5.03939 27.4929 3.24644C27.4929 1.45348 26.0394 0 24.2465 0C22.4535 0 21 1.45348 21 3.24644C21 5.03939 22.4535 6.49287 24.2465 6.49287Z" fill="#004BC3"/>
        <path d="M22.2429 3.46635L23.3665 4.58495L26.2451 1.90527" stroke="white" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}