import { ArrowLeftCircleIcon, ClipboardIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Loader } from "../../component";
import { PortfolioContext } from "./context";
import { Fund, FundListContext } from "../fund/context";
import { PersonaContext } from "../login/context";

interface ClipboardState {
    accountBank: boolean
    accountName: boolean
    accountNumber: boolean
}

export default function BankTransfer() {
    const [loading, setLoading] = useState(true)
    const [fund, setFund] = useState({} as Fund)
    
    const navigate = useNavigate()
    const persona = useContext(PersonaContext)
    const portfolio = useContext(PortfolioContext)
    const fundList = useContext(FundListContext)

    const {requestId} = useParams()

    useEffect(() => {
        if (!persona.clientId || !persona.accountId) {
            navigate("/auth/email")
            return
        }
        const request = portfolio.accountRequests?.filter(r => r.id === requestId)[0]
        if (!request) {
            portfolio.reloadAccountRequests()
            return
        }
        
        const fund = fundList.funds?.filter(f => f.id === request.fundId)[0]
        if (!fund) {
            return
        }
        setLoading(false)
        setFund(fund)
    },[ portfolio.accountRequestsLoading, fundList.funds ])

    
    const [clipboard, setClipboard] = useState<ClipboardState>({
        accountBank: false,
        accountName: false,
        accountNumber: false,
    })

    const copyToClipboard = (field: string, text: string) => {
        setClipboard(prev => ({ ...prev, [field]: true }))
        setTimeout(() => {
            setClipboard(prev => ({ ...prev, [field]: false }))
        }, 1000)
        window.navigator.clipboard.writeText(text);
    }

    if (loading) {
        return <div className="h-full flex justify-center items-center">
            <Loader classNames="w-16 h-16 text-blue-100" />
        </div>
    }

    return <div className="pb-24">
        <div className="relative h-32 text-center hg-bg-blue" style={{ borderBottomLeftRadius: "40%", borderBottomRightRadius: "40%"}}>
            <ArrowLeftCircleIcon className="fixed mt-2 ml-2 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={() => navigate(`/portfolio/request/${requestId}`)}/>
            <h1 className="pt-16 text-xl text-white pl-3 pr-6">Bank transfer</h1>
        </div>

        <div className="flex flex-col text-sm font-medium 2xs:w-5/6 md:w-1/2 mx-auto">
            <div className="text-sm font-medium bg-slate-200 text-gray-800 border-gray-300 rounded-lg p-5 mb-5 mt-5">
                We will match your deposit using the bank account name. Please make sure you deposit from a bank account in your name.
            </div>
            <div className="flex flex-col gap-3 mb-5">
                <div className="flex flex-col gap-1 cursor-pointer" onClick={() => copyToClipboard("accountBank", fund.bankAccounts?.collection?.accountBank)}>
                    <span className="text-md font-medium text-gray-600">Bank name</span>
                    <div className="flex justify-between gap-1">
                        <span>{clipboard.accountBank ? "Copied!" : fund.bankAccounts?.collection?.accountBank}</span>
                        {!clipboard.accountBank ? <ClipboardIcon className="w-5 h-5"/> : <></> }
                    </div>
                </div>
                <div className="flex flex-col gap-1 cursor-pointer" onClick={() => copyToClipboard("accountName", fund.bankAccounts?.collection?.accountName)}>
                    <span className="text-md font-medium text-gray-600">Account name</span>
                    <div className="flex justify-between gap-1">
                        <span>{clipboard.accountName ? "Copied!" : fund.bankAccounts?.collection?.accountName}</span>
                        {!clipboard.accountName ? <ClipboardIcon className="w-5 h-5"/> : <></> }
                    </div>
                </div>
                <div className="flex flex-col gap-1 cursor-pointer" onClick={() => copyToClipboard("accountNumber", fund.bankAccounts?.collection?.accountNumber)}>
                    <span className="text-md font-medium text-gray-600">Account number</span>
                    <div className="flex justify-between gap-1">
                        <span>{clipboard.accountNumber ? "Copied!" : fund.bankAccounts?.collection?.accountNumber}</span>
                        {!clipboard.accountNumber ? <ClipboardIcon className="w-5 h-5"/> : <></> }
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <span className="text-md font-medium text-gray-600">Reference number</span>
                    <span>Not required</span>
                </div>
            </div>
        </div>
    </div>
}
