import { HTMLAttributes } from "react";

interface BankInputProps extends HTMLAttributes<HTMLSelectElement> {
}

export default function BankInput(props: BankInputProps) {
    return <select {...props}>
        <option value="">Select...</option>
        <option value="afbqm">MBSB BANK BERHAD</option>
        <option value="agobmykl">AGROBANK BERHAD</option>
        <option value="arbkmykl">AMBANK (M) BERHAD</option>
        <option value="bimbmykl">BANK ISLAM MALAYSIA BERHAD</option>
        <option value="bkrmmykl">BANK KERJASAMA RAKYAT MALAYSIA BERHAD (BANK RAKYAT)</option>
        <option value="bmmbmykl">BANK MUAMALAT MALAYSIA BERHAD</option>
        <option value="chasmykx">J.P.MORGAN CHASE BANK BERHAD</option>
        <option value="cibbmykl">CIMB BANK BERHAD</option>
        <option value="citimykl">CITIBANK BERHAD</option>
        <option value="deutmykl">DEUTSCHE BANK (M) BERHAD</option>
        <option value="hbmbmykl">HSBC BANK MALAYSIA BERHAD</option>
        <option value="hlbbmykl">HONG LEONG BANK BERHAD</option>
        <option value="kfhomykl">KUWAIT FINANCE HOUSE (MALAYSIA) BERHAD</option>
        <option value="mbbemykl">MALAYAN BANKING BERHAD (MAYBANK)</option>
        <option value="mfbbmykl">ALLIANCE BANK MALAYSIA BERHAD</option>
        <option value="ocbcmykl">OCBC BANK (MALAYSIA) BERHAD</option>
        <option value="pbbemykl">PUBLIC BANK BERHAD</option>
        <option value="phbmmykl">AFFIN BANK BERHAD</option>
        <option value="rhbbmykl">RHB BANK BERHAD</option>
        <option value="rjhimykl">AL RAJHI BANKING AND INVESTMENT CORPORATION</option>
        <option value="scblmykx">STANDARD CHARTERED BANK MALAYSIA BERHAD</option>
        <option value="smbcmykl">SUMITOMO MITSUI BANKING CORPORATION MALAYSIA BERHAD</option>
        <option value="uovbmykl">UNITED OVERSEAS BANK (MALAYSIA) BERHA</option>
    </select>
}