interface OnboardingUspFirstProps {
    className?: string
}


export default function OnboardingUspFirst({ className }: OnboardingUspFirstProps) {
    return <svg width="191" height="254" viewBox="0 0 251 314" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.66" d="M126.246 187.738L72.3834 156.46L72.1978 286.119L127.627 313.757L178.911 288.306V157.347L126.246 187.738Z" fill="url(#paint0_linear_2103_1075)"/>
        <path d="M126.101 26.1837L103.29 12.9321L103.372 38.6618L126.184 51.903L126.101 26.1837Z" fill="#A4C8FC"/>
        <path d="M125.678 0L103.29 12.9319L126.101 26.1834L148.49 13.2516L125.678 0Z" fill="url(#paint1_linear_2103_1075)"/>
        <path d="M148.49 13.2515L126.101 26.1833L126.184 51.9027L148.572 38.9811L148.49 13.2515Z" fill="#6095E2"/>
        <path d="M227.937 201.217L205.115 187.966L205.198 213.695L228.009 226.947L227.937 201.217Z" fill="#A4C8FC"/>
        <path d="M227.504 175.034L205.115 187.966L227.937 201.217L250.315 188.285L227.504 175.034Z" fill="url(#paint2_linear_2103_1075)"/>
        <path d="M250.315 188.286L227.937 201.218L228.009 226.947L250.398 214.015L250.315 188.286Z" fill="#6095E2"/>
        <path d="M22.8112 201.351L0 188.1L0.0824999 213.829L22.8937 227.081L22.8112 201.351Z" fill="#A4C8FC"/>
        <path d="M22.3884 175.168L0 188.1L22.8112 201.351L45.1997 188.42L22.3884 175.168Z" fill="url(#paint3_linear_2103_1075)"/>
        <path d="M45.1997 188.419L22.8112 201.351L22.8937 227.081L45.2822 214.149L45.1997 188.419Z" fill="#6095E2"/>
        <path d="M120.058 257.296C119.996 257.286 119.934 257.255 119.872 257.224L33.6909 208.312C33.3609 208.126 33.2475 207.714 33.4331 207.394C33.6187 207.064 34.0312 206.951 34.3509 207.136L120.532 256.049C120.862 256.234 120.976 256.647 120.79 256.966C120.636 257.234 120.336 257.358 120.048 257.296H120.058Z" fill="#8BA2B0"/>
        <path d="M223.616 81.9228C223.554 81.9125 223.492 81.8816 223.431 81.8507L137.249 32.9385C136.919 32.7529 136.806 32.3404 136.991 32.0207C137.177 31.6907 137.589 31.5773 137.909 31.7629L224.09 80.675C224.421 80.8607 224.534 81.2732 224.348 81.5928C224.194 81.861 223.895 81.9847 223.606 81.9228H223.616Z" fill="#8BA2B0"/>
        <path d="M131.433 258.668C131.257 258.627 131.103 258.524 131 258.359C130.804 258.039 130.917 257.627 131.237 257.431L215.614 207.24C215.933 207.044 216.346 207.157 216.542 207.477C216.738 207.797 216.624 208.209 216.305 208.405L131.928 258.596C131.773 258.689 131.598 258.709 131.433 258.678V258.668Z" fill="#8BA2B0"/>
        <path d="M227.504 188.956C227.205 188.884 226.978 188.615 226.978 188.296L227.659 89.203C227.659 88.8318 227.968 88.5327 228.339 88.5327C228.71 88.5327 229.01 88.8421 229.01 89.2133L228.329 188.306C228.329 188.677 228.02 188.976 227.648 188.976C227.597 188.976 227.545 188.976 227.504 188.956Z" fill="#8BA2B0"/>
        <path d="M22.2234 188.925C21.9244 188.852 21.6975 188.584 21.6975 188.265L22.3781 89.1718C22.3781 88.8005 22.6875 88.5015 23.0587 88.5015C23.43 88.5015 23.7291 88.8108 23.7291 89.1821L23.0484 188.275C23.0484 188.646 22.7391 188.945 22.3678 188.945C22.3162 188.945 22.2647 188.945 22.2234 188.925Z" fill="#8BA2B0"/>
        <path d="M28.1119 81.9739C27.9366 81.9326 27.7716 81.8192 27.6684 81.6439C27.4828 81.3139 27.5962 80.9014 27.9262 80.726L114.087 31.7726C114.417 31.587 114.83 31.7005 115.005 32.0305C115.191 32.3605 115.077 32.773 114.747 32.9483L28.5966 81.9017C28.4419 81.9842 28.2769 82.0048 28.1119 81.9739Z" fill="#8BA2B0"/>
        <path d="M227.494 84.3663L204.682 71.1147L204.755 96.8341L227.576 110.086L227.494 84.3663Z" fill="#A4C8FC"/>
        <path d="M227.06 58.1831L204.682 71.115L227.494 84.3665L249.882 71.4347L227.06 58.1831Z" fill="url(#paint4_linear_2103_1075)"/>
        <path d="M249.882 71.4346L227.494 84.3664L227.576 110.086L249.954 97.1539L249.882 71.4346Z" fill="#6095E2"/>
        <path d="M23.0484 84.5211L0.237183 71.2695L0.319683 96.9992L23.1309 110.251L23.0484 84.5211Z" fill="#A4C8FC"/>
        <path d="M22.6256 58.3374L0.237183 71.2693L23.0484 84.5208L45.4368 71.589L22.6256 58.3374Z" fill="url(#paint5_linear_2103_1075)"/>
        <path d="M45.4369 71.5894L23.0484 84.5212L23.1309 110.251L45.5194 97.319L45.4369 71.5894Z" fill="#6095E2"/>
        <path d="M129.814 140.25C129.638 140.209 129.473 140.096 129.37 139.92C129.185 139.59 129.298 139.178 129.628 139.002L215.789 90.049C216.119 89.8634 216.531 89.9768 216.707 90.3068C216.892 90.6368 216.779 91.0493 216.449 91.2246L130.288 140.178C130.133 140.261 129.968 140.281 129.803 140.25H129.814Z" fill="#8BA2B0"/>
        <path d="M120.316 140.487C120.254 140.476 120.192 140.446 120.13 140.415L33.9487 91.5025C33.6187 91.3169 33.5053 90.9043 33.6909 90.5847C33.8765 90.2547 34.289 90.1412 34.6087 90.3268L120.79 139.239C121.12 139.425 121.234 139.837 121.048 140.157C120.893 140.425 120.594 140.549 120.306 140.487H120.316Z" fill="#8BA2B0"/>
        <path d="M127.132 260.731L104.311 247.49L104.393 273.209L127.205 286.461L127.132 260.731Z" fill="#A4C8FC"/>
        <path d="M126.699 234.558L104.311 247.489L127.132 260.731L149.511 247.799L126.699 234.558Z" fill="url(#paint6_linear_2103_1075)"/>
        <path d="M149.511 247.799L127.132 260.731L127.205 286.46L149.593 273.528L149.511 247.799Z" fill="#6095E2"/>
        <path d="M126.802 248.583C126.503 248.511 126.277 248.253 126.277 247.933L125.183 155.317C125.183 154.946 125.482 154.636 125.854 154.636C126.225 154.636 126.534 154.935 126.534 155.307L127.627 247.923C127.627 248.294 127.328 248.604 126.957 248.604C126.906 248.604 126.854 248.604 126.802 248.583Z" fill="#8BA2B0"/>
        <path d="M125.049 81.438L72.1978 111.963L126.06 143.251L178.911 112.716L125.049 81.438Z" fill="url(#paint7_linear_2103_1075)"/>
        <path d="M178.911 112.715L126.06 143.251L72.1978 111.962L72.3834 172.703L126.246 203.981L179.097 173.456L178.911 112.715Z" fill="url(#paint8_linear_2103_1075)"/>
        <path d="M133.495 131.196L119.831 139.157V110.251C119.831 109.106 120.44 108.065 121.43 107.487L126.699 104.445C127.679 103.867 128.906 103.878 129.896 104.445L135.083 107.456C136.063 108.034 136.671 109.075 136.671 110.21V125.678C136.671 127.937 135.475 130.041 133.516 131.165V131.185L133.495 131.196Z" fill="#004BC3"/>
        <path d="M135.063 173.487L129.814 176.55C128.834 177.127 127.607 177.127 126.617 176.55L121.409 173.539C120.429 172.972 119.821 171.92 119.821 170.775V155.42C119.821 153.161 121.017 151.057 122.976 149.933L136.641 141.972V170.744C136.641 171.868 136.042 172.93 135.052 173.497L135.063 173.487Z" fill="#004BC3"/>
        <path d="M113.056 142.23V168.691L99.4021 160.782C97.4428 159.637 96.2362 157.554 96.2362 155.285V128.72L109.911 136.743C111.86 137.888 113.056 139.971 113.056 142.23Z" fill="#004BC3"/>
        <path d="M143.426 138.806V112.334L157.09 120.295C159.05 121.44 160.246 123.523 160.246 125.781V152.305L146.571 144.282C144.622 143.137 143.426 141.054 143.426 138.796V138.806Z" fill="#004BC3"/>
        <path d="M100.949 124.781L108.261 129.071C110.375 130.309 113.056 128.782 113.056 126.338V117.841C113.056 115.387 110.416 113.871 108.291 115.087L100.98 119.305C98.8659 120.522 98.8555 123.564 100.959 124.802L100.949 124.781Z" fill="#004BC3"/>
        <path d="M155.523 156.245L148.211 151.955C146.097 150.717 143.416 152.243 143.416 154.687V163.185C143.416 165.639 146.056 167.155 148.18 165.938L155.492 161.721C157.606 160.504 157.616 157.462 155.512 156.224L155.523 156.245Z" fill="#004BC3"/>
        <defs>
        <linearGradient id="paint0_linear_2103_1075" x1="125.555" y1="313.757" x2="125.555" y2="156.46" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E5F3FF" stopOpacity="0"/>
        <stop offset="0.81848" stopColor="#6693DB"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2103_1075" x1="123.804" y1="23.5382" x2="128.224" y2="1.50996" gradientUnits="userSpaceOnUse">
        <stop stopColor="#336FCF"/>
        <stop offset="0.31" stopColor="#1E60CA"/>
        <stop offset="0.73" stopColor="#0850C4"/>
        <stop offset="1" stopColor="#004BC3"/>
        </linearGradient>
        <linearGradient id="paint2_linear_2103_1075" x1="225.619" y1="198.575" x2="230.049" y2="176.557" gradientUnits="userSpaceOnUse">
        <stop stopColor="#336FCF"/>
        <stop offset="0.31" stopColor="#1E60CA"/>
        <stop offset="0.73" stopColor="#0850C4"/>
        <stop offset="1" stopColor="#004BC3"/>
        </linearGradient>
        <linearGradient id="paint3_linear_2103_1075" x1="20.5031" y1="198.704" x2="24.9233" y2="176.675" gradientUnits="userSpaceOnUse">
        <stop stopColor="#336FCF"/>
        <stop offset="0.31" stopColor="#1E60CA"/>
        <stop offset="0.73" stopColor="#0850C4"/>
        <stop offset="1" stopColor="#004BC3"/>
        </linearGradient>
        <linearGradient id="paint4_linear_2103_1075" x1="225.186" y1="81.7243" x2="229.616" y2="59.7063" gradientUnits="userSpaceOnUse">
        <stop stopColor="#336FCF"/>
        <stop offset="0.31" stopColor="#1E60CA"/>
        <stop offset="0.73" stopColor="#0850C4"/>
        <stop offset="1" stopColor="#004BC3"/>
        </linearGradient>
        <linearGradient id="paint5_linear_2103_1075" x1="20.7405" y1="81.873" x2="25.171" y2="59.8447" gradientUnits="userSpaceOnUse">
        <stop stopColor="#336FCF"/>
        <stop offset="0.31" stopColor="#1E60CA"/>
        <stop offset="0.73" stopColor="#0850C4"/>
        <stop offset="1" stopColor="#004BC3"/>
        </linearGradient>
        <linearGradient id="paint6_linear_2103_1075" x1="124.814" y1="258.096" x2="129.234" y2="236.067" gradientUnits="userSpaceOnUse">
        <stop stopColor="#336FCF"/>
        <stop offset="0.31" stopColor="#1E60CA"/>
        <stop offset="0.73" stopColor="#0850C4"/>
        <stop offset="1" stopColor="#004BC3"/>
        </linearGradient>
        <linearGradient id="paint7_linear_2103_1075" x1="121.163" y1="137.224" x2="130.425" y2="84.742" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E5F3FF"/>
        <stop offset="1" stopColor="#CCDBF3"/>
        </linearGradient>
        <linearGradient id="paint8_linear_2103_1075" x1="84.0056" y1="184.325" x2="167.289" y2="101.093" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E5F3FF"/>
        <stop offset="1" stopColor="#D7E7FF"/>
        </linearGradient>
        </defs>
    </svg>
}