import { ChangeEvent, useState, useEffect, KeyboardEvent, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { deleteAuthEmail, getSession, sendAuthEmail } from "../../api";
import { Loader } from "../../component";
import HalogenLogo from "../../asset/halogen-logo";
import { PersonaContext } from "./context";

export default function Login() {
    const navigate = useNavigate()
    const [renderLogin, setRenderLogin] = useState(false)
    const [email, setEmail] = useState("")
    const [validEmail, setValidEmail] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const [loadingSending, setLoadingSending] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        getSession().then((resp) => {
            if (resp.data.clientAuthenticated) {
                navigate(`/portfolio`)
                return
            }
            // if (resp.data.applicantAuthenticated) {
            //     navigate(`/create-account/wizard`)
            //     return
            // }
            setRenderLogin(true)
        }).catch(console.error)

        // if (expired) {
        //     setErrorMessage("This one-time login link has expired. Please get one-time access again.")
        // }
    }, [])

    const handleOnChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const email = ev.target.value?.trim()
        setEmail(email)

        const pattern = /^[\w\-+.]+@[\w\-]+\.[\w]{2,4}(\.[\w]{2,4}$)?/
        setValidEmail(pattern.test(email))
    }

    const handleOnSubmit = () => {
        if (!validEmail) return
        setLoadingSending(true)
        sendAuthEmail(email).then(() => {
            deleteAuthEmail()
            setLoadingSending(false)
            setEmailSent(true)
        }).catch(() => {
            setRenderLogin(true)
            return
        })
    }

    const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === "Enter") {
            handleOnSubmit()
            return
        }
    }

    if (!renderLogin) return <></>

    return <div className="flex flex-col h-full bg-white md:w-1/2 mx-auto">
        <HalogenLogo color="blue" className="ml-auto mr-auto pt-12 2xs:w-1/3 xs:w-1/4 sm:w-1/6 md:w-3/12 lg:w-3/12"/>
        <h1 className="text-center 2xs:text-4xl xs:text-5xl sm:text-5xl md:text-7xl lg:text-8xl mt-5 font-medium text-blue-950">Wallet</h1>

        {!emailSent && !loadingSending && errorMessage !== "" && <div className="text-center mt-10 bg-amber-300 text-sm font-medium text-amber-800 w-5/6 mr-auto ml-auto p-3 rounded-xl">
            <p>{errorMessage}</p>
        </div>}
        {!emailSent && !loadingSending &&
            <div className="text-center mt-10 mb-10">
                <input onChange={handleOnChange} className="mr-auto ml-auto rounded-full w-5/6 p-3 text-lg" autoFocus={true} placeholder="joe.dan@example.com" type="email" onKeyDown={handleKeyDown}/>
                <button onClick={handleOnSubmit} disabled={!validEmail} className="mr-auto ml-auto w-5/6 mt-5 text-white p-3 text-lg rounded-full hg-bg-blue disabled:bg-blue-300">Get one-time access</button>
            </div>
        }
        {emailSent && !loadingSending && <div className="text-center mt-10">
            <p>We have sent a one-time login link to you at {email}.</p>
        </div>}
        {!emailSent && loadingSending && <Loader classNames="ml-auto mr-auto mt-10 w-12 h-12 text-blue-100"/>}
    </div>
}
