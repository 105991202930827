import { useNavigate } from "react-router-dom";
import { getPersona, setPersonaTitle } from "../../api";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Loader } from "../../component";
import { PersonaContext } from "./context";

export default function PersonaTitle() {
    const [title, setTitle] = useState("")
    const [loading, setLoading] = useState(false)
    const [render, setRender] = useState(false)
    const persona = useContext(PersonaContext)
    const navigate = useNavigate()

    useEffect(() => {
        getPersona().then(resp => {
            setRender(true)
            if (resp.data?.persona?.title && resp.data?.persona?.title !== "") {
                navigate("/portfolio")
                return
            }
        }).catch(() => {
            navigate("/auth/email")
        })
    }, [])

    const handleOnChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const title = ev.target.value?.trim()
        setTitle(title)
    }
    
    const handleOnSubmit = () => {
        setLoading(true)
        setPersonaTitle(title).then(() => {
            persona.setTitle(title)
            navigate("/portfolio")
        }).catch(err => {
            navigate("/auth/email")
        })
    }

    if (!render) return <></>

    return <div className="pb-24">
        <div className="h-32 flex items-center justify-center hg-bg-blue"  style={{ borderBottomLeftRadius: "40%", borderBottomRightRadius: "40%" }}>
            <h1 className="text-xl font-medium text-white">
                How may we address you?
            </h1>
        </div>
        {!loading && <div className="flex flex-col relative h-96">
            <input type="text" placeholder="Joe Dan" className="mr-auto ml-auto rounded-full w-5/6 mt-10 p-3 pt-5 pb-5 text-lg" autoFocus={true} onChange={handleOnChange}/>
            <button disabled={title.length < 2} className="mr-auto ml-auto w-5/6 mt-10 text-white p-3 text-lg rounded-full hg-bg-blue disabled:bg-blue-300" onClick={handleOnSubmit}>Submit</button>
        </div>}
        {loading && <div className="mt-10 text-center w-full">
            <Loader classNames="w-12 h-12 text-blue-100" />
        </div>}

    </div>
}
