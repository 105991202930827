interface BitcoinProps {
    className?: string
}


export default function HSRIFLogo({ className }: BitcoinProps) {
    return <svg className={className} viewBox="0 0 87 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M43.4711 0.587932C43.8027 0.401115 44.2095 0.408956 44.5337 0.608412L86.1161 26.1965C86.4337 26.3919 86.6233 26.7414 86.6141 27.1141C86.6048 27.4868 86.398 27.8264 86.0713 28.0058L44.1957 50.9923C43.8712 51.1705 43.4767 51.164 43.1582 50.9753L1.2827 26.1595C0.961081 25.9689 0.765505 25.6212 0.769594 25.2474C0.773683 24.8736 0.976816 24.5303 1.30253 24.3468L43.4711 0.587932ZM3.90852 25.2817L43.7118 48.8695L83.486 27.0365L43.9616 2.71483L3.90852 25.2817Z" fill="#53D4C5"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.4711 0.587932C43.8027 0.401115 44.2095 0.408956 44.5337 0.608412L86.1161 26.1965C86.4337 26.3919 86.6233 26.7414 86.6141 27.1141C86.6048 27.4868 86.398 27.8264 86.0713 28.0058L44.1957 50.9923C43.8712 51.1705 43.4767 51.164 43.1582 50.9753L1.2827 26.1595C0.961081 25.9689 0.765505 25.6212 0.769594 25.2474C0.773683 24.8736 0.976816 24.5303 1.30253 24.3468L43.4711 0.587932ZM3.90852 25.2817L43.7118 48.8695L83.486 27.0365L43.9616 2.71483L3.90852 25.2817Z" fill="#F6B444"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.4711 0.587932C43.8027 0.401115 44.2095 0.408956 44.5337 0.608412L86.1161 26.1965C86.4337 26.3919 86.6233 26.7414 86.6141 27.1141C86.6048 27.4868 86.398 27.8264 86.0713 28.0058L44.1957 50.9923C43.8712 51.1705 43.4767 51.164 43.1582 50.9753L1.2827 26.1595C0.961081 25.9689 0.765505 25.6212 0.769594 25.2474C0.773683 24.8736 0.976816 24.5303 1.30253 24.3468L43.4711 0.587932ZM3.90852 25.2817L43.7118 48.8695L83.486 27.0365L43.9616 2.71483L3.90852 25.2817Z" fill="#88A4F6"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.4711 0.587932C43.8027 0.401115 44.2095 0.408956 44.5337 0.608412L86.1161 26.1965C86.4337 26.3919 86.6233 26.7414 86.6141 27.1141C86.6048 27.4868 86.398 27.8264 86.0713 28.0058L44.1957 50.9923C43.8712 51.1705 43.4767 51.164 43.1582 50.9753L1.2827 26.1595C0.961081 25.9689 0.765505 25.6212 0.769594 25.2474C0.773683 24.8736 0.976816 24.5303 1.30253 24.3468L43.4711 0.587932ZM3.90852 25.2817L43.7118 48.8695L83.486 27.0365L43.9616 2.71483L3.90852 25.2817Z" fill="#53D4C5"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.6953 49.0234C44.2735 49.0234 44.7422 49.4921 44.7422 50.0703V97.5398L85.0276 73.0822C85.5218 72.7822 86.1657 72.9396 86.4657 73.4338C86.7658 73.9281 86.6084 74.5719 86.1142 74.872L44.2386 100.295C43.9154 100.491 43.5114 100.498 43.1819 100.312C42.8524 100.127 42.6484 99.7782 42.6484 99.4001V50.0703C42.6484 49.4921 43.1171 49.0234 43.6953 49.0234Z" fill="#53D4C5"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.6953 49.0234C44.2735 49.0234 44.7422 49.4921 44.7422 50.0703V97.5398L85.0276 73.0822C85.5218 72.7822 86.1657 72.9396 86.4657 73.4338C86.7658 73.9281 86.6084 74.5719 86.1142 74.872L44.2386 100.295C43.9154 100.491 43.5114 100.498 43.1819 100.312C42.8524 100.127 42.6484 99.7782 42.6484 99.4001V50.0703C42.6484 49.4921 43.1171 49.0234 43.6953 49.0234Z" fill="#F6B444"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.6953 49.0234C44.2735 49.0234 44.7422 49.4921 44.7422 50.0703V97.5398L85.0276 73.0822C85.5218 72.7822 86.1657 72.9396 86.4657 73.4338C86.7658 73.9281 86.6084 74.5719 86.1142 74.872L44.2386 100.295C43.9154 100.491 43.5114 100.498 43.1819 100.312C42.8524 100.127 42.6484 99.7782 42.6484 99.4001V50.0703C42.6484 49.4921 43.1171 49.0234 43.6953 49.0234Z" fill="#88A4F6"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.6953 49.0234C44.2735 49.0234 44.7422 49.4921 44.7422 50.0703V97.5398L85.0276 73.0822C85.5218 72.7822 86.1657 72.9396 86.4657 73.4338C86.7658 73.9281 86.6084 74.5719 86.1142 74.872L44.2386 100.295C43.9154 100.491 43.5114 100.498 43.1819 100.312C42.8524 100.127 42.6484 99.7782 42.6484 99.4001V50.0703C42.6484 49.4921 43.1171 49.0234 43.6953 49.0234Z" fill="#53D4C5"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.81642 24.2109C2.3946 24.2109 2.86331 24.6796 2.86331 25.2578V73.6224L44.2316 98.5073C44.7271 98.8053 44.8871 99.4486 44.5891 99.944C44.291 100.439 43.6478 100.599 43.1523 100.301L1.27678 75.1115C0.962032 74.9221 0.769531 74.5817 0.769531 74.2144V25.2578C0.769531 24.6796 1.23824 24.2109 1.81642 24.2109Z" fill="#53D4C5"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.81642 24.2109C2.3946 24.2109 2.86331 24.6796 2.86331 25.2578V73.6224L44.2316 98.5073C44.7271 98.8053 44.8871 99.4486 44.5891 99.944C44.291 100.439 43.6478 100.599 43.1523 100.301L1.27678 75.1115C0.962032 74.9221 0.769531 74.5817 0.769531 74.2144V25.2578C0.769531 24.6796 1.23824 24.2109 1.81642 24.2109Z" fill="#F6B444"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.81642 24.2109C2.3946 24.2109 2.86331 24.6796 2.86331 25.2578V73.6224L44.2316 98.5073C44.7271 98.8053 44.8871 99.4486 44.5891 99.944C44.291 100.439 43.6478 100.599 43.1523 100.301L1.27678 75.1115C0.962032 74.9221 0.769531 74.5817 0.769531 74.2144V25.2578C0.769531 24.6796 1.23824 24.2109 1.81642 24.2109Z" fill="#88A4F6"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.81642 24.2109C2.3946 24.2109 2.86331 24.6796 2.86331 25.2578V73.6224L44.2316 98.5073C44.7271 98.8053 44.8871 99.4486 44.5891 99.944C44.291 100.439 43.6478 100.599 43.1523 100.301L1.27678 75.1115C0.962032 74.9221 0.769531 74.5817 0.769531 74.2144V25.2578C0.769531 24.6796 1.23824 24.2109 1.81642 24.2109Z" fill="#53D4C5"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M85.5703 26.0391C86.1485 26.0391 86.6172 26.5078 86.6172 27.086V73.9793C86.6172 74.5574 86.1485 75.0261 85.5703 75.0261C84.9921 75.0261 84.5234 74.5574 84.5234 73.9793V27.086C84.5234 26.5078 84.9921 26.0391 85.5703 26.0391Z" fill="#53D4C5"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M85.5703 26.0391C86.1485 26.0391 86.6172 26.5078 86.6172 27.086V73.9793C86.6172 74.5574 86.1485 75.0261 85.5703 75.0261C84.9921 75.0261 84.5234 74.5574 84.5234 73.9793V27.086C84.5234 26.5078 84.9921 26.0391 85.5703 26.0391Z" fill="#F6B444"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M85.5703 26.0391C86.1485 26.0391 86.6172 26.5078 86.6172 27.086V73.9793C86.6172 74.5574 86.1485 75.0261 85.5703 75.0261C84.9921 75.0261 84.5234 74.5574 84.5234 73.9793V27.086C84.5234 26.5078 84.9921 26.0391 85.5703 26.0391Z" fill="#88A4F6"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M85.5703 26.0391C86.1485 26.0391 86.6172 26.5078 86.6172 27.086V73.9793C86.6172 74.5574 86.1485 75.0261 85.5703 75.0261C84.9921 75.0261 84.5234 74.5574 84.5234 73.9793V27.086C84.5234 26.5078 84.9921 26.0391 85.5703 26.0391Z" fill="#53D4C5"/>
        <path d="M11.5195 33.1257V67.3861C11.5196 67.8501 11.6418 68.306 11.8739 68.7079C12.106 69.1098 12.4398 69.4435 12.8418 69.6756L42.5224 86.8058C42.9245 87.0378 43.3805 87.16 43.8448 87.16C44.309 87.16 44.7651 87.0378 45.1671 86.8058L74.847 69.6756C75.249 69.4435 75.5828 69.1098 75.8149 68.7079C76.047 68.306 76.1692 67.8501 76.1692 67.3861V33.1257C76.1693 32.6616 76.0471 32.2057 75.815 31.8038C75.5828 31.4019 75.249 31.0681 74.847 30.8361L45.167 13.7059C44.7649 13.4738 44.3088 13.3516 43.8446 13.3516C43.3803 13.3516 42.9242 13.4738 42.5222 13.7059L12.8418 30.8361C12.4398 31.0681 12.1059 31.4019 11.8738 31.8038C11.6417 32.2057 11.5195 32.6616 11.5195 33.1257Z" fill="#53D4C5"/>
        <path d="M11.5195 33.1257V67.3861C11.5196 67.8501 11.6418 68.306 11.8739 68.7079C12.106 69.1098 12.4398 69.4435 12.8418 69.6756L42.5224 86.8058C42.9245 87.0378 43.3805 87.16 43.8448 87.16C44.309 87.16 44.7651 87.0378 45.1671 86.8058L74.847 69.6756C75.249 69.4435 75.5828 69.1098 75.8149 68.7079C76.047 68.306 76.1692 67.8501 76.1692 67.3861V33.1257C76.1693 32.6616 76.0471 32.2057 75.815 31.8038C75.5828 31.4019 75.249 31.0681 74.847 30.8361L45.167 13.7059C44.7649 13.4738 44.3088 13.3516 43.8446 13.3516C43.3803 13.3516 42.9242 13.4738 42.5222 13.7059L12.8418 30.8361C12.4398 31.0681 12.1059 31.4019 11.8738 31.8038C11.6417 32.2057 11.5195 32.6616 11.5195 33.1257Z" fill="#F6B444"/>
        <path d="M11.5195 33.1257V67.3861C11.5196 67.8501 11.6418 68.306 11.8739 68.7079C12.106 69.1098 12.4398 69.4435 12.8418 69.6756L42.5224 86.8058C42.9245 87.0378 43.3805 87.16 43.8448 87.16C44.309 87.16 44.7651 87.0378 45.1671 86.8058L74.847 69.6756C75.249 69.4435 75.5828 69.1098 75.8149 68.7079C76.047 68.306 76.1692 67.8501 76.1692 67.3861V33.1257C76.1693 32.6616 76.0471 32.2057 75.815 31.8038C75.5828 31.4019 75.249 31.0681 74.847 30.8361L45.167 13.7059C44.7649 13.4738 44.3088 13.3516 43.8446 13.3516C43.3803 13.3516 42.9242 13.4738 42.5222 13.7059L12.8418 30.8361C12.4398 31.0681 12.1059 31.4019 11.8738 31.8038C11.6417 32.2057 11.5195 32.6616 11.5195 33.1257Z" fill="#88A4F6"/>
        <path d="M11.5195 33.1257V67.3861C11.5196 67.8501 11.6418 68.306 11.8739 68.7079C12.106 69.1098 12.4398 69.4435 12.8418 69.6756L42.5224 86.8058C42.9245 87.0378 43.3805 87.16 43.8448 87.16C44.309 87.16 44.7651 87.0378 45.1671 86.8058L74.847 69.6756C75.249 69.4435 75.5828 69.1098 75.8149 68.7079C76.047 68.306 76.1692 67.8501 76.1692 67.3861V33.1257C76.1693 32.6616 76.0471 32.2057 75.815 31.8038C75.5828 31.4019 75.249 31.0681 74.847 30.8361L45.167 13.7059C44.7649 13.4738 44.3088 13.3516 43.8446 13.3516C43.3803 13.3516 42.9242 13.4738 42.5222 13.7059L12.8418 30.8361C12.4398 31.0681 12.1059 31.4019 11.8738 31.8038C11.6417 32.2057 11.5195 32.6616 11.5195 33.1257Z" fill="#53D4C5"/>
        <path d="M11.5195 33.1257V67.3861C11.5196 67.8501 11.6418 68.306 11.8739 68.7079C12.106 69.1098 12.4398 69.4435 12.8418 69.6756L42.5224 86.8058C42.9245 87.0378 43.3805 87.16 43.8448 87.16C44.309 87.16 44.7651 87.0378 45.1671 86.8058L74.847 69.6756C75.249 69.4435 75.5828 69.1098 75.8149 68.7079C76.047 68.306 76.1692 67.8501 76.1692 67.3861V33.1257C76.1693 32.6616 76.0471 32.2057 75.815 31.8038C75.5828 31.4019 75.249 31.0681 74.847 30.8361L45.167 13.7059C44.7649 13.4738 44.3088 13.3516 43.8446 13.3516C43.3803 13.3516 42.9242 13.4738 42.5222 13.7059L12.8418 30.8361C12.4398 31.0681 12.1059 31.4019 11.8738 31.8038C11.6417 32.2057 11.5195 32.6616 11.5195 33.1257Z" fill="url(#paint0_linear_7507_2171)"/>
        <circle opacity="0.18" cx="44.0449" cy="50.459" r="22.834" fill="url(#paint1_radial_7507_2171)"/>
        <path d="M42.3125 39.6719H47.4658L52.9086 52.9507H53.1402L58.5829 39.6719H63.7362V59.4357H59.6831V46.5719H59.519L54.4044 59.3392H51.6444L46.5297 46.5236H46.3656V59.4357H42.3125V39.6719Z" fill="white"/>
        <path d="M24.4727 59.4279V39.6641H32.2701C33.7627 39.6641 35.0365 39.9311 36.0916 40.465C37.1532 40.9926 37.9606 41.7421 38.5139 42.7136C39.0736 43.6786 39.3534 44.8141 39.3534 46.1201C39.3534 47.4326 39.0704 48.5617 38.5042 49.5074C37.9381 50.4467 37.1178 51.1672 36.0434 51.6691C34.9754 52.1709 33.6823 52.4218 32.164 52.4218H26.9431V49.0635H31.4884C32.2862 49.0635 32.9489 48.9541 33.4764 48.7354C34.004 48.5166 34.3964 48.1885 34.6537 47.751C34.9175 47.3135 35.0494 46.7699 35.0494 46.1201C35.0494 45.4639 34.9175 44.9106 34.6537 44.4603C34.3964 44.0099 34.0007 43.6689 33.4668 43.4373C32.9392 43.1993 32.2733 43.0803 31.4691 43.0803H28.6512V59.4279H24.4727ZM35.1459 50.4338L40.0579 59.4279H35.4451L30.6392 50.4338H35.1459Z" fill="white"/>
        <defs>
        <linearGradient id="paint0_linear_7507_2171" x1="43.8444" y1="13.3516" x2="43.8444" y2="87.16" gradientUnits="userSpaceOnUse">
        <stop stopColor="#53D4C5"/>
        <stop offset="1" stopColor="#3F8DD5"/>
        </linearGradient>
        <radialGradient id="paint1_radial_7507_2171" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(44.0449 50.459) rotate(90) scale(22.834)">
        <stop stopColor="#0A4844"/>
        <stop offset="1" stopColor="#0A4844" stopOpacity="0"/>
        </radialGradient>
        </defs>
    </svg>
}