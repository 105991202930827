import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import moment, { duration } from "moment";
import { PersonaContext, PersonaListContext } from "../login/context";
import { Loader } from "../../component";
import { CreateAccountStatementInput, createAccountStatement } from "../../api";
import EmainSentIllustration from "../../asset/email-sent";

interface StatementState {
    form: CreateAccountStatementInput
    creating: boolean
    created: boolean
}

export default function Statement() {
    const persona = useContext(PersonaContext)
    const personaList = useContext(PersonaListContext)
    const [state, setState] = useState<StatementState>({
        created: false,
        creating: false,
        form: {
            clientId: "",
            accountId: "",
            fromDate: moment().add(duration(-1, "month")).format("yyyy-MM"),
            toDate: moment().add(duration(-1, "month")).format("yyyy-MM"),
            // format: "singlefile",
        }
    })

    useEffect(() => {
        if (personaList.loading || personaList.clients.length > 1) {
            return
        }
        setState(prev => ({
            ...prev,
            form: {
                ...prev.form,
                clientId: personaList.clients[0].clientId,
                accountId: personaList.clients[0].accountId,
            }
        }))
    }, [personaList.loading])

    const handleAccountChange = (ev: ChangeEvent<HTMLSelectElement>) => {
        const index = parseInt(ev.currentTarget.value)
        setState(prev => ({
            ...prev,
            form: {
                ...prev.form,
                clientId: personaList.clients[index].clientId,
                accountId: personaList.clients[index].accountId,
            }
        }))
    }

    const handleFromDateChange = (ev: ChangeEvent<HTMLInputElement>) => {
        let date = moment(ev.currentTarget.value)
        let fromDate = date.format("yyyy-MM")
        if (date.isBefore("2023-07")) {
           fromDate = "2023-07"
        }
        setState(prev => ({
            ...prev,
            form: {
                ...prev.form,
                fromDate,
            }
        }))
    }

    const handleToDateChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const date = moment(ev.currentTarget.value)
        let toDate = date.format("yyyy-MM")
        if (date.isBefore(state.form.fromDate)) {
           toDate = state.form.fromDate
        }
        if (date.isAfter(moment().format("yyyy-MM"))) {
            toDate = moment().format("yyyy-MM")
        }
        setState(prev => ({
            ...prev,
            form: {
                ...prev.form,
                toDate,
            }
        }))
    }

    const handleFormatChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const value = ev.currentTarget.value
        if (value !== "singlefile" && value !== "multiplefiles") return
        setState(prev => ({
            ...prev,
            form: {
                ...prev.form,
                format: value
            }
        }))
    }

    const handleSubmit = () => {
        setState(prev => ({ ...prev, creating: true }))
        createAccountStatement(state.form).then(() => {
            setState(prev => ({ ...prev, created: true }))
        }).catch(() => {
            setState(prev => ({ ...prev, creating: false }))
        })
    }

    const navigate = useNavigate()

    if (personaList.loading) {
        return <div className="flex justify-center items-center h-full w-full">
            <Loader classNames="w-12 h-12 text-blue-100" />
        </div>
    }
    
    const canSubmit = !state.creating && state.form.clientId !== "" && state.form.accountId !== "" && state.form.fromDate !== "" && state.form.toDate !== "" && state.form.fromDate <= state.form.toDate && state.form.toDate <= moment().format("yyyy-MM")

    return <div className="flex flex-col h-full">
        <div>
            <div className="relative h-32 text-center hg-bg-blue" style={{ borderBottomLeftRadius: "40%", borderBottomRightRadius: "40%"}}>
                <ArrowLeftCircleIcon className="fixed mt-2 ml-2 w-8 h-8 text-white hg-bg-blue rounded-zxl cursor-pointer" onClick={() => navigate("/settings")}/>
                <h1 className="pt-12 text-xl text-white pl-3 pr-6">
                    Statements
                </h1>
            </div>
        </div>
        {state.created && <div className="flex flex-col justify-between h-full p-5">
            <div className="flex flex-col justify-center items-center text-center w-full gap-6 h-1/2">
                <EmainSentIllustration />
                <h3 className="text-blue-800 font-medium text-xl">
                    Your statement is on the way!
                </h3>
                <h3 className="text-blue-800 font-medium text-lg">
                    Please find the requested statement attached to the email we've sent.
                </h3>
            </div>
            <a href="#/portfolio" className="text-center w-64 mx-auto p-3 hg-bg-blue text-white rounded-full">Portfolio</a>
        </div>}
        {!state.created && <div className="flex flex-col justify-between h-full p-5">
            <div className="flex flex-col gap-5">
                <div className="flex items-center">
                    <label className="w-2/6">Account</label>
                    {personaList.clients.length === 1 && <label>{personaList.clients[0].accountName} - {personaList.clients[0].accountId}</label>}
                    {personaList.clients.length > 1 && <select className="w-4/6 border rounded-full" onChange={handleAccountChange}>
                        <option value="">Select..</option>
                        {personaList.clients.map((c, i) => {
                            return <option key={i} value={i}>{c.accountName} - {c.accountId}</option>
                        })}
                    </select>}
                </div>
                <div className="flex items-center">
                    <label className="w-2/6">From</label>
                    <input type="month" onChange={handleFromDateChange} value={state.form.fromDate} min={"2023-07"} max={moment().format("YYYY-MM")} className="border rounded-full w-4/6" />
                </div>
                <div className="flex items-center">
                    <label className="w-2/6">To</label>
                    <input type="month" onChange={handleToDateChange} value={state.form.toDate} min={state.form.fromDate} max={moment().format("YYYY-MM")} className="border rounded-full w-4/6" />
                </div>
                {/* <div className="flex items-center">
                    <label className="w-2/6">Format</label>
                    <div className="w-4/6 inline-flex flex-col gap-1">
                        <div className="">
                            <input onChange={handleFormatChange} value="singlefile" id="singlefile" type="radio" checked={state.form.format === "singlefile"} />
                            <label htmlFor="singlefile" className="ml-2 text-sm">Single file</label>
                        </div>
                        <div className="">
                            <input onChange={handleFormatChange} value="multiplefiles" id="multiplefiles" type="radio" checked={state.form.format === "multiplefiles"}/>
                            <label htmlFor="multiplefiles" className="ml-2 text-sm">Multiple files - one per month</label>
                        </div>
                    </div>
                </div> */}
            </div>
            <button className="w-full p-3 hg-bg-blue text-white rounded-full disabled:bg-blue-300" disabled={!canSubmit} onClick={handleSubmit}>
                {!state.creating && "Submit"}
                {state.creating && <Loader classNames="text-blue-300 h-5 w-5"/>}
            </button>
        </div>}
    </div>
}
