
import { useState, Fragment, useEffect } from "react"
import { useParams } from "react-router-dom"
import { CheckBadgeIcon, ExclamationTriangleIcon, LinkSlashIcon } from "@heroicons/react/24/outline"
import HalogenLogo from "../../asset/halogen-logo"
import { Loader } from "../../component"
import { VerifyPolicyParticipantEmailOutput, verifyPolicyParticipantEmail } from "../../api"
import { AxiosError } from "axios"

interface ParticipantVerifyEmailState {
    loading: boolean
    output: VerifyPolicyParticipantEmailOutput
    error: boolean
}

export default function ParticipantVerifyEmail() {
    const { token } = useParams()
    const [state, setState] = useState<ParticipantVerifyEmailState>({
        loading: true,
        output: {
            expired: false,
        } as VerifyPolicyParticipantEmailOutput,
        error: false,
    })

    useEffect(() => {
        if (!token) return
        verifyPolicyParticipantEmail({
            token: token,
        }).then((resp) => {
            const output = resp.data as VerifyPolicyParticipantEmailOutput
            setState(prev => ({
                ...prev,
                output,
            }))
        }).catch((err: AxiosError) => {
            setState(prev => ({
                ...prev,
                error: true
            }))
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false
            }))
        })
    }, [])

    return <div className="h-full md:max-w-3xl mr-auto ml-auto">
        <div className="mb-12 hg-bg-blue w-full py-3 flex justify-center gap-2 cursor-pointer" onClick={() => window.location.href = "https://wallet.halogen.my"}>
            <HalogenLogo color="white" className="w-6"/>
            <label className="my-auto text-white text-xl font-semibold">Wallet</label>
        </div>

        {state.loading && <div className="text-center mr-3 ml-3">
            <Loader classNames="w-12 h-12 text-blue-100" />
        </div>}
        {!state.loading && state.error && <div>
            <div className="mt-12 flex flex-col gap-3 text-center mr-3 ml-3 font-medium text-red-900">
                <ExclamationTriangleIcon className="w-16 h-16 mr-auto ml-auto text-red-500" />
                <p>Something went wrong!</p> 
                <p>Try to reload the page, if the problem persists, please do not hesitate to reach out to us at <a href="mailto:support@halogen.my" className="underline">support@halogen.my</a>.</p>
                <button onClick={() => window.location.reload()} className="mt-3 mx-auto hg-bg-blue text-white rounded-full p-3 px-12">Reload</button>
            </div>
        </div>}
        {!state.loading && !state.error && state.output && state.output.expired && <div>
            <div className="mt-12 flex flex-col gap-3 text-center mr-3 ml-3 font-medium text-amber-900">
                <LinkSlashIcon className="w-16 h-16 mr-auto ml-auto text-amber-400" />
                <p>Expired link!</p> 
                <p>Please ask the authorised person of the Halogen account to send a new link.</p>
            </div>
        </div>}
        {!state.loading && !state.error && state.output && !state.output.expired && <Fragment>
            <div className="mt-12 flex flex-col gap-3 text-center mr-3 ml-3 font-medium text-blue-900">
                <CheckBadgeIcon className="w-16 h-16 mr-auto ml-auto hg-text-blue" />
                <p>Thank you!</p> 
                <p>Your email was verified, and you will be notified for new transactions made to the account.</p>
            </div>

            <div className="flex flex-col gap-3 justify-center mt-12">
                <label className="text-sm text-center font-bold text-blue-900">Unlock the future of wealth with Halogen!</label>
                <button onClick={() => window.location.href = "https://wallet.halogen.my/#/create-account"} className="mx-auto hg-bg-blue text-white rounded-full p-3 px-12">Invest Now</button>
            </div>
        </Fragment>}
    </div>
}
