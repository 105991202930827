interface BitcoinProps {
    className?: string
}


export default function HSCTFLogo({ className }: BitcoinProps) {
    return <svg className={className} viewBox="0 0 87 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M43.4893 51.1719C43.2968 51.1719 43.1257 51.1184 42.9547 51.0222L1.0809 26.2042C0.760108 26.0011 0.567635 25.6589 0.567635 25.2846C0.567635 24.9104 0.781494 24.5575 1.10228 24.3865L43.2754 0.637761C43.6069 0.445291 44.0133 0.455983 44.3447 0.659146L85.9298 26.247C86.2399 26.4502 86.4324 26.7923 86.4324 27.1666C86.4324 27.5408 86.2078 27.883 85.887 28.0541L44.0133 51.0436C43.8529 51.1291 43.6818 51.1719 43.5107 51.1719H43.4893ZM3.71137 25.3274L43.5107 48.9157L83.2779 27.081L43.7673 2.75493L3.71137 25.3274Z" fill="#327AD9"/>
        <path d="M43.4893 51.1719C43.2968 51.1719 43.1257 51.1184 42.9547 51.0222L1.0809 26.2042C0.760108 26.0011 0.567635 25.6589 0.567635 25.2846C0.567635 24.9104 0.781494 24.5575 1.10228 24.3865L43.2754 0.637761C43.6069 0.445291 44.0133 0.455983 44.3447 0.659146L85.9298 26.247C86.2399 26.4502 86.4324 26.7923 86.4324 27.1666C86.4324 27.5408 86.2078 27.883 85.887 28.0541L44.0133 51.0436C43.8529 51.1291 43.6818 51.1719 43.5107 51.1719H43.4893ZM3.71137 25.3274L43.5107 48.9157L83.2779 27.081L43.7673 2.75493L3.71137 25.3274Z" fill="#3684E5"/>
        <path d="M43.4893 51.1719C43.2968 51.1719 43.1257 51.1184 42.9547 51.0222L1.0809 26.2042C0.760108 26.0011 0.567635 25.6589 0.567635 25.2846C0.567635 24.9104 0.781494 24.5575 1.10228 24.3865L43.2754 0.637761C43.6069 0.445291 44.0133 0.455983 44.3447 0.659146L85.9298 26.247C86.2399 26.4502 86.4324 26.7923 86.4324 27.1666C86.4324 27.5408 86.2078 27.883 85.887 28.0541L44.0133 51.0436C43.8529 51.1291 43.6818 51.1719 43.5107 51.1719H43.4893ZM3.71137 25.3274L43.5107 48.9157L83.2779 27.081L43.7673 2.75493L3.71137 25.3274Z" fill="#327AD9"/>
        <path d="M43.4893 51.1719C43.2968 51.1719 43.1257 51.1184 42.9547 51.0222L1.0809 26.2042C0.760108 26.0011 0.567635 25.6589 0.567635 25.2846C0.567635 24.9104 0.781494 24.5575 1.10228 24.3865L43.2754 0.637761C43.6069 0.445291 44.0133 0.455983 44.3447 0.659146L85.9298 26.247C86.2399 26.4502 86.4324 26.7923 86.4324 27.1666C86.4324 27.5408 86.2078 27.883 85.887 28.0541L44.0133 51.0436C43.8529 51.1291 43.6818 51.1719 43.5107 51.1719H43.4893ZM3.71137 25.3274L43.5107 48.9157L83.2779 27.081L43.7673 2.75493L3.71137 25.3274Z" fill="#3684E5"/>
        <path d="M43.4893 51.1719C43.2968 51.1719 43.1257 51.1184 42.9547 51.0222L1.0809 26.2042C0.760108 26.0011 0.567635 25.6589 0.567635 25.2846C0.567635 24.9104 0.781494 24.5575 1.10228 24.3865L43.2754 0.637761C43.6069 0.445291 44.0133 0.455983 44.3447 0.659146L85.9298 26.247C86.2399 26.4502 86.4324 26.7923 86.4324 27.1666C86.4324 27.5408 86.2078 27.883 85.887 28.0541L44.0133 51.0436C43.8529 51.1291 43.6818 51.1719 43.5107 51.1719H43.4893ZM3.71137 25.3274L43.5107 48.9157L83.2779 27.081L43.7673 2.75493L3.71137 25.3274Z" fill="#327AD9"/>
        <path d="M43.4893 51.1719C43.2968 51.1719 43.1257 51.1184 42.9547 51.0222L1.0809 26.2042C0.760108 26.0011 0.567635 25.6589 0.567635 25.2846C0.567635 24.9104 0.781494 24.5575 1.10228 24.3865L43.2754 0.637761C43.6069 0.445291 44.0133 0.455983 44.3447 0.659146L85.9298 26.247C86.2399 26.4502 86.4324 26.7923 86.4324 27.1666C86.4324 27.5408 86.2078 27.883 85.887 28.0541L44.0133 51.0436C43.8529 51.1291 43.6818 51.1719 43.5107 51.1719H43.4893ZM3.71137 25.3274L43.5107 48.9157L83.2779 27.081L43.7673 2.75493L3.71137 25.3274Z" fill="#3684E5"/>
        <path d="M43.4893 51.1719C43.2968 51.1719 43.1257 51.1184 42.9547 51.0222L1.0809 26.2042C0.760108 26.0011 0.567635 25.6589 0.567635 25.2846C0.567635 24.9104 0.781494 24.5575 1.10228 24.3865L43.2754 0.637761C43.6069 0.445291 44.0133 0.455983 44.3447 0.659146L85.9298 26.247C86.2399 26.4502 86.4324 26.7923 86.4324 27.1666C86.4324 27.5408 86.2078 27.883 85.887 28.0541L44.0133 51.0436C43.8529 51.1291 43.6818 51.1719 43.5107 51.1719H43.4893ZM3.71137 25.3274L43.5107 48.9157L83.2779 27.081L43.7673 2.75493L3.71137 25.3274Z" fill="#327AD9"/>
        <path d="M43.4893 51.1719C43.2968 51.1719 43.1257 51.1184 42.9547 51.0222L1.0809 26.2042C0.760108 26.0011 0.567635 25.6589 0.567635 25.2846C0.567635 24.9104 0.781494 24.5575 1.10228 24.3865L43.2754 0.637761C43.6069 0.445291 44.0133 0.455983 44.3447 0.659146L85.9298 26.247C86.2399 26.4502 86.4324 26.7923 86.4324 27.1666C86.4324 27.5408 86.2078 27.883 85.887 28.0541L44.0133 51.0436C43.8529 51.1291 43.6818 51.1719 43.5107 51.1719H43.4893ZM3.71137 25.3274L43.5107 48.9157L83.2779 27.081L43.7673 2.75493L3.71137 25.3274Z" fill="#3684E5"/>
        <path d="M43.4905 100.5C43.3088 100.5 43.1377 100.457 42.9773 100.361C42.6458 100.168 42.4426 99.826 42.4426 99.4517V50.1259C42.4426 49.5485 42.9131 49.078 43.4905 49.078C44.068 49.078 44.5385 49.5485 44.5385 50.1259V97.5912L84.819 73.1368C85.3108 72.8374 85.9631 72.9871 86.2518 73.4896C86.5512 73.9815 86.4015 74.6337 85.8989 74.9225L44.0252 100.339C43.8541 100.446 43.6723 100.5 43.4798 100.5H43.4905Z" fill="#327AD9"/>
        <path d="M43.4905 100.5C43.3088 100.5 43.1377 100.457 42.9773 100.361C42.6458 100.168 42.4426 99.826 42.4426 99.4517V50.1259C42.4426 49.5485 42.9131 49.078 43.4905 49.078C44.068 49.078 44.5385 49.5485 44.5385 50.1259V97.5912L84.819 73.1368C85.3108 72.8374 85.9631 72.9871 86.2518 73.4896C86.5512 73.9815 86.4015 74.6337 85.8989 74.9225L44.0252 100.339C43.8541 100.446 43.6723 100.5 43.4798 100.5H43.4905Z" fill="#3684E5"/>
        <path d="M43.4905 100.5C43.3088 100.5 43.1377 100.457 42.9773 100.361C42.6458 100.168 42.4426 99.826 42.4426 99.4517V50.1259C42.4426 49.5485 42.9131 49.078 43.4905 49.078C44.068 49.078 44.5385 49.5485 44.5385 50.1259V97.5912L84.819 73.1368C85.3108 72.8374 85.9631 72.9871 86.2518 73.4896C86.5512 73.9815 86.4015 74.6337 85.8989 74.9225L44.0252 100.339C43.8541 100.446 43.6723 100.5 43.4798 100.5H43.4905Z" fill="#327AD9"/>
        <path d="M43.4905 100.5C43.3088 100.5 43.1377 100.457 42.9773 100.361C42.6458 100.168 42.4426 99.826 42.4426 99.4517V50.1259C42.4426 49.5485 42.9131 49.078 43.4905 49.078C44.068 49.078 44.5385 49.5485 44.5385 50.1259V97.5912L84.819 73.1368C85.3108 72.8374 85.9631 72.9871 86.2518 73.4896C86.5512 73.9815 86.4015 74.6337 85.8989 74.9225L44.0252 100.339C43.8541 100.446 43.6723 100.5 43.4798 100.5H43.4905Z" fill="#3684E5"/>
        <path d="M43.4905 100.5C43.3088 100.5 43.1377 100.457 42.9773 100.361C42.6458 100.168 42.4426 99.826 42.4426 99.4517V50.1259C42.4426 49.5485 42.9131 49.078 43.4905 49.078C44.068 49.078 44.5385 49.5485 44.5385 50.1259V97.5912L84.819 73.1368C85.3108 72.8374 85.9631 72.9871 86.2518 73.4896C86.5512 73.9815 86.4015 74.6337 85.8989 74.9225L44.0252 100.339C43.8541 100.446 43.6723 100.5 43.4798 100.5H43.4905Z" fill="#327AD9"/>
        <path d="M43.4905 100.5C43.3088 100.5 43.1377 100.457 42.9773 100.361C42.6458 100.168 42.4426 99.826 42.4426 99.4517V50.1259C42.4426 49.5485 42.9131 49.078 43.4905 49.078C44.068 49.078 44.5385 49.5485 44.5385 50.1259V97.5912L84.819 73.1368C85.3108 72.8374 85.9631 72.9871 86.2518 73.4896C86.5512 73.9815 86.4015 74.6337 85.8989 74.9225L44.0252 100.339C43.8541 100.446 43.6723 100.5 43.4798 100.5H43.4905Z" fill="#3684E5"/>
        <path d="M43.4905 100.5C43.3088 100.5 43.1377 100.457 42.9773 100.361C42.6458 100.168 42.4426 99.826 42.4426 99.4517V50.1259C42.4426 49.5485 42.9131 49.078 43.4905 49.078C44.068 49.078 44.5385 49.5485 44.5385 50.1259V97.5912L84.819 73.1368C85.3108 72.8374 85.9631 72.9871 86.2518 73.4896C86.5512 73.9815 86.4015 74.6337 85.8989 74.9225L44.0252 100.339C43.8541 100.446 43.6723 100.5 43.4798 100.5H43.4905Z" fill="#327AD9"/>
        <path d="M43.4905 100.5C43.3088 100.5 43.1377 100.457 42.9773 100.361C42.6458 100.168 42.4426 99.826 42.4426 99.4517V50.1259C42.4426 49.5485 42.9131 49.078 43.4905 49.078C44.068 49.078 44.5385 49.5485 44.5385 50.1259V97.5912L84.819 73.1368C85.3108 72.8374 85.9631 72.9871 86.2518 73.4896C86.5512 73.9815 86.4015 74.6337 85.8989 74.9225L44.0252 100.339C43.8541 100.446 43.6723 100.5 43.4798 100.5H43.4905Z" fill="#3684E5"/>
        <path d="M43.4893 100.5C43.2968 100.5 43.1257 100.447 42.9547 100.35L1.07021 75.1582C0.760108 74.9658 0.567635 74.6343 0.567635 74.2707V25.2977C0.567635 24.7203 1.03813 24.2498 1.61555 24.2498C2.19297 24.2498 2.66346 24.7203 2.66346 25.2977V73.6612L44.0346 98.5434C44.5265 98.8321 44.6976 99.4843 44.3875 99.9762C44.1843 100.297 43.8422 100.479 43.4893 100.479V100.5Z" fill="#327AD9"/>
        <path d="M43.4893 100.5C43.2968 100.5 43.1257 100.447 42.9547 100.35L1.07021 75.1582C0.760108 74.9658 0.567635 74.6343 0.567635 74.2707V25.2977C0.567635 24.7203 1.03813 24.2498 1.61555 24.2498C2.19297 24.2498 2.66346 24.7203 2.66346 25.2977V73.6612L44.0346 98.5434C44.5265 98.8321 44.6976 99.4843 44.3875 99.9762C44.1843 100.297 43.8422 100.479 43.4893 100.479V100.5Z" fill="#3684E5"/>
        <path d="M43.4893 100.5C43.2968 100.5 43.1257 100.447 42.9547 100.35L1.07021 75.1582C0.760108 74.9658 0.567635 74.6343 0.567635 74.2707V25.2977C0.567635 24.7203 1.03813 24.2498 1.61555 24.2498C2.19297 24.2498 2.66346 24.7203 2.66346 25.2977V73.6612L44.0346 98.5434C44.5265 98.8321 44.6976 99.4843 44.3875 99.9762C44.1843 100.297 43.8422 100.479 43.4893 100.479V100.5Z" fill="#327AD9"/>
        <path d="M43.4893 100.5C43.2968 100.5 43.1257 100.447 42.9547 100.35L1.07021 75.1582C0.760108 74.9658 0.567635 74.6343 0.567635 74.2707V25.2977C0.567635 24.7203 1.03813 24.2498 1.61555 24.2498C2.19297 24.2498 2.66346 24.7203 2.66346 25.2977V73.6612L44.0346 98.5434C44.5265 98.8321 44.6976 99.4843 44.3875 99.9762C44.1843 100.297 43.8422 100.479 43.4893 100.479V100.5Z" fill="#3684E5"/>
        <path d="M43.4893 100.5C43.2968 100.5 43.1257 100.447 42.9547 100.35L1.07021 75.1582C0.760108 74.9658 0.567635 74.6343 0.567635 74.2707V25.2977C0.567635 24.7203 1.03813 24.2498 1.61555 24.2498C2.19297 24.2498 2.66346 24.7203 2.66346 25.2977V73.6612L44.0346 98.5434C44.5265 98.8321 44.6976 99.4843 44.3875 99.9762C44.1843 100.297 43.8422 100.479 43.4893 100.479V100.5Z" fill="#327AD9"/>
        <path d="M43.4893 100.5C43.2968 100.5 43.1257 100.447 42.9547 100.35L1.07021 75.1582C0.760108 74.9658 0.567635 74.6343 0.567635 74.2707V25.2977C0.567635 24.7203 1.03813 24.2498 1.61555 24.2498C2.19297 24.2498 2.66346 24.7203 2.66346 25.2977V73.6612L44.0346 98.5434C44.5265 98.8321 44.6976 99.4843 44.3875 99.9762C44.1843 100.297 43.8422 100.479 43.4893 100.479V100.5Z" fill="#3684E5"/>
        <path d="M43.4893 100.5C43.2968 100.5 43.1257 100.447 42.9547 100.35L1.07021 75.1582C0.760108 74.9658 0.567635 74.6343 0.567635 74.2707V25.2977C0.567635 24.7203 1.03813 24.2498 1.61555 24.2498C2.19297 24.2498 2.66346 24.7203 2.66346 25.2977V73.6612L44.0346 98.5434C44.5265 98.8321 44.6976 99.4843 44.3875 99.9762C44.1843 100.297 43.8422 100.479 43.4893 100.479V100.5Z" fill="#327AD9"/>
        <path d="M43.4893 100.5C43.2968 100.5 43.1257 100.447 42.9547 100.35L1.07021 75.1582C0.760108 74.9658 0.567635 74.6343 0.567635 74.2707V25.2977C0.567635 24.7203 1.03813 24.2498 1.61555 24.2498C2.19297 24.2498 2.66346 24.7203 2.66346 25.2977V73.6612L44.0346 98.5434C44.5265 98.8321 44.6976 99.4843 44.3875 99.9762C44.1843 100.297 43.8422 100.479 43.4893 100.479V100.5Z" fill="#3684E5"/>
        <path d="M85.3616 75.0695C84.7842 75.0695 84.3137 74.599 84.3137 74.0216V27.1337C84.3137 26.5563 84.7842 26.0858 85.3616 26.0858C85.9391 26.0858 86.4095 26.5563 86.4095 27.1337V74.0216C86.4095 74.599 85.9391 75.0695 85.3616 75.0695Z" fill="#327AD9"/>
        <path d="M85.3616 75.0695C84.7842 75.0695 84.3137 74.599 84.3137 74.0216V27.1337C84.3137 26.5563 84.7842 26.0858 85.3616 26.0858C85.9391 26.0858 86.4095 26.5563 86.4095 27.1337V74.0216C86.4095 74.599 85.9391 75.0695 85.3616 75.0695Z" fill="#3684E5"/>
        <path d="M85.3616 75.0695C84.7842 75.0695 84.3137 74.599 84.3137 74.0216V27.1337C84.3137 26.5563 84.7842 26.0858 85.3616 26.0858C85.9391 26.0858 86.4095 26.5563 86.4095 27.1337V74.0216C86.4095 74.599 85.9391 75.0695 85.3616 75.0695Z" fill="#327AD9"/>
        <path d="M85.3616 75.0695C84.7842 75.0695 84.3137 74.599 84.3137 74.0216V27.1337C84.3137 26.5563 84.7842 26.0858 85.3616 26.0858C85.9391 26.0858 86.4095 26.5563 86.4095 27.1337V74.0216C86.4095 74.599 85.9391 75.0695 85.3616 75.0695Z" fill="#3684E5"/>
        <path d="M85.3616 75.0695C84.7842 75.0695 84.3137 74.599 84.3137 74.0216V27.1337C84.3137 26.5563 84.7842 26.0858 85.3616 26.0858C85.9391 26.0858 86.4095 26.5563 86.4095 27.1337V74.0216C86.4095 74.599 85.9391 75.0695 85.3616 75.0695Z" fill="#327AD9"/>
        <path d="M85.3616 75.0695C84.7842 75.0695 84.3137 74.599 84.3137 74.0216V27.1337C84.3137 26.5563 84.7842 26.0858 85.3616 26.0858C85.9391 26.0858 86.4095 26.5563 86.4095 27.1337V74.0216C86.4095 74.599 85.9391 75.0695 85.3616 75.0695Z" fill="#3684E5"/>
        <path d="M85.3616 75.0695C84.7842 75.0695 84.3137 74.599 84.3137 74.0216V27.1337C84.3137 26.5563 84.7842 26.0858 85.3616 26.0858C85.9391 26.0858 86.4095 26.5563 86.4095 27.1337V74.0216C86.4095 74.599 85.9391 75.0695 85.3616 75.0695Z" fill="#327AD9"/>
        <path d="M85.3616 75.0695C84.7842 75.0695 84.3137 74.599 84.3137 74.0216V27.1337C84.3137 26.5563 84.7842 26.0858 85.3616 26.0858C85.9391 26.0858 86.4095 26.5563 86.4095 27.1337V74.0216C86.4095 74.599 85.9391 75.0695 85.3616 75.0695Z" fill="#3684E5"/>
        <path d="M11.3137 33.1742V67.4446C11.3137 67.9044 11.442 68.3642 11.6666 68.7598C11.9018 69.1554 12.2333 69.4976 12.629 69.7222L42.3127 86.852C42.7084 87.0873 43.1682 87.2049 43.628 87.2049C44.0877 87.2049 44.5476 87.0766 44.9432 86.852L74.6269 69.7222C75.0226 69.4869 75.3648 69.1554 75.5893 68.7598C75.8246 68.3642 75.9422 67.9044 75.9422 67.4446V33.1742C75.9422 32.7144 75.8139 32.2546 75.5893 31.859C75.3541 31.4633 75.0226 31.1212 74.6269 30.8966L44.9432 13.7668C44.5476 13.5315 44.0877 13.4139 43.628 13.4139C43.1682 13.4139 42.7084 13.5422 42.3127 13.7668L12.6397 30.8752C12.244 31.1105 11.9018 31.442 11.6773 31.8376C11.442 32.2332 11.3244 32.693 11.3244 33.1528L11.3137 33.1742Z" fill="url(#paint0_linear_7507_2205)"/>
        <circle opacity="0.18" cx="44.3977" cy="50.5057" r="22.834" fill="url(#paint1_radial_7507_2205)"/>
        <path d="M64.9176 54.0825V45.5389H55.4115C53.9038 45.5389 52.503 46.3302 51.7224 47.6133L47.7981 54.0718H64.9069L64.9176 54.0825Z" fill="white"/>
        <path d="M50.3946 71.5499L57.7514 67.369L51.8916 57.0612C51.8061 56.9008 51.635 56.8045 51.4532 56.8045H48.9724C45.8608 56.8045 43.9146 60.1621 45.4437 62.8673L50.3839 71.5499H50.3946Z" fill="white"/>
        <path d="M22.1114 45.5311L22.0793 54.0319L34.1838 54.0746C34.248 54.0746 34.3121 54.0426 34.3335 53.9891L35.6594 51.6688C37.2099 48.9528 35.2638 45.5739 32.1308 45.5739L22.09 45.5418L22.1114 45.5311Z" fill="white"/>
        <path d="M58.8824 33.9119L51.3545 29.453L45.0136 40.1672C44.9815 40.2206 44.9815 40.2955 45.0136 40.3489L46.3823 42.7441C47.9755 45.5349 51.9961 45.567 53.6321 42.7976L58.8931 33.9119H58.8824Z" fill="white"/>
        <path d="M29.4075 67.0943L36.8177 71.4249L41.6403 63.1808C42.3995 61.8762 42.4315 60.2616 41.7151 58.925L38.0902 52.242L29.4075 67.0943Z" fill="white"/>
        <path d="M35.9153 29.698L28.7083 34.1035L33.6163 42.1231C34.3969 43.3955 35.7763 44.1654 37.2733 44.1654L44.7477 44.1333L35.9153 29.6873V29.698Z" fill="white"/>
        <path d="M43.4996 54.9427L39.5325 48.078H47.456L43.4889 54.9427H43.4996Z" fill="white"/>
        <defs>
        <linearGradient id="paint0_linear_7507_2205" x1="43.6279" y1="13.4032" x2="43.6279" y2="87.2049" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3B92D9"/>
        <stop offset="1" stopColor="#3369FF"/>
        </linearGradient>
        <radialGradient id="paint1_radial_7507_2205" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(44.3977 50.5057) rotate(90) scale(22.834)">
        <stop stopColor="#060F1C"/>
        <stop offset="1" stopColor="#060F1C" stopOpacity="0"/>
        </radialGradient>
        </defs>
    </svg>
}