import Layout from '../../layout';
import { Navigate } from "react-router-dom";
import Switch from './switch';

export default [
  {
    path: "/switch",
    element: <Layout><Switch /></Layout>,
    errorElement: <Navigate to="/" />
  }
]
