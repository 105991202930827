
import { ArrowLeftIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { ChangeEvent, MouseEvent, useContext, useState } from "react"
import { Doughnut } from "react-chartjs-2"
import { FundListContext } from "../fund/context"
import { useNavigate } from "react-router-dom"

interface BuilderAllocation {
    fundCode?: string
    percentage?: number
}

interface BuilderState {
    allocations: Set<BuilderAllocation>
}

interface CreatePortfolioState {
    // allocations: Allocation[]
}

export default function CreatePortfolio() {
    const navigate = useNavigate()
    const [state, setState] = useState<CreatePortfolioState>()
    const [builder, setBuilder] = useState<BuilderState>({
        allocations: new Set<BuilderAllocation>([
            { fundCode: "hsbtcf", percentage: 60 },
            { fundCode: "hsethf", percentage: 30 },
            { fundCode: "hsrif", percentage: 10 },
        ]),
    })
    
    const fundList = useContext(FundListContext)

    const handleAddAllocation = (fundCode: string, percentage: number) => {
        setBuilder(prev => ({
            allocations: new Set<BuilderAllocation>(prev.allocations).add({
                fundCode,
                percentage,
            })
        }))
    }
    
    const handleRemoveAllocation = (ev: MouseEvent<SVGElement>) => {
        const index = ev.currentTarget.dataset.index
        if (!index) return
        setBuilder(prev => {
            const newAllocations = Array.from(prev.allocations)
            newAllocations.splice(parseInt(index), 1)
            return {
                ...prev,
                allocations: new Set<BuilderAllocation>(newAllocations)
            }
        })
    }
    
    const handleChangeFund = (ev: ChangeEvent<HTMLSelectElement>) => {
        const index = ev.currentTarget.dataset.index
        if (!index) return
        setBuilder(prev => {
            const newAllocations = Array.from(prev.allocations)
            newAllocations[parseInt(index)].fundCode = ev.target.value
            return {
                ...prev,
                allocations: new Set<BuilderAllocation>(newAllocations)
            }
        })
    }
    
    const handleChangePercentage = (ev: ChangeEvent<HTMLInputElement>) => {
        const index = ev.currentTarget.dataset.index
        if (!index) return
        setBuilder(prev => {
            const newAllocations = Array.from(prev.allocations)
            newAllocations[parseInt(index)].percentage = parseInt(ev.target.value)
            return {
                ...prev,
                allocations: new Set<BuilderAllocation>(newAllocations)
            }
        })
    }

    return <div className="h-full">
        <div className="flex flex-col gap-4 h-full bg-black overflow-auto">
            {/* Header */}
            <div className="flex items-center justify-between text-white mt-5 mx-3">
                <ArrowLeftIcon className="w-5 h-5" onClick={() => navigate("/v2/")}/>
                <div className="flex flex-col items-center gap-1">
                    <h3 className="">New Portfolio</h3>
                </div>
                <div></div>
            </div>

            {/* Chart */}
            <div>
                <div className="relative">
                    <div className="flex flex-col justify-center items-center text-white text-sm absolute top-1/2 bottom-1/2 right-1/2 left-1/3 w-1/3">
                        <span className="text-gray-400 font-medium">Value</span>
                        <span className="font-bold">RM 10,827,123.23</span>
                        {/* <h3 className="text-xs font-bold text-green-400">+RM13,012,134.87</h3> */}
                    </div>
                    <Doughnut
                        className="p-5"
                        options={{ 
                            maintainAspectRatio: true, 
                            responsive: true,
                            cutout: 140,
                            plugins: {
                                legend: {
                                    display: false,
                                }
                            }
                        }}
                        data={{
                            labels: ["HSBTCF", "HSETHF", "HSRIF"],
                            datasets: [
                                {
                                    data: Array.from(builder.allocations).map(a => a.percentage),
                                    backgroundColor: "transparent",
                                    borderColor: [ "#FFA500", "#37367b", "#38B261" ],
                                    borderWidth: 4,
                                    hoverOffset: 16,
                                },
                            ],
                        }}
                    />
                </div>
            </div>

            {/* Requests */}
            <div className="flex flex-col gap-4 p-3 pb-12 bg-gray-900 rounded-tr-3xl rounded-tl-3xl  text-white">
                <h3 className="font-medium">Allocations</h3>
                {Array.from(builder.allocations).map((a, index) => <div className={`flex flex-col gap-1 text-gray-200 pb-3 ${index !== builder.allocations.size - 1 && "border-b border-b-gray-500"}`}>
                    <div className="flex items-center gap-2">
                        <select className="w-full cursor-pointer bg-transparent p-1 text-sm" data-index={index} onChange={handleChangeFund}>
                            <option value="">Select asset..</option>
                            {fundList.funds?.map(f => {
                                return <option selected={f.code === a.fundCode} value={f.code}>{f.name}</option>
                            })}
                        </select>
                        <div>
                            <XMarkIcon data-index={index} className="w-7 h-7 bg-gray-800 rounded-full p-1" onClick={handleRemoveAllocation}/>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center">
                        <input type="range" data-index={index} className="bg-transparent slider appearance-none py-3" style={{}} value={a.percentage} min={0} max={100} onChange={handleChangePercentage} />
                        <label className="text-center text-sm font-medium">{a.percentage}%</label>
                    </div>
                    {/* <div className="flex">
                        <input type="number" data-index={index} placeholder="10" className="bg-transparent text-sm text-white p-1" value={a.percentage} min={0} max={100} onChange={handleChangePercentage} />
                        <label className="border border-gray-500 p-1 text-sm font-medium">%</label>
                    </div> */}
                </div>)}
                
                {builder.allocations.size < fundList.funds.length && <div className="flex justify-center items-center">
                    <PlusIcon onClick={() => handleAddAllocation("", 50)} className="p-1 bg-gray-800 rounded-full text-gray-300 w-8 h-8"/>
                </div>}
            </div>


        </div>
    </div>
}
