import Layout from '../../layout';
import PersonaContext, { PersonaListContext } from './context';
import Login from './login';
import PersonaTitle from './persona_title';
import { Navigate } from "react-router-dom";
import Verify from './verify';
import Onboarding from './onboarding';
import PersonaAccountList from './persona_account_list';
import Biometric from './biometric';

export default [
    {
      path: "/auth/email",
      element: <Login />,
      errorElement: <Navigate to="/" />
    },
    {
      path: "/auth/verify/:id",
      element: <Verify />,
      errorElement: <Navigate to="/" />
    },
    {
      path: "/auth/biometric",
      element: <PersonaContext.PersonaProvider><Biometric /></PersonaContext.PersonaProvider>,
      errorElement: <Navigate to="/" />
    },
    {
      path: "/onboarding",
      element: <PersonaContext.PersonaProvider><Onboarding /></PersonaContext.PersonaProvider>,
      errorElement: <Navigate to="/" />
    },
    {
      path: "/persona/title",
      element: <PersonaContext.PersonaProvider><PersonaTitle /></PersonaContext.PersonaProvider>,
      errorElement: <Navigate to="/" />
    },
    {
      path: "/persona/account/list",
      element: <PersonaContext.PersonaListProvider><PersonaAccountList /></PersonaContext.PersonaListProvider>,
      errorElement: <Navigate to="/" />
    }
]
