import { ChangeEvent, KeyboardEvent, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { createAccount, getSession } from "../../api"
import { CheckBadgeIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline"
import { Loader } from "../../component"
import EmainSentIllustration from "../../asset/email-sent"

interface CreateAccountState {
    email: string
    validEmail: boolean
    emailSent: boolean
    loading: boolean
    referrerCode: string
}

export default function CreateAccount() {
    const navigate = useNavigate()
    const [state, setState] = useState<CreateAccountState>({
        email: "",
        referrerCode: "",
        validEmail: false,
        emailSent: false,
        loading: false,
    })

    useEffect(() => {
        getSession().then(resp => {
            if (resp.data.clientAuthenticated) {
                navigate("/portfolio")
                return
            }
            let referrerCode = ""
            if (window.location.pathname.split("/").length > 1) {
                referrerCode = window.location.pathname.split("/")[1] 
            }
            setState(prev => ({
                ...prev,
                referrerCode: referrerCode
            }))
        }).catch(() => navigate("/"))
    }, [])

    const handleEmailChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const email = ev.target?.value
        const pattern = /^[\w\-+.]+@[\w\-]+\.[\w]{2,4}(\.[\w]{2,4}$)?/
        const validEmail = pattern.test(email)
        setState(prev => ({
            ...prev,
            email,
            validEmail,
        }))
    }

    const submitDisabled = !state.validEmail || state.loading
    const handleSubmit = async () => {
        if (submitDisabled) {
            return
        }
        try {
            setState(prev => ({...prev, loading: true}))
            const resp = await createAccount({
                email: state.email,
                referrerCode: state.referrerCode,
            })
            setState(prev => ({
                ...prev,
                emailSent: true,
                loading: false
            }))
        } catch(err) {
            setState(prev => ({...prev, loading: false}))
        }
    }
    const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
        if (ev.key !== "Enter") {
            return
        }
        handleSubmit()
    }

    return <div className="flex flex-col items-center md:w-1/2 p-5 mx-auto md:justify-normal md:gap-20 sm:justify-between xs:justify-between h-full">
        {state.emailSent && <div className="flex flex-col xs:justify-between sm:justify-between md:justify-normal md:gap-20 h-full">
            <div className="flex flex-col justify-center items-center text-center w-full gap-6 h-1/2">
                <EmainSentIllustration />
                <h3 className="text-blue-800 font-medium text-xl">
                    We've sent you an email!
                </h3>
                <h3 className="text-blue-800 font-medium text-lg">
                    Please click the link in the email we’ve sent to {state.email} to verify your email.
                </h3>
            </div>
            <a href="#/onboarding/home" className="text-center w-64 mx-auto p-3 hg-bg-blue text-white rounded-full">Home</a>
        </div>}
        {!state.emailSent && <>
            <div className="flex flex-col items-start w-full gap-6">
                <h3 className="text-blue-800 font-medium text-xl">
                    Let's start by connecting. What's your email address?
                </h3>
                <input type="email" onKeyDown={handleKeyDown} inputMode="email" className="w-full p-3 hg-border-blue rounded-full" placeholder="joe.dan@example.com" value={state.email} onChange={handleEmailChange}/>
            </div>
            <div className="w-full">
                <button type="submit" disabled={submitDisabled} onClick={handleSubmit} className="w-full p-3 hg-bg-blue text-white rounded-full disabled:bg-blue-300">
                    {!state.loading && state.referrerCode !== "" && `Submit (Referral: ${state.referrerCode.toUpperCase()})`}
                    {!state.loading && state.referrerCode === "" && "Submit"}
                    {state.loading && <Loader classNames="text-blue-300 h-5 w-5"/>}
                </button>
            </div>
        </>}
    </div>
}