interface MalaysianRinggitProps {
    color: "white" | "blue"
    className?: string
}


export default function MalaysianRinggit({ color, className }: MalaysianRinggitProps) {
    if (color == "blue") {
        return <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" stroke="#06AEFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.52517 12.9186C7.40772 12.9264 7.28244 12.9303 7.14933 12.9303C7.01622 12.9303 6.81851 12.9205 6.55621 12.901V15.953H5V8.10565C5.5481 8.03522 6.22148 8 7.02013 8C8.1085 8 8.9189 8.20152 9.45134 8.60455C9.9877 9.00367 10.2559 9.62191 10.2559 10.4593C10.2559 11.1088 10.0112 11.6645 9.52181 12.1262C9.3613 12.2749 9.17729 12.404 8.9698 12.5136L10.1678 15.0374C10.4027 15.5578 10.5828 15.867 10.7081 15.9648L10.6787 15.9941C10.2989 15.9315 9.7019 15.9335 8.88758 16L7.52517 12.9186ZM7.13171 9.38518C6.93205 9.38518 6.74021 9.39496 6.55621 9.41453V11.6566C6.74804 11.684 6.97315 11.6977 7.23154 11.6977C7.48993 11.6977 7.70721 11.6762 7.88339 11.6332C8.05956 11.5862 8.21225 11.5158 8.34144 11.4219C8.61941 11.2145 8.75839 10.9034 8.75839 10.4886C8.75839 10.0739 8.61353 9.78234 8.32383 9.61409C8.05369 9.46148 7.65632 9.38518 7.13171 9.38518Z" fill="#06AEFF"/>
            <path d="M13.5973 15.9883C13.4094 15.9648 13.0473 15.953 12.5109 15.953H12.0411V8.12326C12.2917 8.13891 12.5677 8.14674 12.8691 8.14674C13.1706 8.14674 13.3839 8.13108 13.5092 8.09978L15.4824 11.4512L17.3792 8.09978C17.5045 8.13108 17.7433 8.14674 18.0956 8.14674C18.448 8.14674 18.7494 8.13891 19 8.12326V15.953H18.5302C17.9938 15.953 17.6317 15.9648 17.4438 15.9883V10.7293L15.5235 14.1629L13.5973 11.0051V15.9883Z" fill="#06AEFF"/>
        </svg>
    }
    return <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="10" fill="white"/>
        <path d="M7.52517 12.9186C7.40772 12.9264 7.28244 12.9303 7.14933 12.9303C7.01622 12.9303 6.81851 12.9205 6.55621 12.901V15.953H5V8.10565C5.5481 8.03522 6.22148 8 7.02013 8C8.1085 8 8.9189 8.20152 9.45134 8.60455C9.9877 9.00367 10.2559 9.62191 10.2559 10.4593C10.2559 11.1088 10.0112 11.6645 9.52181 12.1262C9.3613 12.2749 9.17729 12.404 8.9698 12.5136L10.1678 15.0374C10.4027 15.5578 10.5828 15.867 10.7081 15.9648L10.6787 15.9941C10.2989 15.9315 9.7019 15.9335 8.88758 16L7.52517 12.9186ZM7.13171 9.38518C6.93205 9.38518 6.74021 9.39496 6.55621 9.41453V11.6566C6.74804 11.684 6.97315 11.6977 7.23154 11.6977C7.48993 11.6977 7.70721 11.6762 7.88339 11.6332C8.05956 11.5862 8.21225 11.5158 8.34144 11.4219C8.61941 11.2145 8.75839 10.9034 8.75839 10.4886C8.75839 10.0739 8.61353 9.78234 8.32383 9.61409C8.05369 9.46148 7.65632 9.38518 7.13171 9.38518Z" fill="#154BC5"/>
        <path d="M13.5973 15.9883C13.4094 15.9648 13.0473 15.953 12.5109 15.953H12.0411V8.12326C12.2917 8.13891 12.5677 8.14674 12.8691 8.14674C13.1706 8.14674 13.3839 8.13108 13.5092 8.09978L15.4824 11.4512L17.3792 8.09978C17.5045 8.13108 17.7433 8.14674 18.0956 8.14674C18.448 8.14674 18.7494 8.13891 19 8.12326V15.953H18.5302C17.9938 15.953 17.6317 15.9648 17.4438 15.9883V10.7293L15.5235 14.1629L13.5973 11.0051V15.9883Z" fill="#154BC5"/>
    </svg>
}