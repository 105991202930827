interface BitcoinProps {
    className?: string
}


export default function HSEIFLogo({ className }: BitcoinProps) {
    return <svg className={className} viewBox="0 0 87 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M40.5672 0.128069C40.8822 -0.0494107 41.2687 -0.041961 41.5766 0.147525L81.0807 24.4566C81.3823 24.6422 81.5625 24.9743 81.5537 25.3283C81.5449 25.6824 81.3485 26.0051 81.038 26.1755L41.2556 48.0131C40.9473 48.1823 40.5725 48.1761 40.27 47.9968L0.487523 24.4214C0.181975 24.2404 -0.00382484 23.9101 5.97401e-05 23.555C0.00394432 23.1998 0.196924 22.8737 0.506359 22.6993L40.5672 0.128069ZM2.98209 23.5876L40.7959 45.9963L78.582 25.2546L41.0332 2.14866L2.98209 23.5876Z" fill="#F2763A"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M40.7802 46.1426C41.3295 46.1426 41.7748 46.5879 41.7748 47.1371V92.2339L80.0465 68.9988C80.516 68.7138 81.1278 68.8633 81.4128 69.3328C81.6979 69.8024 81.5483 70.4141 81.0788 70.6991L41.2963 94.8513C40.9893 95.0378 40.6055 95.0441 40.2924 94.8679C39.9794 94.6918 39.7856 94.3604 39.7856 94.0012V47.1371C39.7856 46.5879 40.2309 46.1426 40.7802 46.1426Z" fill="#F2763A"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.994564 22.5703C1.54385 22.5703 1.98913 23.0156 1.98913 23.5649V69.512L41.2897 93.153C41.7604 93.4362 41.9124 94.0473 41.6293 94.518C41.3461 94.9886 40.735 95.1407 40.2643 94.8575L0.481898 70.9267C0.182878 70.7468 0 70.4234 0 70.0744V23.5649C0 23.0156 0.445281 22.5703 0.994564 22.5703Z" fill="#F2763A"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M80.5621 24.3071C81.1114 24.3071 81.5567 24.7523 81.5567 25.3016V69.851C81.5567 70.4003 81.1114 70.8456 80.5621 70.8456C80.0128 70.8456 79.5676 70.4003 79.5676 69.851V25.3016C79.5676 24.7523 80.0128 24.3071 80.5621 24.3071Z" fill="#F2763A"/>
    <path d="M10.2127 31.0394V63.5874C10.2127 64.0283 10.3288 64.4613 10.5493 64.8432C10.7698 65.225 11.087 65.542 11.4689 65.7625L39.6659 82.0365C40.0479 82.2569 40.4811 82.3729 40.9222 82.3729C41.3632 82.3729 41.7965 82.2569 42.1785 82.0365L70.3748 65.7625C70.7567 65.542 71.0738 65.225 71.2943 64.8432C71.5148 64.4613 71.6309 64.0283 71.6309 63.5874V31.0394C71.631 30.5986 71.5149 30.1654 71.2944 29.7836C71.0739 29.4018 70.7567 29.0847 70.3748 28.8643L42.1783 12.5903C41.7963 12.3698 41.3631 12.2537 40.922 12.2537C40.4809 12.2537 40.0476 12.3698 39.6657 12.5903L11.4689 28.8643C11.0869 29.0847 10.7698 29.4018 10.5493 29.7836C10.3288 30.1654 10.2127 30.5986 10.2127 31.0394Z" fill="url(#paint0_linear_10116_11431)"/>
    <circle opacity="0.18" cx="40.7787" cy="47.5" r="21.6927" fill="url(#paint1_radial_10116_11431)"/>
    <path d="M37.963 37.7617H42.8586L48.1766 43.0274L55.5889 35.8392L58.2571 38.7375L48.2195 48.5175L41.9694 42.4632H41.8135L41.8135 56.6379H37.963L37.963 37.7617Z" fill="white"/>
    <path d="M21.0148 56.6305V37.8545H28.4225C29.8405 37.8545 31.0507 38.1082 32.0531 38.6155C33.0615 39.1166 33.8286 39.8287 34.3542 40.7516C34.886 41.6684 35.1518 42.7472 35.1518 43.9879C35.1518 45.2347 34.8829 46.3074 34.345 47.2058C33.8072 48.0982 33.0279 48.7827 32.0072 49.2595C30.9926 49.7362 29.7641 49.9746 28.3217 49.9746H23.3618V46.7841H27.6799C28.4378 46.7841 29.0674 46.6802 29.5685 46.4724C30.0697 46.2646 30.4426 45.9529 30.687 45.5373C30.9376 45.1217 31.0629 44.6052 31.0629 43.9879C31.0629 43.3645 30.9376 42.8388 30.687 42.411C30.4426 41.9832 30.0667 41.6592 29.5594 41.4392C29.0582 41.2131 28.4256 41.1 27.6616 41.1H24.9846V56.6305H21.0148ZM31.1546 48.086L35.8211 56.6305H31.4388L26.8732 48.086H31.1546Z" fill="white"/>
    <path d="M54.5427 47.5117L58.3513 43.7956L58.3511 56.6305H54.5424L54.5427 47.5117Z" fill="white"/>
    <path d="M60.4694 33.8468L60.4901 41.7319L52.5843 33.8261L60.4694 33.8468Z" fill="white"/>
    <defs>
    <linearGradient id="paint0_linear_10116_11431" x1="40.9218" y1="12.2537" x2="40.9218" y2="82.3729" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F25F49"/>
    <stop offset="1" stopColor="#F28C2C"/>
    </linearGradient>
    <radialGradient id="paint1_radial_10116_11431" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(40.7787 47.5) rotate(90) scale(21.6927)">
    <stop stopColor="#48140A"/>
    <stop offset="1" stopColor="#BD6512" stopOpacity="0"/>
    </radialGradient>
    </defs>
    </svg>
    
}