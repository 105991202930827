import {createContext, useContext, useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import { AccountBreakdown, GetClientAccountValueOutput, cancelClientRequest, getClientAccountRequests, getClientAccountSwitchInValue, getClientAccountValue, getClientAccountValueBreakdown, getFund } from '../../api'
import { AxiosError } from 'axios';
import { PersonaContext } from '../login/context';

export interface AccountRequest {
    id: string
    type: string
    fundClassBaseCurrency: string
    fundId: string
    fundClassSequence: string
    fundCode: string
    fundShortName: string
    amount: number
    units: number
    unitPrice: number
    postFeeAmount: number
    requestedAmount: number
    settlementBankingFee?: number

    switchId: string
    switchFromFundId: string
    switchFromFundClassSequence: number
    switchToFundId: string
    switchToFundClassSequence: number

    consentType: undefined | "noconsent" | "policy" | "document"
    consentStatus: undefined | "pending" | "signed"
    lastNotifiedAt: undefined | string
    
    rebateId: string
    rebateFromDate: string
    rebateToDate: string

    salesChargePercentage: number
    redemptionChargePercentage: number
    status: string
    createdAt: string
}

export interface AccountTransaction {
    createdAt: string
    baseAsset: string
    quoteAsset: string
    category: string
    baseAmount: number
    unitPrice: number
    quoteAmount: number
}

export interface IPortfolioContext {
    accountValue: GetClientAccountValueOutput
    accountValueLoading: boolean
    
    breakdown?: AccountBreakdown[]
    breakdownLoading: boolean
    
    switchinValue: number
    switchinValueLoading: boolean
    
    accountExperience: string
    accountRequests?: AccountRequest[]
    // accountTransactions is provided when the account is mandate
    accountTransactions?: AccountTransaction[]
    accountRequestsLoading: boolean
    reloadAccountRequestsToggle: boolean
    reloadAccountRequests: () => void
    cancelRequest: (requestId: string, onSuccess: () => void, onError: (err: AxiosError) => void) => void
}

const initialState = {
    accountValue: {
        accountId: "",
        accountName: "",
        quoteAsset: "",
        netInflow: 0,
        totalInflow: 0,
        totalOutflow: 0,
        portfolioValue: 0,
        pnlAmount: 0,
        pnlPercentage: 0,
        since: "",
    },
    accountValueLoading: true,
    
    breakdown: [],
    breakdownLoading: true,

    switchinValue: 0,
    switchinValueLoading: true,
    
    accountExperience: "fundmanagement",
    accountTransactions: [],
    accountRequests: [],
    accountRequestsLoading: true,
    reloadAccountRequestsToggle: false,
    reloadAccountRequests: () => {},
    cancelRequest: (requestId: string, onSuccess: () => void, onError: (err: AxiosError) => void) => {}
}

export const PortfolioContext = createContext<IPortfolioContext>(initialState)

const PortfolioProvider = (props: any) => {
    const navigate = useNavigate()
    const persona = useContext(PersonaContext)
    
    const [state, setState] = useState<IPortfolioContext>(initialState)

    useEffect(() => {
        if (!persona.clientId || !persona.accountId) {
            navigate("/auth/email")
            return
        }

        getClientAccountValue(persona.clientId, persona.accountId).then((resp) => {
            const data = resp.data
            setState(prev => ({
                ...prev, 
                accountValue: {
                    accountId: data.accountId,
                    accountName: data.accountName,
                    quoteAsset: data.quoteAsset,
                    netInflow: data.netInflow,
                    totalInflow: data.totalInflow,
                    totalOutflow: data.totalOutflow,
                    portfolioValue: data.portfolioValue,
                    pnlAmount: data.pnlAmount,
                    pnlPercentage: data.pnlPercentage,
                    since: data.since,
                },
                accountValueLoading: false,
            }))
        }).catch((err: AxiosError) => {
            if (err.response?.status === 401) navigate("/auth/email")
        })
        
        getClientAccountValueBreakdown(persona.clientId, persona.accountId).then((resp) => {
            const data = resp.data
            setState(prev => ({
                ...prev, 
                breakdown: data.breakdown,
                breakdownLoading: false,
            }))
        }).catch((err: AxiosError) => {
            if (err.response?.status === 401) navigate("/auth/email")
        })
        
        getClientAccountSwitchInValue(persona.clientId, persona.accountId).then((resp) => {
            const data = resp.data
            setState(prev => ({
                ...prev, 
                switchinValue: data.value,
                switchinValueLoading: false,
            }))
        }).catch((err: AxiosError) => {
            if (err.response?.status === 401) navigate("/auth/email")
        })
        
        getClientAccountRequests(persona.clientId, persona.accountId).then((resp) => {
            const data = resp.data
            setState(prev => ({
                ...prev, 
                accountRequests: data.requests,
                accountRequestsLoading: false,
                reloadAccountRequests,
                cancelRequest,
            }))
        }).catch((err: AxiosError) => {
            if (err.response?.status === 401) navigate("/auth/email")
        })
    }, [])

    useEffect(() => {
        if (!persona.clientId || !persona.accountId) {
            navigate("/auth/email")
            return
        }
        setState(prev => ({
            ...prev,
            accountRequestsLoading: true,
        }))
        getClientAccountRequests(persona.clientId, persona.accountId).then((resp) => {
            const data = resp.data
            setState(prev => ({
                ...prev, 
                accountExperience: data.experience,
                accountRequests: data.requests,
                accountTransactions: data.transactions,
                accountRequestsLoading: false,
            }))
        }).catch((err: AxiosError) => {
            if (err.response?.status === 401) navigate("/auth/email")
        })
    }, [ state.reloadAccountRequestsToggle ])

    const cancelRequest = (requestId: string, onSuccess: () => void, onError: (err: AxiosError) => void) => {
        cancelClientRequest({
            clientId: persona.clientId,
            accountId: persona.accountId,
            requestId,
        }).then(() => {
            reloadAccountRequests()
            onSuccess()
        }).catch(onError)
    }

    const reloadAccountRequests = () => {
        setState(prev => ({
            ...prev,
            reloadAccountRequestsToggle: !prev.reloadAccountRequestsToggle,
        }))
    }
    
    return <PortfolioContext.Provider value={ state }>
        {props.children}
    </PortfolioContext.Provider>
}

export default {
    PortfolioContext,
    PortfolioProvider,
}
