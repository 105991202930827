interface StampProps {
    className?: string
}


export default function Stamp({ className }: StampProps) {
    return <svg width="121" height="119" viewBox="0 0 161 159" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M94.4694 65.4345C94.449 56.7954 97.6288 48.4558 103.393 42.0222C106.042 37.7868 107.44 32.8858 107.423 27.8905C107.337 13.0138 95.3031 0.972983 80.4264 0.875C65.5923 0.878697 53.57 12.9102 53.5737 27.7445C53.5737 33.2167 55.2486 38.5558 58.3674 43.052C67.8958 54.2664 69.299 70.2764 61.8689 82.9772H99.326C96.1406 77.6824 94.462 71.6167 94.4694 65.4364V65.4345Z" fill="#004BC3"/>
        <path d="M20.6436 87.7299C20.6436 85.105 22.7715 82.9771 25.3964 82.9771H135.601C138.226 82.9771 140.354 85.105 140.354 87.7299V99.6122H20.6436V87.7299Z" fill="#448CFF"/>
        <path d="M7.43158 109.118C7.43158 103.868 11.6875 99.6123 16.9373 99.6123H144.062C149.312 99.6123 153.567 103.868 153.567 109.118V149.007H7.43158V109.118Z" fill="#B1DFFF"/>
        <rect x="0.499512" y="149.006" width="160" height="9.8568" rx="2.37644" fill="#004BC3"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M99.0145 110.691C100.741 112.417 100.741 115.216 99.0145 116.942L78.0286 137.928C77.1996 138.757 76.0753 139.223 74.9029 139.223C73.7306 139.223 72.6063 138.757 71.7773 137.928L61.9838 128.134C60.2575 126.408 60.2575 123.609 61.9838 121.883C63.71 120.157 66.5088 120.157 68.235 121.883L74.9029 128.551L92.7633 110.691C94.4895 108.964 97.2883 108.964 99.0145 110.691Z" fill="#B1DFFF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M99.0145 110.691C100.741 112.417 100.741 115.216 99.0145 116.942L78.0286 137.928C77.1996 138.757 76.0753 139.223 74.9029 139.223C73.7306 139.223 72.6063 138.757 71.7773 137.928L61.9838 128.134C60.2575 126.408 60.2575 123.609 61.9838 121.883C63.71 120.157 66.5088 120.157 68.235 121.883L74.9029 128.551L92.7633 110.691C94.4895 108.964 97.2883 108.964 99.0145 110.691Z" fill="#CEE3F5"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M99.0145 110.691C100.741 112.417 100.741 115.216 99.0145 116.942L78.0286 137.928C77.1996 138.757 76.0753 139.223 74.9029 139.223C73.7306 139.223 72.6063 138.757 71.7773 137.928L61.9838 128.134C60.2575 126.408 60.2575 123.609 61.9838 121.883C63.71 120.157 66.5088 120.157 68.235 121.883L74.9029 128.551L92.7633 110.691C94.4895 108.964 97.2883 108.964 99.0145 110.691Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M99.0145 110.691C100.741 112.417 100.741 115.216 99.0145 116.942L78.0286 137.928C77.1996 138.757 76.0753 139.223 74.9029 139.223C73.7306 139.223 72.6063 138.757 71.7773 137.928L61.9838 128.134C60.2575 126.408 60.2575 123.609 61.9838 121.883C63.71 120.157 66.5088 120.157 68.235 121.883L74.9029 128.551L92.7633 110.691C94.4895 108.964 97.2883 108.964 99.0145 110.691Z" fill="#004BC3"/>
    </svg>
}

