import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useEffect, MouseEvent, ChangeEvent, useContext} from "react";
import { PersonaContext } from "../login/context";
import { DepositContext } from "./context";
import HSBTCFLogo from "../../asset/hsbtcf-logo";
import HSETHFLogo from "../../asset/hsethf-logo";
import HSRIFLogo from "../../asset/hsrif-logo";

export default function Deposit() {
    const persona = useContext(PersonaContext)
    const deposit = useContext(DepositContext)
    
    const navigate = useNavigate()

    useEffect(() => {
        if (!persona.clientId || !persona.accountId) {
            navigate("/auth/email")
            return
        }
        if (!persona.canInvest) {
            navigate("/portfolio")
            return
        }
    },[])

    const handleBack = () => {
        navigate("/portfolio")
    }

    const handleSelectAsset = (ev: MouseEvent<HTMLLIElement>) => {
        const {chain, asset} = ev.currentTarget.dataset
        if (!asset) return
        if (!chain) {
            navigate(`/deposit/legacy/${asset}/collection`)
            return
        }
        navigate(`/deposit/${chain}/${asset}/collection`)
    }

    const renderAssetIcon = (asset: string) => {
        switch (asset) {
            case "btc":
                return <HSBTCFLogo className="w-12 h-12" />
            case "eth":
                return <HSETHFLogo className="w-12 h-12" />
            case "myr":
                return <HSRIFLogo className="w-12 h-12" />
            default:
                break;
        }
    }
    
    return <div className="flex flex-col gap-6">
    <div className="relative">
        <XMarkIcon className="fixed mt-4 ml-4 w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={handleBack}/>
        <h1 className="ml-8 pt-20 text-xl hg-text-blue pl-3 pr-6">What asset would you like to deposit?</h1>
    </div>
    <ul className="flex flex-col md:w-2/3 md:mx-auto mx-10">
        {!deposit.loading && deposit.output?.addresses?.map((a, idx) => {
            if (a.assetType === "digital") {
                return <li key={idx} className="flex gap-2 py-5 text-lg border-b cursor-pointer" data-chain={a.chain} data-asset={a.asset} onClick={handleSelectAsset}>
                    {renderAssetIcon(a.asset)}
                    <label className="my-auto">
                        <span className="capitalize">{a.chain}</span> - <span className="uppercase">{a.asset}</span>
                    </label>
                </li>
            }
            return <li key={idx} className="flex gap-2 py-5 text-lg border-b cursor-pointer" data-asset={a.asset} onClick={handleSelectAsset}>
                {renderAssetIcon(a.asset)}
                <label className="my-auto">
                    <span className="capitalize">{a.assetLabel}</span> - <span className="uppercase">{a.asset}</span>
                </label>
            </li>

        })}
    </ul>
</div>
}
