import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PersonaContext } from "./context";

export default function Biometric() {
    const navigate = useNavigate()
    const [userId, setUserID] = useState<string>("random_user")
    const [state, setState] = useState<any>({})
    const persona = useContext(PersonaContext)

    useEffect(() => {
        if (!window.PublicKeyCredential) {
            navigate("/portfolio")
            return
        }
        
        
    },[])

    const handleCreate = () => {
        window.navigator.credentials.create({
            publicKey: {
                rp: { 
                    id: "halogen.my",
                    name: "Halogen Wallet" 
                },
                user: { 
                    id: new TextEncoder().encode(userId),
                    name: "ysweid@halogen.my",
                    displayName: "Yahia Sweid" 
                },
                authenticatorSelection: {
                    authenticatorAttachment: "platform",
                    userVerification: "required",
                    requireResidentKey: true,
                },
                challenge: new TextEncoder().encode("mockedChallenge"),
                pubKeyCredParams: [ { type: "public-key", alg: -7 } ],
            }
        }).then(resp => {
            console.log("from create", resp)
            setState(() => ({
                create: {
                    id: resp?.id,
                    password: resp?.password,
                    type: resp?.type,
                }
            }))
            console.log(resp?.id, resp?.password, resp?.type)
        }).catch(err => {
            alert(err)
            console.log("Error", err)
        });
    }

    const handleGet = () => {
        window.navigator.credentials.get({
            mediation: "required",
            publicKey: {
                rpId: "halogen.my",
                challenge: new TextEncoder().encode("mockedChallenge"),
                // allowCredentials: [{ type: "public-key", id: new TextEncoder().encode(userId), transports: ["internal"] }],
                userVerification: "required",
              }
        }).then(resp => {
            console.log("from get", resp)
            setState(() => ({
                get: {
                    id: resp?.id,
                    password: resp?.password,
                    type: resp?.type,
                }
            }))
        }).catch(err => {
            alert(err)
            console.log("Error", err)
        })
    }
    
    // TODO: get without creds to show list of available keys
    const handleGetWithoutCreds = () => {
        window.navigator.credentials.get({
            mediation: "required",
            publicKey: {
                rpId: "halogen.my",
                challenge: new TextEncoder().encode("mockedChallenge"),
                // allowCredentials: [{ type: "public-key", id: new TextEncoder().encode(userId), transports: ["internal"] }],
                userVerification: "required",
              }
        }).then(resp => {
            console.log("from get", resp)
            setState(() => ({
                get: {
                    id: resp?.id,
                    password: resp?.password,
                    type: resp?.type,
                }
            }))
        }).catch(err => {
            alert(err)
            console.log("Error", err)
        })
    }
    
    return <div className="flex flex-col gap-5">
        <input className="mt-20" type="text" name="username" autoComplete="username webauthn"/>
        <label>Create</label>
        <ul>
            <li>{state?.create?.id}</li>
            <li>{state?.create?.password}</li>
            <li>{state?.create?.type}</li>
        </ul>
        <label>Get</label>
        <ul>
            <li>{state?.get?.id}</li>
            <li>{state?.get?.password}</li>
            <li>{state?.get?.type}</li>
        </ul>
        <div className="flex gap-10">
            <button className="bg-blue-700 text-white cursor-pointer p-3 rounded-full"onClick={handleCreate}>Create</button>
            <button className="bg-blue-700 text-white cursor-pointer p-3 rounded-full"onClick={handleGet}>Get</button>
        </div>
    </div>
}
