import Layout from '../../layout';
import { Navigate } from "react-router-dom";
import Invest from './invest';
import SuitabilityAssessment from './suitability_assessment';

export default [
  {
    path: "/invest",
    element: <Layout><Invest /></Layout>,
    errorElement: <Navigate to="/" />
  },
  {
    path: "/invest/fund/:fundId/class/:fundClassSequence",
    element: <Layout><Invest /></Layout>,
    errorElement: <Navigate to="/" />
  },
  {
    path: "/invest/suitability-assessment/fund/:fundId/class/:fundClassSequence",
    element: <Layout><SuitabilityAssessment /></Layout>,
    errorElement: <Navigate to="/" />
  },
]
