import Layout from '../../layout';
import List from './list';
import { Navigate } from "react-router-dom";
import Request from './request';
import DuitNow from './duitnow';
import BankTransfer from './banktransfer';
import Allocation from './allocation';

export default [
    {
      path: "/portfolio",
      element: <Layout><List /></Layout>,
      errorElement: <Navigate to="/" />
    },
    {
      path: "/portfolio/request/:requestId",
      element: <Layout><Request /></Layout>,
      errorElement: <Navigate to="/" />
    },
    {
      path: "/portfolio/allocation/:fundId/:fundClassSequence",
      element: <Layout><Allocation /></Layout>,
      errorElement: <Navigate to="/" />
    },
    {
      path: "/portfolio/request/:requestId/duitnow",
      element: <Layout><DuitNow /></Layout>,
      errorElement: <Navigate to="/" />
    },
    {
      path: "/portfolio/request/:requestId/banktransfer",
      element: <Layout><BankTransfer /></Layout>,
      errorElement: <Navigate to="/" />
    }
]
