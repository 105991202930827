import Layout from '../../layout';
import { Navigate } from "react-router-dom";
import Deposit from './deposit';
import DepositContext from './context';
import DepositCollection from './deposit_collection';

export default [
  {
    path: "/deposit",
    element: <Layout>
      <DepositContext.DepositProvider>
        <Deposit />
      </DepositContext.DepositProvider>
    </Layout>,
    errorElement: <Navigate to="/" />
  },
  {
    path: "/deposit/:chain/:asset/collection",
    element: <Layout>
      <DepositContext.DepositProvider>
        <DepositCollection />
      </DepositContext.DepositProvider>
    </Layout>,
    errorElement: <Navigate to="/" />
  }
]
