import { useState } from "react"

interface ButtonProps {
    label: string
    onClick: React.MouseEventHandler<HTMLButtonElement>
    className?: string
    disabled?: boolean
    loading?: boolean
    icon?: JSX.Element
    hideTextWhenLoading?: boolean
}

export default function Button({onClick, disabled, loading, label, className, icon, hideTextWhenLoading}: ButtonProps) {
    return <>
        <button type="submit" onClick={onClick} disabled={disabled} className={className}>
            {loading && <svg className="animate-spin ml-0.5 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>}
            { hideTextWhenLoading && loading ? <></> : icon ? icon : <></>}
            { hideTextWhenLoading && loading ? <></> : label}
        </button>
        {/* {state.showEditbox && 
            <textarea rows={4} cols={48} onChange={handleEditboxChange} value={state.text} onMouseEnter={handleMouseEnter} onMouseOut={handleMouseOut} 
                placeholder="Required remarks"
                className="fixed right-6 top-14 resize-none shadow-md border-b-0 border-r-0 border-l-0 border-t-2 border-t-blue-300 focus:ring-0" />} */}
    </>
}