
import * as onfido from "onfido-sdk-ui"
import { ChangeEvent, MouseEvent, useEffect, useState, Fragment, KeyboardEvent, useContext } from "react"
import Flag from "../../asset/flag"
import { deleteAuthEmail, getApplicantDetails, getBalance, getOnfidoSession, getSession, setApplicantField, switchRequest, verifyMsisdn, verifyMsisdnCode} from "../../api"
import { Loader } from "../../component"
import { useNavigate } from "react-router-dom"
import { ArrowDownIcon, ArrowLeftCircleIcon, CheckBadgeIcon } from "@heroicons/react/24/outline"
import { PhoneNumberUtil, PhoneNumberType, PhoneNumberFormat } from "google-libphonenumber"
import Hourglass from "../../asset/hourglass"
import { PersonaContext } from "../login/context"
import { PortfolioContext } from "../portfolio/context"
import { FundListContext } from "../fund/context"
import { ProfileContext } from "../settings/context"

interface SwitchState {
    questions: Question[]

    stack: Question[]
    values: Map<string, any>

    currentQuestionIndex: number
    loading: boolean
}

interface Question {
    name: string
    component: (values: Map<string, any>) => JSX.Element
    showIf?: (values: Map<string, any>) => boolean
}

interface SubmitInput {
    name: string
    value: string | number | boolean | Object
    waitForServer?: boolean
}

export default function Switch() {
    const navigate = useNavigate()
    const persona = useContext(PersonaContext)
    const portfolio = useContext(PortfolioContext)
    const fundList = useContext(FundListContext)
    
    const [state, setState] = useState<SwitchState>({
        questions: [],
        stack: [],
        loading: true,
        values: new Map<string, string | number | Object>(),
        currentQuestionIndex: 0,
    })

    
    const updateStack = (values: Map<string, any>): Question[] => {
        const stack: Question[] = []
        for (let i = 0; i < questions.length; i++) {
            const q = questions[i]
            stack.push(q)
            if (!values.get(q.name)) {
                if (q.showIf) {
                    if (q.showIf(values)){
                        break
                    } else {
                        stack.pop()
                        continue
                    }
                }
                break
            }
        }
        return stack
    }
    
    useEffect(() => {
        if (!persona.clientId || !persona.accountId) {
            navigate("/auth/email")
            return
        }
        if (!persona.canRedeem) {
            navigate("/portfolio")
            return
        }
        if (fundList.privateMandates) {
            navigate("/portfolio")
            return
        }
        if (portfolio.accountValue && portfolio.accountValue.portfolioValue <= 0) {
            navigate("/portfolio")
            return
        }
        const values = new Map<string, any>()
        const stack = updateStack(values)
        setState(prev => ({
            ...prev,
            loading: false,
            stack,
            questions,
        }))
    },[])

    const handleSubmit = (input: SubmitInput[]) => {
        const request = input.map(i => Object.fromEntries(state.values.set(i.name, i.value)))
        setState(prev => ({ ...prev, loading: true }))
        // setApplicantField(request).then(() => {
        //     setState((prev) => {
        //         const values = new Map<string, string | number | Object>(prev.values).set(input.name, input.value)
        //         const stack = updateStack(values)
        //         return {
        //             ...prev,
        //             loading: false,
        //             stack,
        //             values,
        //         }
        //     })
        // }).catch(() => {
        //     setState(prev => ({ ...prev, loading: false }))
        // })
        return
    }

    const handleSubmitWithNoSave = (input: SubmitInput[]) => {
        setState((prev) => {
            const values = new Map<string, string | number | Object>(prev.values)
            input.map(i => values.set(i.name, i.value))
            const stack = updateStack(values)
            return {
                ...prev,
                loading: false,
                stack,
                values,
            }
        })
    }

    const handleBack = () => {
        setState(prev => {
            if (prev.stack.length <= 1) {
                navigate("/portfolio")
                return prev
            }
            const lastQuestion = prev.stack[prev.stack.length - 2]
            const values = new Map<string, any>(prev.values)
            // shouldn't happen lastQuestion is undefined
            if (lastQuestion) {
                values.delete(lastQuestion.name)
            }
            const stack = updateStack(values)
            return {
                ...prev,
                stack,
                values,
            }
        })
    }

    const questions: Question[] = [
        {
            name: "switchOutFund",
            component: () => <QuestionSwitchOutFund handleSubmit={handleSubmitWithNoSave} />,
        },
        {
            name: "switchInFund",
            component: (values) => <QuestionSwitchInFund switchFromFundId={values.get("switchFromFundId")} switchFromFundClassSequence={values.get("switchFromFundClassSequence")} handleSubmit={handleSubmitWithNoSave} />,
        },
        {
            name: "amount",
            component: (values) => <QuestionAmount switchFromFundId={values.get("switchFromFundId")} switchFromFundClassSequence={values.get("switchFromFundClassSequence")} switchToFundId={values.get("switchToFundId")} switchToFundClassSequence={values.get("switchToFundClassSequence")} handleSubmit={handleSubmitWithNoSave} />,
        },
        {
            name: "outro",
            component: (values) => <Preview clientId={persona.clientId} accountId={persona.accountId} amount={values.get("amount")} switchFromFundId={values.get("switchFromFundId")} switchFromFundClassSequence={values.get("switchFromFundClassSequence")} switchToFundId={values.get("switchToFundId")} switchToFundClassSequence={values.get("switchToFundClassSequence")} handleSubmit={handleSubmit}/>,
        },
    ]

    if (state.loading) {
        return <div className="flex justify-center items-center h-full">
            <Loader classNames="w-12 h-12 text-blue-50" />
        </div>
    }

    const lastQuestion = state.stack[state.stack.length - 1]

    return <div className="flex flex-col p-5 mx-auto h-full">
        <div className="relative mb-10">
            <ArrowLeftCircleIcon className="fixed w-8 h-8 text-white hg-bg-blue rounded-2xl cursor-pointer" onClick={handleBack}/>
        </div>

        {lastQuestion.component(state.values)}
    </div>
}

interface QuestionProps {
    handleSubmit: (input: SubmitInput[]) => void
}

function QuestionSwitchOutFund(props: QuestionProps) {
    const portfolio = useContext(PortfolioContext)

    const handleSubmit = (ev: MouseEvent<HTMLLIElement>) => {
        const { fundid, fundclasssequence } = ev.currentTarget.dataset
        if (!fundid || !fundclasssequence) return
        const input= [
            {name: "switchOutFund", value: true},
            {name: "switchFromFundId", value: fundid},
            {name: "switchFromFundClassSequence", value: parseInt(fundclasssequence)},
        ]
        props.handleSubmit(input)
    }
    
    return <div className="flex flex-col xs:justify-between sm:justify-between md:justify-normal md:gap-20 h-full">
        <div className="flex flex-col items-start w-full gap-6">
            <h3 className="text-xl hg-text-blue pl-3 pr-6">
                What fund would you like to switch from?
            </h3>
            <ul className="text-white flex flex-wrap justify-center gap-5 text-center w-full">
                {portfolio.breakdown?.map(b => {
                    return <li key={b.fundId + b.fundClassLabel} className="border text-white text-sm cursor-pointer w-72 hg-bg-light-blue" data-fundid={b.fundId} data-fundclasssequence={b.fundClassSequence} onClick={handleSubmit}>
                    <span className="block text-sm pt-8 pb-8 pr-5 pl-5 hg-text-blue font-medium">
                        {b.fundName} - Class {b.fundClassLabel}
                    </span>
                </li>
                })}
            </ul>
        </div>
    </div>
}

interface QuestionSwitchInFundProps extends QuestionProps {
    switchFromFundId: string
    switchFromFundClassSequence: number
    handleSubmit: (input: SubmitInput[]) => void
}

function QuestionSwitchInFund(props: QuestionSwitchInFundProps) {
    interface Fund {
        fundId: string
        fundClassSequence: number
        fundName: string
        fundClassLabel: string
    }

    const fundList = useContext(FundListContext)
    const [sourceFund, setSourceFund] = useState<Fund>()
    const [funds, setFunds] = useState<Fund[]>([])

    useEffect(() => {
        let sourceFund: Fund
        let list: Fund[] = []
        for (let i = 0; i < fundList.funds.length; i++) {
            const f = fundList.funds[i]
            for (let j = 0; j < f.classes.length; j++) {
                const c = fundList.funds[i].classes[j]
                if (f.id === props.switchFromFundId && c.sequence === props.switchFromFundClassSequence) {
                    sourceFund = {
                        fundId: f.id,
                        fundName: f.name,
                        fundClassSequence: c.sequence,
                        fundClassLabel: c.label,
                    }
                    setSourceFund(sourceFund)
                    continue
                }
                list.push({
                    fundId: f.id,
                    fundName: f.name,
                    fundClassSequence: c.sequence,
                    fundClassLabel: c.label,
                })
            }
        }
        setFunds(list)
    }, [])

    const handleSubmit = (ev: MouseEvent<HTMLLIElement>) => {
        const { fundid, fundclasssequence } = ev.currentTarget.dataset
        if (!fundid || !fundclasssequence) return
        const input= [
            {name: "switchInFund", value: true},
            {name: "switchToFundId", value: fundid},
            {name: "switchToFundClassSequence", value: parseInt(fundclasssequence)},
        ]
        props.handleSubmit(input)
    }
    
    return <div className="flex flex-col xs:justify-between sm:justify-between md:justify-normal md:gap-20 h-full">
        <div className="flex flex-col items-start w-full gap-6">
            <h3 className="text-xl hg-text-blue pl-3 pr-6">
                You're switching from <span className="font-medium">{sourceFund?.fundName } - Class {sourceFund?.fundClassLabel}</span>. What fund would you like to switch to?
            </h3>
            <ul className="text-white flex flex-wrap justify-center gap-5 text-center w-full">
                {funds?.map(f => {
                    return <li key={f.fundId + f.fundClassSequence} className="border text-white text-sm cursor-pointer w-72 hg-bg-light-blue" data-fundid={f.fundId} data-fundclasssequence={f.fundClassSequence} onClick={handleSubmit}>
                        <span className="block text-sm pt-8 pb-8 pr-5 pl-5 hg-text-blue font-medium">
                            {f.fundName} - Class {f.fundClassLabel}
                        </span>
                    </li>
                })}
            </ul>
        </div>
    </div>
}

interface QuestionAmountProps extends QuestionProps {
    switchFromFundId: string
    switchFromFundClassSequence: number
    
    switchToFundId: string
    switchToFundClassSequence: number
}

function QuestionAmount(props: QuestionAmountProps) {
    const [amount, setAmount] = useState(0)
    const [units, setUnits] = useState(0)
    const [value, setValue] = useState(0)
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()
    const persona = useContext(PersonaContext)
    const fundList = useContext(FundListContext)
    const sourceFund = fundList.funds.filter(f => f.id === props.switchFromFundId)[0]
    const targetClass = sourceFund.classes.filter(c => c.label === sourceFund.classes[props.switchFromFundClassSequence - 1].label)[0]
    
    const destinationFund = fundList.funds.filter(f => f.id === props.switchToFundId)[0]
    const destinationClass = destinationFund.classes.filter(c => c.label === destinationFund.classes[props.switchToFundClassSequence - 1].label)[0]

    let minimumRedemptionAmount = targetClass.limits.minimumRedemptionAmount
    const maximumRedemptionAmount = value
    
    useEffect(() => {
        getBalance(persona.clientId, persona.accountId).then((resp) => {
            setUnits(resp.data.balance?.[props.switchFromFundId]?.[props.switchFromFundClassSequence].units || 0)
            setValue(resp.data.balance?.[props.switchFromFundId]?.[props.switchFromFundClassSequence].value || 0)
            setLoading(false)
        }).catch(err => {
            navigate("/portfolio")
            return
        })
    }, [])

    const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
        if (ev.key !== "Enter") {
            return
        }
        handleSubmit()
    }

    const handleSubmit = () => {
        const input= [
            {name: "amount", value: amount},
        ]
        props.handleSubmit(input)
    }

    const handleAmountChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const amount = parseFloat(ev.currentTarget.value || "0")
        setAmount(amount)
    }
    
    const handleMax = () => {
        setAmount(maximumRedemptionAmount)
    }

    if (loading) {
        return <div className="flex justify-center items-center h-full">
            <Loader classNames="w-16 h-16 text-blue-100"/>
        </div>
    }

    return <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-5 pl-3">
            <h1 className="text-xl hg-text-blue">You currently have <span className="font-medium">{value.toLocaleString("en-my", { currency: "myr", style: "currency" ,minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span> in the  <span className="font-medium">{sourceFund?.name} - Class {sourceFund?.classes[props.switchFromFundClassSequence - 1]?.label}</span>.
                <br /> <br/>
                How much would you like to switch to <span className="font-medium">{destinationFund.name} - Class {destinationClass.label}</span>?
            </h1>
            <div className="flex flex-col gap-2">
                <span className="block text-blue-900 text-lg font-medium">Switch amount</span>
                <div className="relative">
                    <span className="absolute bottom-3 left-4 text-gray-700">RM</span>
                    <input type="number" inputMode="numeric" onKeyDown={handleKeyDown} onChange={handleAmountChange} value={amount <= 0 ? "" : amount} className="w-full border p-3 pl-12 rounded-full" placeholder="10,000.00"/>
                    <span className="absolute bottom-3 right-4 hg-text-blue cursor-pointer" onClick={handleMax}>Max</span>
                </div>
                {amount < minimumRedemptionAmount && <span className="ml-4 text-xs text-red-700 font-medium">Minimum switch amount is {minimumRedemptionAmount?.toLocaleString("en-my", { currency: "myr", style: "currency", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                {amount > maximumRedemptionAmount && <div className="flex ml-4 gap-1">
                    <span className="text-xs text-red-700 font-medium">
                        Estimated maximum switch amount is {maximumRedemptionAmount?.toLocaleString("en-my", { currency: "myr", style: "currency", minimumFractionDigits: 2, maximumFractionDigits: 2 })}.
                    </span>
                </div>}
            </div>
        </div>
        <button onClick={handleSubmit} disabled={!amount || amount < minimumRedemptionAmount || amount > maximumRedemptionAmount} className="w-full block p-3 hg-bg-blue text-white border rounded-full disabled:bg-blue-300">
            Preview
        </button>
    </div>
}

interface QuestionPreviewProps extends QuestionProps {
    clientId: string
    accountId: string

    switchFromFundId: string
    switchFromFundClassSequence: number
    
    switchToFundId: string
    switchToFundClassSequence: number

    amount: number
}

interface PreviewState {
    switchLoading: boolean
    switchFailed: boolean
}

function Preview(props: QuestionPreviewProps) {
    const [ state, setState ] = useState<PreviewState>({
        switchLoading: false,
        switchFailed: false,
    })
    const portfolio = useContext(PortfolioContext)
    const fundList = useContext(FundListContext)
    const sourceFund = fundList.funds.filter(f => f.id === props.switchFromFundId)[0]
    const targetFund = fundList.funds.filter(f => f.id === props.switchToFundId)[0]

    const navigate = useNavigate()

    const handleRedeem = (ev: MouseEvent) => {
        setState(prev => ({...prev, switchLoading: true}))
        switchRequest({
            clientId: props.clientId,
            accountId: props.accountId,
            switchFromFundId: props.switchFromFundId,
            switchFromFundClassSequence: props.switchFromFundClassSequence,
            switchToFundId: props.switchToFundId,
            switchToFundClassSequence: props.switchToFundClassSequence,
            requestedAmount: props.amount,
        }).then(resp => {
            portfolio.reloadAccountRequests()
            navigate(`/portfolio/request/${resp.data?.request?.id}`)
        }).catch(() => {
            setState(prev => ({
                ...prev,
                switchLoading: false,
                switchFailed: true
            }))
        })
    }

    const renderSwitchButton = () => {
        if (state.switchLoading) {
            return <button disabled={state.switchLoading} className="hg-bg-blue p-3 text-white border rounded-full disabled:bg-blue-300">
                <Loader classNames="w-7 h-7 text-blue-300" />
            </button>
        }
        return <button onClick={handleRedeem} className="hg-bg-blue p-3 text-white border rounded-full outline-0">
            Switch
        </button>
    }

    return <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-6 pl-3">
            <h1 className="text-xl hg-text-blue">Preview of switch</h1>
            <div className="flex gap-2">
                <span className="text-blue-900 font-medium w-40">From</span>
                <span className="w-full">{sourceFund.name} - {sourceFund.classes[props.switchFromFundClassSequence - 1]?.label}</span>
            </div>
            <div className="flex gap-2">
                <span className="text-blue-900 font-medium w-40">To</span>
                <span className="w-full">{targetFund.name} - {targetFund.classes[props.switchToFundClassSequence - 1]?.label}</span>
            </div>
            <div className="flex gap-2">
                <span className="text-blue-900 font-medium w-40">Amount</span>
                <span className="w-full">{props.amount?.toLocaleString("en-my", {currency: "myr", style:"currency", maximumFractionDigits: 2, minimumFractionDigits: 2})}</span>
            </div>
            <div className="flex gap-2">
                <span className="text-blue-900 font-medium w-40">Switch fee %</span>
                <span className="w-full">0.00%</span>
            </div>
            <div className="flex gap-2">
                <span className="text-blue-900 font-medium w-40">Switch fee</span>
                <span className="w-full">RM 0.00</span>
            </div>
            <div className="flex gap-2">
                <span className="text-blue-900 font-medium w-40">Nett amount</span>
                <span className="w-full">{props.amount?.toLocaleString("en-my", {currency: "myr", style:"currency", maximumFractionDigits: 2, minimumFractionDigits: 2})}</span>
            </div>
            {state.switchFailed && <div className="mt-5 w-5/6 p-3 border border-red-700 bg-red-100 text-sm rounded-full text-center">
                An error ocurred, please try again.
            </div>}
        </div>
        {renderSwitchButton()}
    </div>
}
